import { FormPayload, IGetJobVisit, JobChartData, JobListSearchPayload } from "../../components/JobManagement/interface";
import { Filterable, Pageable, Paginated, Searchable, Sortable } from "../../shared/interfaces";
import { DataResponse } from "../service-models";
import { newAxiosInstance as http } from "../http-common";
const getJobVisitData = (payload?: IGetJobVisit, signal?: any) => {
    let url = '/joborders/status';
    return http.get<DataResponse<JobChartData>>(url, {
        params: payload,
        signal: signal
    });
}


const getJobListService = (payload?: JobListSearchPayload, signal?: any) => {
    let url = '/joborders/list';
    return http.get<DataResponse<any>>(url, { params: payload, signal: signal });
}
const createJobService = (payload: FormPayload) => {
    const url = `/joborders/create`;
    return http.post<DataResponse<FormPayload>>(url, payload);
};

const updateJobService = (id: string, payload: FormPayload) => {
    const url = `/joborders/${id}`;
    return http.put<DataResponse<FormPayload>>(url, payload);
};

const deleteJobService = (id: string) => {
    const url = `/joborders/${id}`;
    return http.delete<DataResponse<any>>(url);
};

const getJobByIDService = (id?: string) => {
    const url = `/joborders/${id}`;

    return http.get<DataResponse<any>>(url);
}
const getJobProcedureList = (id?: string) => {
    let url = `/joborders/procedures/${id}`
    return http.get<DataResponse<any>>(url);
}

const JobMAnagementService = {
    createJobService,
    getJobListService,
    deleteJobService,
    getJobVisitData,
    getJobByIDService,
    getJobProcedureList,
    updateJobService

};
export default JobMAnagementService;