import React, { useState } from 'react';
import { JobActionHeaderProps } from './interface';
import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import SearchBar from '../SearchBar/SearchBar';
import { FilterIcon } from '../Icons/CdsIcons';
import { debounce } from 'lodash';

const JobActionHeader: React.FC<JobActionHeaderProps> = ({ selectRow, setSelectedRow, handleDelete, onClickFilter, setSearchPayload }) => {
    const { t } = useTranslation();
    const [isDelete, setDelete] = useState<boolean>(false);
    const onClickCross = () => {
        setSelectedRow([]);
    };
    // const onClickDelete = () => {
    //     console.log(selectRow.length);
    //     if (selectRow.length < 1) return;
    // try {
    //     setDelete(true);
    //     await handleDelete(selectRow);
    // } catch (error) {
    // } finally {
    //     setDelete(false);
    // }
    // };
    const onSearch = debounce((value) => {
        setSearchPayload((prev) => ({ ...prev, search: value.trim(), page: 1 }));
    }, 500);
    return (
        <Stack
            direction={'row'}
            justifyContent={'space-between'}
        >
            <Stack
                direction={'row'}
                gap={'10px'}
                alignItems={'center'}
            >
                <Box
                    component={'div'}
                    sx={{ width: '400px', maxWidth: '400px' }}
                >
                    <SearchBar onChangeHandler={(e) => onSearch(e.target.value)} />
                </Box>
                {/* <IconButton
                    disableFocusRipple
                    sx={{ border: '1px solid rgba(224, 226, 236, 1)', borderRadius: '6px', padding: 'auto 16px', maxHeight: '40px' }}
                    onClick={onClickFilter}
                >
                    <FilterIcon />
                </IconButton> */}
                {selectRow.length > 0 && (
                    <>
                        <Stack
                            sx={{ padding: '9px 13px', borderRadius: '10px', background: 'rgba(231, 242, 249, 1)', minWidth: '138px' }}
                            flexDirection={'row'}
                            gap={'10px'}
                            alignItems={'center'}
                            justifyContent={'flex-start'}
                            alignContent={'center'}
                        >
                            <Typography sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '20px', textAlign: 'left' }}>
                                {t('COMMON.SELECTED')} {`(${selectRow ? selectRow.length : 0})`}
                            </Typography>

                            <IconButton
                                sx={{ padding: 0 }}
                                onClick={onClickCross}
                            >
                                <ClearOutlinedIcon sx={{ fontSize: '20px' }} />
                            </IconButton>
                        </Stack>
                        <Button
                            variant="outlined"
                            disableRipple
                            sx={{
                                height: '40px',
                                border: '2px solid rgba(228, 0, 43, 1)',
                                borderRadius: '100px',
                                color: 'rgba(228, 0, 43, 1)',
                                fontSize: '14px',
                                fontWeight: 600,
                                '&:hover, &:active, &.Mui-disabled': {
                                    border: '2px solid rgba(228, 0, 43, 1)',
                                },
                            }}
                            endIcon={isDelete ? <CircularProgress size={20} /> : null}
                            onClick={handleDelete}
                        >
                            {t('COMMON.DELETE')}
                        </Button>
                    </>
                )}
            </Stack>
            {/* <Button
                variant="contained"
                sx={{
                    height: '40px',
                    bgcolor: '#1079BF',
                    borderRadius: '100px',
                    color: '#FFFFFF',
                    fontSize: '14px',
                    fontWeight: 600,
                    '&:hover, &:active, &.Mui-disabled': {
                        bgcolor: '#1079BF',
                    },
                }}
            >
                {t('COMMON.EXPORT')}
            </Button> */}
        </Stack>
    );
};

export default JobActionHeader;
