import { LinkProps } from "@mui/material";
import { Pageable, Searchable } from "../../shared/interfaces";

export interface ChartData {
    value: number,
    color: string,
    label: string
}
export interface PieChartProps {
    title: string,
    data: ChartData[],
}
export interface JobActionHeaderProps {
    selectRow: any[];
    setSelectedRow: any;
    handleDelete: (selectRow) => void;
    onClickFilter: () => void;
    searchPayload: JobListSearchPayload,
    setSearchPayload: any,

}
export enum JOB_STATUS {
    "COMPLETED" = "completed",
    "IN_PROGRESS" = "In progress",
    "FAILED" = "Failed"
}
export interface IGetJobVisit {
    start?: number;
    end?: number;
}

interface JobOrderStatus {
    completed: number | 0;
    inProgress: number | 0;
    notStarted: number | 0;
    failed: number | 0;
    total: number | 0;
}

interface CompletedStatus {
    onTime: number | 0;
    late: number | 0;
    total: number | 0;
}

interface FpyStatus {
    failedJobs: number | string | 0;
    fpyPercentChange: number | string | 0;
    fpy: number | string | 0;
}

export interface JobChartData {
    jobOrderStatusStatistics: JobOrderStatus;
    completionTimeStatistics: CompletedStatus;
    successRateStatistics: FpyStatus;
}
export interface IGetJobList {
    startTime: number;
    endTime: number;
}
export interface NewJobModalProps {
    isOpen: boolean,
    handleClose: () => void;
    setJobNo: any;
    jobNo: any;
    onClickDone: () => void;
}

export interface AssignedUserProps {
    name: string,
    email: string,
    id: string
}
export interface FormDataProps {
    serialNo: string | '';
    materialNo: string | '';
    PO: string | '';
    salesNo: string | '';
    MRP: string | '';
    quantity: string | '';
    jobName: string | '';
    productionPlant: string | '';
    version: string | '';
    orderType: string | '';
    location: string | '';
    assignedUser: AssignedUserProps
    startDate: string,
    dueDate: string,
    estimatedTime: string,
    procedures: [],
    bom: [],
}

export interface LinkRouterProps extends LinkProps {
    to: string;
    replace?: boolean;
}

export interface FormPayload {
    jobId?: string,
    jobName: string,
    material?: string,
    productionPlant: string,
    purchaseOrderNumber: string,
    version: string,
    salesOrderNumber: string,
    orderType: string,
    MRPController: string,
    storageLocation: string,
    quantity: string,
    assignee: string,
    // startDate: string,
    dueDate: string,
    estimatedTime: number | string,
    procedures: [],
    // BOM: [],
}
export interface JobListSearchPayload extends Pageable, Searchable<'jobId', string> {
    jobId?: string,
}
export interface JobRightPanelProps {
    view: string,
    formData: FormDataProps,
    onChange: (key: string, value: string) => void;
    procedureList: any[];
    setProcedureList: any,
    errors: any,
    isDisableEdit: boolean,
    isView: boolean,
    assignedUserList: any[]
}
export const JobViewList = {
    "GENERAL_DETAILS": "GENERAL_DETAILS",
    "PROCEDURES": "PROCEDURES",
}

