import { Box, Button, Card, CircularProgress, Divider, Grid, IconButton, LinearProgress, Skeleton, Stack, styled, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import JobOverView from './JobOverView';
import JobActionHeader from './JobActionHeader';
import PaginatedTableComponent, { ColumnProps, TableCellHeading } from '../Table/PaginatedTableComponent';
import { DeleteIcon, TableListSearchIcon } from '../../components/Icons/CdsIcons';
import { JOB_STATUS, JobListSearchPayload } from './interface';
import NewJobModal from './NewJobModal';
import { useNavigate } from 'react-router-dom';
import { IDateRange } from '../../entities/Analytics/AnalyticsProcedure';
import JobMAnagementService from '../../services/JobManagement/JobManagementService';
import { Toast } from '../Notifications/Notification';
import moment from 'moment';
import { convertSecondsToTime } from './JobDetail';
import { BasicDialog } from '../Trainer/Dialog/BasicDialog/BasicDialog';
const JobManagement = () => {
    const columns: ColumnProps[] = useMemo(() => {
        return [
            {
                label: <TableCellHeading value={'JOB_MANAGEMENT.JOB_ID'} />,
                id: 'jobId',
                isSortBy: true,
                align: 'left',
                render: (value, row) => {
                    return <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#1079BF' }}>{value}</Typography>;
                },
            },
            {
                label: <TableCellHeading value={'JOB_MANAGEMENT.JOB_NAME'} />,
                id: 'jobName',
                isSortBy: false,
                align: 'left',
                render: (value, row) => {
                    return <Typography sx={{ fontSize: '16px', fontWeight: '400', textTransform: 'none', border: 'none', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'start' }}>{value ?? '-'}</Typography>;
                },
            },
            {
                label: <TableCellHeading value={'JOB_MANAGEMENT.ASSIGNED_TO'} />,
                id: 'assignedTo',
                isSortBy: false,
                align: 'left',
                render: (value, row) => {
                    return <Typography sx={{ fontSize: '16px', fontWeight: '400', textTransform: 'none', border: 'none', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'start' }}>{value ?? '-'}</Typography>;
                },
            },
            {
                label: <TableCellHeading value={'JOB_MANAGEMENT.PO'} />,
                id: 'po',
                isSortBy: true,
                align: 'left',
                render: (value, row) => {
                    return <Typography sx={{ fontSize: '16px', fontWeight: '400', textTransform: 'none', border: 'none', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'start' }}>{value ?? '-'}</Typography>;
                },
            },
            {
                label: <TableCellHeading value={'JOB_MANAGEMENT.MATERIAL'} />,
                id: 'material',
                isSortBy: true,
                align: 'left',
                render: (value, row) => {
                    return <Typography sx={{ fontSize: '16px', fontWeight: '400', textTransform: 'none', border: 'none', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'start' }}>{value ?? '-'}</Typography>;
                },
            },
            {
                label: <TableCellHeading value={'JOB_MANAGEMENT.POQ'} />,
                id: 'poq',
                isSortBy: true,
                align: 'left',
                render: (value, row) => {
                    return <Typography sx={{ fontSize: '16px', fontWeight: '400', textTransform: 'none', border: 'none', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'start' }}>{value ? value : '-'}</Typography>;
                },
            },
            // {
            //     label: <TableCellHeading value={'JOB_MANAGEMENT.STARTED_ON'} />,
            //     id: 'startedOn',
            //     isSortBy: true,
            //     align: 'left',
            //     render: (value, row) => {
            //         return <TableCellHeading value={value} />;
            //     },
            // },
            {
                label: <TableCellHeading value={'JOB_MANAGEMENT.DUE_DATE'} />,
                id: 'dueDate',
                isSortBy: true,
                align: 'left',
                render: (value, row) => {
                    return <Typography sx={{ fontSize: '16px', fontWeight: '400', textTransform: 'none', border: 'none', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'start' }}>{getDueDateTime(value) ?? '-'}</Typography>;
                },
            },
            {
                label: <TableCellHeading value={'JOB_MANAGEMENT.TAKT_TIME'} />,
                id: 'taktTime',
                isSortBy: false,
                align: 'left',
                render: (value, row) => {
                    return <Typography sx={{ fontSize: '16px', fontWeight: '400', textTransform: 'none', border: 'none', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'start' }}>{convertSecondsToTime(value) ?? '-'}</Typography>;
                },
            },
            {
                label: <TableCellHeading value={'JOB_MANAGEMENT.TIME_TAKEN'} />,
                id: 'timeTaken',
                isSortBy: false,
                align: 'left',
                render: (value, row) => {
                    return <Typography sx={{ fontSize: '16px', fontWeight: '400', textTransform: 'none', border: 'none', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'start' }}>{value ?? '-'}</Typography>;
                },
            },
            {
                label: <TableCellHeading value={'JOB_MANAGEMENT.COMPLETED_ON'} />,
                id: 'completedOn',
                isSortBy: true,
                align: 'left',
                render: (value, row) => {
                    return <Typography sx={{ fontSize: '16px', fontWeight: '400', textTransform: 'none', border: 'none', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'start' }}>{value ?? '-'}</Typography>;
                },
            },
            {
                label: <TableCellHeading value={'COMMON.STATUS'} />,
                id: 'status',
                isSortBy: false,
                align: 'left',
                render: (value, row) => {
                    return (
                        <JobStatus
                            status={value}
                            progress={row.progress}
                        />
                    );
                },
            },
            {
                label: <TableCellHeading value={'Actions'} />,
                id: 'action',
                isSortBy: false,
                align: 'left',
                render: (value, row) => {
                    return (
                        <IconButton
                            sx={{ padding: 0, fontSize: '24px' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                const tmp: any[] = [];
                                tmp.push(row);
                                setDeleteModalInfo((prev) => ({ ...prev, open: true, deletedJobs: [...tmp] }));
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    );
                },
            },
        ];
    }, []);

    const { t } = useTranslation();
    const [selectRow, setSelectedRow] = useState<any>([]);
    const [jobList, setJobList] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isNewJobOpen, setNewJobOpen] = useState<boolean>(false);
    const [jobNo, setJobNo] = useState<{ serialNo: string; materialNo: string }>({ serialNo: '', materialNo: '' });
    const [searchPayload, setSearchPayload] = useState<JobListSearchPayload>({ page: 1, pageSize: 30 });
    const [paginationState, setPaginationState] = useState<{ totalItems: number; currentPage: number; hasMore: boolean; isProgress: boolean }>({ totalItems: 0, currentPage: 1, hasMore: false, isProgress: false });
    const [updateList, setUpdateList] = useState<boolean>(false);
    const [dateRange, setDateRange] = useState<IDateRange>({
        startDate: '',
        endDate: '',
    });
    const [deleteModalInfo, setDeleteModalInfo] = useState<{ open: boolean; deletedJobs: any[] }>({ deletedJobs: [], open: false });
    const navigate = useNavigate();
    useEffect(() => {
        if (searchPayload.page === 1) {
            setLoading(true);
        } else {
            setPaginationState((prev) => ({ ...prev, isProgress: true }));
        }
        const payload = cleanPayload(searchPayload);
        JobMAnagementService.getJobListService(payload)
            .then((res) => {
                const jobList: any = [];
                const { currentPage, totalPages, totalDocuments } = res?.data?.data;
                setPaginationState((prev) => ({ ...prev, currentPage: currentPage, hasMore: currentPage < totalPages, totalItems: totalDocuments }));
                res?.data?.data?.data.map((job) => {
                    const obj = createJobObj(job);
                    jobList.push(obj);
                });
                if (currentPage === 1) {
                    setJobList(jobList);
                } else {
                    setJobList((prev) => [...(prev || []), ...jobList]);
                }
            })
            .catch((e) => {})
            .finally(() => {
                setLoading(false);
                setPaginationState((prev) => ({ ...prev, isProgress: false }));
            });
    }, [searchPayload, updateList]);

    const handleDelete = async (job: any[]) => {
        if (job.length < 1) return;
        setLoading(true);
        const promises = job.map((deletedJob) =>
            JobMAnagementService.deleteJobService(deletedJob.id)
                .then((res) => {
                    Toast.success(t('BUTTON.OPERATION_SUCCESS'), `${t('JOB_MANAGEMENT.JOB_DELETE')}`);
                })
                .catch(({ response }) => {
                    Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response?.data?.messageCode, { ns: 'error' }) || response?.data?.errorMessage);
                }),
        );
        return Promise.all(promises)
            .then((res) => {
                setUpdateList((prev) => !prev);
            })
            .catch((error) => {})
            .finally(() => {
                setLoading(false);
                setSelectedRow([]);
                setDeleteModalInfo({ open: false, deletedJobs: [] });
                setPaginationState((prev) => ({ ...prev, currentPage: 1 }));
                setSearchPayload((prev) => ({ ...prev, page: 1 }));
            });
    };
    const onClickFilter = () => {};
    const onClickJobList = (job) => {
        if (job.jobId) navigate(`/view-job/${job.id}`);
    };
    const createJobObj = (job) => {
        const obj: any = {
            id: job._id,
            jobId: job.jobId,
            jobName: job.jobName,
            assignedTo: job.assignee?.firstName + ' ' + job.assignee?.lastName,
            po: job.purchaseOrderNumber,
            material: job.material,
            poq: job.quantity,
            startDate: job.startDate,
            dueDate: job.dueDate,
            taktTime: job.estimatedTime,
            timeTaken: job.timeTaken,
            completedOn: job.completedOn,
            status: job.status,
            action: '',
        };
        return obj;
    };
    const loadNext = () => {
        setSearchPayload((prev) => ({ ...prev, page: paginationState.currentPage + 1 }));
    };
    return (
        <Box
            component={'div'}
            sx={{ bgcolor: '#F9FAFC' }}
        >
            <Box
                component={'div'}
                sx={{ marginInline: '24px', paddingBlock: '24px' }}
            >
                <Stack
                    component={'div'}
                    gap={'16px'}
                >
                    <JobHeader
                        count={paginationState.totalItems || 0}
                        setJobModal={setNewJobOpen}
                    />
                    <JobOverView
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        updateList={updateList}
                    />
                    <JobActionHeader
                        selectRow={selectRow}
                        setSelectedRow={setSelectedRow}
                        handleDelete={() => {
                            const tmp = [...selectRow.map((current) => current.row)];
                            setDeleteModalInfo((prev) => ({ ...prev, open: true, deletedJobs: [...tmp] }));
                        }}
                        onClickFilter={onClickFilter}
                        searchPayload={searchPayload}
                        setSearchPayload={setSearchPayload}
                    />
                    {loading ? (
                        <PageLoading />
                    ) : (
                        <>
                            {jobList?.length ? (
                                <Box
                                    component={'div'}
                                    sx={{
                                        width: '100%',
                                        minWidth: '650px',
                                        ' table': {
                                            tableLayout: 'fixed',
                                            '& thead': {
                                                '& th': {
                                                    overflow: 'hidden',
                                                    textOverflow: 'unset',
                                                    whiteSpace: 'nowrap',
                                                },
                                            },
                                            '& tbody': {
                                                '& th, td': {
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                },
                                            },
                                            '& th, td': {
                                                padding: '0px 12px !important',
                                                height: '48px !important',
                                                minHeight: '48px !important',
                                            },
                                            '& th:nth-of-type(1), & td:nth-of-type(1)': {
                                                width: '40px !important',
                                                maxWidth: '40px !important',
                                            },
                                            '& th:nth-of-type(4), & td:nth-of-type(4)': {
                                                width: '10%',
                                            },
                                            '& th:nth-of-type(5), & td:nth-of-type(5)': {
                                                width: '5%',
                                            },
                                            '& th:nth-of-type(11), & td:nth-of-type(11)': {
                                                width: '9%',
                                            },
                                            '& th:nth-of-type(12), & td:nth-of-type(12)': {
                                                width: '15%',
                                            },
                                            '& th:nth-of-type(13), & td:nth-of-type(13)': {
                                                width: '5%',
                                            },
                                        },
                                    }}
                                >
                                    <PaginatedTableComponent
                                        isCheckBoxEnabled={true}
                                        data={jobList}
                                        columns={columns}
                                        selectRow={selectRow}
                                        setSelectedRow={setSelectedRow}
                                        isPaginationEnabled={true}
                                        onClickRow={(e, row) => onClickJobList(row)}
                                        totalItems={paginationState.totalItems}
                                        hasMore={paginationState.hasMore}
                                        loadNext={loadNext}
                                        isProgress={paginationState.isProgress}
                                    />
                                </Box>
                            ) : (
                                <PageEmptyState />
                            )}
                        </>
                    )}
                </Stack>
            </Box>
            {isNewJobOpen && (
                <NewJobModal
                    isOpen={isNewJobOpen}
                    setJobNo={setJobNo}
                    jobNo={jobNo}
                    handleClose={() => {
                        setJobNo({ serialNo: '', materialNo: '' });
                        setNewJobOpen(false);
                    }}
                    onClickDone={() => {
                        navigate('/new-job', { state: jobNo });
                    }}
                />
            )}
            <DeleteModal
                deleteModalInfo={deleteModalInfo}
                setDeleteModalInfo={setDeleteModalInfo}
                handleDelete={() => handleDelete(deleteModalInfo.deletedJobs)}
            />
        </Box>
    );
};
export default JobManagement;

export const JobHeader = ({ count, setJobModal }) => {
    const { t } = useTranslation();
    return (
        <Stack
            direction={'row'}
            sx={{ justifyContent: 'space-between', alignItems: 'center', bgcolor: '#F9FAFC' }}
        >
            <Typography sx={{ fontSize: '28px', fontWeight: 500, color: '#333333' }}>{t('JOB_MANAGEMENT.JOB_ORDERS', { count })}</Typography>
            <Button
                variant="contained"
                disableRipple
                sx={{
                    background: '#88C23F',
                    borderRadius: '100px',
                    height: '36px',
                    '&:hover, &:active, &.Mui-disabled': {
                        background: '#76AA35',
                    },
                }}
                onClick={() => setJobModal(true)}
            >
                {t('JOB_MANAGEMENT.ADD_NEW')}
            </Button>
        </Stack>
    );
};
const JobStatus: React.FC<{ status; progress }> = ({ status, progress = '0%' }) => {
    // const [min = 0, max = 0] = progress?.split('/').map(Number) ?? [0, 0];
    return (
        <Stack gap={'4px'}>
            <Stack
                direction={'row'}
                sx={{ justifyContent: 'space-between', alignItems: 'center' }}
            >
                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: status === JOB_STATUS.FAILED ? '#E4002B' : '#000000' }}>{status}</Typography>
                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: status === JOB_STATUS.FAILED ? '#E4002B' : '#000000' }}>{progress}</Typography>
            </Stack>
            <JobBar
                color={status === JOB_STATUS.FAILED ? '#E4002B' : '#008F31'}
                progress={progress}
                // min={min}
                // max={max}
            />
        </Stack>
    );
};

const JobBar: React.FC<{ color: string; min?: number; max?: number; progress }> = ({ color, min, max, progress }) => {
    const [progressWidth, setProgressWidth] = useState(0);
    useEffect(() => {
        // const newProgress = (min / max) * 100;
        setProgressWidth(progress);
    }, [progress]);

    return (
        <Box sx={{ width: '100%', position: 'relative' }}>
            <Box
                component={'div'}
                sx={{ height: '6px', width: '100%', bgcolor: '#F2F2F2', borderRadius: '10px' }}
            ></Box>
            <Box
                component={'div'}
                sx={{ position: 'absolute', top: 0, left: 0, height: '6px', width: `${progress}%`, bgcolor: color, borderRadius: '10px', transition: 'width 2s linear' }}
            ></Box>
        </Box>
    );
};
const DeleteModal: React.FC<{ deleteModalInfo; setDeleteModalInfo; handleDelete }> = ({ deleteModalInfo, setDeleteModalInfo, handleDelete }) => {
    const { t } = useTranslation();
    return (
        <BasicDialog
            title={t('BUTTON.DELETE')}
            onClose={() => setDeleteModalInfo((prev) => ({ ...prev, open: false }))}
            onOpen={deleteModalInfo?.open}
            actions={
                <DeleteAction
                    handleCancelClick={() => setDeleteModalInfo((prev) => ({ ...prev, open: false }))}
                    handleDeleteClick={handleDelete}
                />
            }
            fullWidth={true}
            maxWidth={'xs'}
        >
            <>
                <Box
                    component={'div'}
                    sx={{ position: 'absolute', translate: '-24px -50%', left: 0, width: '150%', height: '1px', bgcolor: '#cccccc' }}
                ></Box>
                <Stack
                    gap={'16px'}
                    sx={{ marginTop: '16px' }}
                >
                    {deleteModalInfo?.deletedJobs?.length < 2 ? (
                        <Typography sx={{ fontWeight: '500', fontSize: '16px', color: '#595959' }}>
                            {t('JOB_MANAGEMENT.DELETE_MODAL_MSG')}
                            <Box
                                component={'span'}
                                sx={{ fontSize: '16px', color: '#595959', fontWeight: 600 }}
                            >
                                {` ${deleteModalInfo?.deletedJobs[0]?.jobId} | ${deleteModalInfo?.deletedJobs[0]?.jobName}?`}
                            </Box>
                        </Typography>
                    ) : (
                        <>
                            <Typography sx={{ fontWeight: '500', fontSize: '16px', color: '#595959', marginBottom: '10px' }}>{t('JOB_MANAGEMENT.DELETE_MODAL_MSG')}</Typography>
                            <Stack
                                gap={'10px'}
                                sx={{ maxHeight: '200px', overflowY: 'auto' }}
                            >
                                {deleteModalInfo?.deletedJobs.map((job, index) => {
                                    return (
                                        <Typography
                                            key={index}
                                            sx={{ fontSize: '16px', color: '#595959', fontWeight: 600 }}
                                        >{` ${job?.jobId} | ${job?.jobName}?`}</Typography>
                                    );
                                })}
                            </Stack>
                        </>
                    )}
                    <Typography sx={{ fontWeight: 600, fontSize: '16px', color: '#e4002b' }}>{t('JOB_MANAGEMENT.MODAL_WARNING_MSG')}</Typography>
                </Stack>
            </>
        </BasicDialog>
    );
};
const DeleteAction = ({ handleCancelClick, handleDeleteClick }) => {
    const { t } = useTranslation();
    const [isActionButtonClicked, setIsActionButtonClicked] = useState<boolean>(false);
    const handleActionButtonClick = () => {
        setIsActionButtonClicked(true);
        handleDeleteClick();
    };
    return (
        <Stack
            direction={'row'}
            gap={'16px'}
        >
            <Button
                variant="outlined"
                onClick={handleCancelClick}
                sx={{
                    bgcolor: 'white',
                    color: '#666666',
                    border: '2px solid #666666',
                    borderRadius: '100px',
                    fontSize: '14px',
                    fontWeight: 600,
                    '&:hover, &:active, &.Mui-disabled': {
                        border: '2px solid #666666',
                        color: '#666666',
                        bgcolor: 'white',
                    },
                }}
            >
                {' '}
                {t('BUTTON.CANCEL')}
            </Button>
            <Button
                variant="contained"
                onClick={handleActionButtonClick}
                disabled={isActionButtonClicked}
                endIcon={
                    isActionButtonClicked ? (
                        <CircularProgress
                            size={20}
                            sx={{ color: 'white' }}
                        />
                    ) : null
                }
                sx={{
                    bgcolor: '#e4002b',
                    color: 'white',
                    border: '2px solid #e4002b',
                    borderRadius: '100px',
                    fontSize: '14px',
                    fontWeight: 600,
                    '&:hover, &:active, &.Mui-disabled': {
                        border: '2px solid #e4002b',
                        color: 'white',
                        bgcolor: '#e4002b',
                    },
                }}
            >
                {' '}
                {t('COMMON.DELETE')}
            </Button>
        </Stack>
    );
};
export const PageLoading = () => {
    return (
        <Grid
            container
            // sx={{ height: '100%' }}
            rowGap={'24px'}
        >
            {Array.from({ length: 6 }).map((_, index) => (
                <Grid
                    key={index}
                    item
                    xs={12}
                >
                    <Skeleton
                        variant="rounded"
                        width={'100%'}
                        sx={{ height: '48px' }}
                    />
                </Grid>
            ))}
        </Grid>
    );
};
export const PageEmptyState = () => {
    const { t } = useTranslation();
    return (
        <Stack
            direction={'column'}
            marginInline={'auto'}
            justifyContent={'center'}
            gap={'10px'}
            sx={{ minHeight: '300px' }}
        >
            <Stack
                direction={'row'}
                gap={'10px'}
                sx={{ justifyContent: 'center' }}
            >
                <TableListSearchIcon />
                <Typography
                    variant="h4"
                    sx={{ textAlign: 'center', fontWeight: 600, fontSize: '18px', color: '#666666' }}
                >
                    {' '}
                    {t('ANALYTICS_SECTION.NO_RECORDS')}
                </Typography>
            </Stack>
            <Typography sx={{ width: '72%', textAlign: 'center', fontWeight: 400, fontSize: '14px', color: '#666666', alignSelf: 'center' }}>{t('ANALYTICS_SECTION.NO_RECORDS_CONTENT')}</Typography>
        </Stack>
    );
};

export const cleanPayload = (payload) => {
    const cleanedPayload = { ...payload };
    Object.keys(cleanedPayload).forEach((key) => {
        const value = cleanedPayload[key];
        if (value === '' || (Array.isArray(value) && value.length === 0)) {
            delete cleanedPayload[key];
        }
    });
    return cleanedPayload;
};

export const getDueDateTime = (unixTime: any) => {
    const date = new Date(unixTime * 1000);
    return moment(date).format('DD-MM-YYYY');
};
