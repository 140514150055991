import { Backdrop, Box, Button, Chip, IconButton, Menu, MenuItem, Select, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ModalInfoIcon } from '../../../../assets/modal-info.svg';
import { IProcedures, IWorkInstructions } from '../../../../entities/Trainee/MockTrainee';
import TrainerService from '../../../../services/trainer/TrainerService';
import ButtonLink from '../ButtonLink/ButtonLink';
import TrainerProcedureTab from '../TrainerProcedureTab/TrainerProcedureTab';
import './RightProceduresCard.scss';
import { ReactComponent as RefreshIcon } from '../../../../assets/refreshIcon.svg';
import { ReactComponent as ErrorIcon } from '../../../../assets/fileUploadErrorIcon.svg';
import AddIcon from '@mui/icons-material/Add';
import { calculateTimeInMinutes, fileSize } from '../../../../shared/utils/helpers';
import TutorialService from '../../../../services/trainee/TraineeService';
import { Toast } from '../../../Notifications/Notification';
import Loading from '../../../Loading/Loading';
import LinearWithValueLabel from '../../../LinearProgressWithLabel/LinearProgressWithLabel';
import FileManagerService from '../../../../services/FileManager/FileManagerService';
import { AmplitudeEvents, WorkInstructionModelFileUploadStatus, WorkInstructionModelStatus } from '../../../../shared/enums';
import { trackEvent } from '../../../../App';
import { PpeDialog } from '../../../Trainer/Dialog/PpeDialog/PpeDialog';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { MoreHoriz} from '@mui/icons-material';
import LaunchIcon from '@mui/icons-material/Launch';
import useCurrentTranslation from '../../../../hooks/useCurrentTranslation';
import moment from 'moment';
import { CommentSideBar } from '../../../Analytics/ProcedureReports/CommentsSideBar/CommentSideBar';
import { CommentSliderWindow } from '../../../Analytics/ProcedureReports/CommentsSideBar/CommentSliderWindow';
import { CommentIcon, ExportPdfIcon } from '../../../Icons/CdsIcons';
import { ExportPDFDialog } from '../../../Trainer/Dialog/ExportPDFDialog/ExportPDFDialog';
interface Props {
  workInstruction: IWorkInstructions;
  procedures: IProcedures[];
  cadFileUpload: any;
  isTrainer?: boolean;
  status?: null | string;
  showInfoMessage: boolean;
  showErrorMessage: boolean;
  cadFileUploadError: string;
  emitShowErrorMessage: (errorMsg: boolean, infoMsg: boolean, statusMsg: null | string) => void;
  emitIsStatusLoading: (flag: boolean) => void;
  isDelete?: boolean;
  setProcedures?: any;
  isRevisitRequest?: boolean;
  fetchProcedures?: (id: string) => void;
  
}

const RightProceduresCard: React.FC<Props> = (props) => {
  const { companyId, id } = useParams();
  const [status, setStatus] = useState(props.status);
  const [hasSteps, setHasSteps] = useState<boolean>(false);
  const [showInfoMessage, setShowInfoMessage] = useState<boolean>(props.showInfoMessage);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(props.showErrorMessage);
  const [isStatusLoading, setIsStatusLoading] = useState(true);
  const [cadFileUploadError, setCadFileUploadError] = useState(props.cadFileUploadError);
  const [cadFileUpload, setCadFileUpload] = useState<any>(null);
  const [cadUploadErrorCode, setCadUploadErrorCode] = useState(-1);
  const [cadUploadIsInProgress, setCadUploadIsInProgress] = useState(false);
  const [cadUploadProgress, setCadUploadProgress] = useState(0);
  const [cadUploadProgressStatus, setCadUploadProgressStatus] = useState('');
  const [showRetryConfirmation, setShowRetryConfirmation] = useState(false);
  const [language, setLanguage] = useState('all');
  const [loading, setLoading] = useState(false);
  const { currentTranslation } = useCurrentTranslation();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language.toUpperCase();
  const systemPrerequisite = currentTranslation?.PREREQUISITES ? currentTranslation.PREREQUISITES[currentLanguage] : t('PROCEDURE.PRE_REQUISITES');
  const systemCoreProcedure = currentTranslation?.CORE_PROCEDURES ? currentTranslation.CORE_PROCEDURES[currentLanguage] : t('PROCEDURE.CORE_PROCEDURES');
  const navigate = useNavigate();
  const resetMessages = (showInfo: boolean = false, showError: boolean = false) => {
    setShowInfoMessage(showInfo);
    setShowErrorMessage(showError);
  };

  useEffect(() => {
    if (props.status) {
      setStatus(props.status);
    }
  }, [props.status]);

  useEffect(() => {
    if (props.procedures) {
      for (let procedure of props.procedures) {
        procedure.tasks_count > 0 ? setHasSteps(true) : setHasSteps(hasSteps);
      }
    }
  }, [props.procedures]);

  useEffect(() => {
    setShowInfoMessage(props.showInfoMessage);
  }, [props.showInfoMessage]);

  useEffect(() => {
    setShowErrorMessage(props.showErrorMessage);
  }, [props.showErrorMessage]);

  const setErrorInfo = (errorMessage: string) => {
    if (errorMessage && errorMessage.length > 0) {
      const err = errorMessage.toUpperCase();

      if (err === WorkInstructionModelStatus.FILE_UPLOAD_FAILED || err.includes('MODEL NAME ALREADY EXISTS')) {
        setCadUploadErrorCode(2);
      } else {
        setCadUploadErrorCode(1);
      }
    } else {
      setCadUploadErrorCode(-1);
    }
  };

  useEffect(() => {
    setErrorInfo(cadFileUploadError);
    return () => {};
  }, [cadFileUploadError]);

  useEffect(() => {
    setErrorInfo(props.cadFileUploadError);
    return () => {};
  }, [props.cadFileUploadError]);

  const initCadFileUploadStatus = () => {
    setCadUploadProgress(0);
    setCadUploadProgressStatus('');
    setCadUploadIsInProgress(true);
  };

  const sendFileMetaData = (cadFileUpload, modelStatus, fileUploadStatus) => {
    if (props.workInstruction) {
      let fileData = {
        ...props.workInstruction,
        status: modelStatus,
        fileStatus: fileUploadStatus,
        file: {
          name: cadFileUpload.name,
          size: cadFileUpload.size,
        },
      };
      TrainerService.editWorkInstruction(fileData.workInstructionId, fileData)
        .then((e) => {
          // console.log("FIle Meta Data Updated")
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const checkFileStatusInterval = () => {
    const checkFileStatus = setInterval(() => {
      if (props.isTrainer && props.workInstruction) {
        let workInstruction = props.workInstruction;
        if (workInstruction.fileStatus === 'File not uploaded') {
          TutorialService.getWorkInstructionsById(workInstruction.workInstructionId).then((response) => {
            workInstruction.fileStatus = response.data.data.fileStatus;
          });
        }
        if (workInstruction && workInstruction.fileStatus !== 'File not uploaded') {
          setIsStatusLoading(true);
          props.emitIsStatusLoading(true);
          TrainerService.getStatus(workInstruction.workInstructionId)
            .then((response) => {
              setIsStatusLoading(false);
              props.emitIsStatusLoading(false);
              if (response.data.data.status === 'PREPARING 3D MODEL') {
                setStatus('Preparing 3D Model');
                props.emitShowErrorMessage(false, true, 'Preparing 3D Model');
                resetMessages(true);
              } else if (response.data.data.status === '3D MODEL READY') {
                setStatus('3D Model Ready');
                props.emitShowErrorMessage(false, false, '3D Model Ready');
                resetMessages();
                clearInterval(checkFileStatus);
              } else if (response.data.data.status === '3D MODEL CONVERSION FAILED') {
                setIsStatusLoading(false);
                props.emitIsStatusLoading(false);
                setStatus('3D Model Conversion Failed');
                props.emitShowErrorMessage(true, false, '3D Model Conversion Failed');
                resetMessages(false, true);
                clearInterval(checkFileStatus);
              }
            })
            .catch(({ response }) => {
              setIsStatusLoading(false);
              props.emitIsStatusLoading(false);
              setStatus('3D Model Conversion Failed');
              props.emitShowErrorMessage(true, false, '3D Model Conversion Failed');
              resetMessages(false, true);
              clearInterval(checkFileStatus);
            });
        } else if (workInstruction && workInstruction.fileStatus !== 'File not uploaded') {
          setStatus('Preparing 3D Model');
          props.emitShowErrorMessage(false, true, 'Preparing 3D Model');
          resetMessages(true);
        }
      }
    }, 10000);
  };

  const uploadCadFile = (event) => {
    let fileUpload;
    if (event?.target?.files) {
      fileUpload = event.valueOf().target.files[0];
      setCadFileUpload(fileUpload);
    } else {
      fileUpload = cadFileUpload ? cadFileUpload : props.cadFileUpload;
    }
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        setCadUploadProgress(Math.round((100 * progressEvent.loaded) / progressEvent.total));
        let progressStatus = `${t('ADD_WI.PLEASE_WAIT_TRANSFERRING_FILE')} ${fileSize(progressEvent.loaded)} ${t('ADD_WI.OF')} ${fileSize(progressEvent.total)}`;
        if (progressEvent.loaded === progressEvent.total) {
          progressStatus = `${t('ADD_WI.PROCESSING_FILE_UPLOAD')}  ${fileSize(progressEvent.loaded)}  ${t('ADD_WI.OF')}  ${fileSize(progressEvent.total)} .`;
        }
        setCadUploadProgressStatus(progressStatus);
      },
    };
    if (props.workInstruction && props.workInstruction.workInstructionId) {
      const formData = new FormData();
      formData.append('file', fileUpload, fileUpload?.name.split('.')[0]);
      formData.append('org', localStorage.getItem('organizationId') || '');
      formData.append('model', props.workInstruction.workInstructionId);
      formData.append('name', fileUpload?.name);
      formData.append('type', '.' + fileUpload?.name.split('.').pop());
      formData.append('model_name', fileUpload?.name.split('.')[0] + Date.now());
      formData.append('creo', fileUpload?.name.split('.').pop()?.toLowerCase() === 'pvz' ? '1' : '0');
      resetMessages(true);
      setStatus('Preparing 3D Model');
      props.emitShowErrorMessage(false, true, 'Preparing 3D Model');
      props.emitIsStatusLoading(true);
      setCadFileUploadError('');
      initCadFileUploadStatus();
      FileManagerService.fileUpload(formData, config)
        .then((response) => {
          setCadUploadIsInProgress(false);
          props.emitIsStatusLoading(false);
          sendFileMetaData(fileUpload, WorkInstructionModelStatus.PREPARING_MODEL, WorkInstructionModelFileUploadStatus.FILE_UPLOAD_SUCCESS);
          trackEvent(AmplitudeEvents.CAD_ASSET_UPLOAD_SUCCESS, { created_by: localStorage.getItem('email'), work_instruction_id: props.workInstruction.workInstructionId, cad_asset: fileUpload?.name, cad_asset_size: fileUpload.size / (1024 * 1024) + 'MB' });
          checkFileStatusInterval();
        })
        .catch((error) => {
          setCadUploadIsInProgress(false);
          const {
            response: {
              data: { error: errorMessage },
            },
          } = error;
          sendFileMetaData(fileUpload, WorkInstructionModelStatus.FILE_UPLOAD_FAILED, WorkInstructionModelFileUploadStatus.FILE_UPLOAD_FAILED + '.' + errorMessage);
          trackEvent(AmplitudeEvents.CAD_ASSET_UPLOAD_FAILED, { created_by: localStorage.getItem('email'), work_instruction_id: props.workInstruction.workInstructionId, cad_asset: fileUpload?.name, cad_asset_size: fileUpload.size / (1024 * 1024) + 'MB' });
          if (errorMessage) {
            setCadFileUploadError(errorMessage);
            Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(error.response.data.messageCode, { ns: 'error' }) || error.response.data.errorMessage);
          } else {
            setCadFileUploadError('CAD file upload failed');
          }
          setStatus('CAD File Upload Failed');
          props.emitShowErrorMessage(true, false, 'CAD File Upload Failed');
          props.emitIsStatusLoading(false);
          resetMessages(false, true);
        });
    }
  };
  const reloadPage = () => {
    window.location.reload();
  };

  const retryUploadCadFile = () => {
    const retryBody = {
      org: localStorage.getItem('organizationId'),
      model: props.workInstruction.workInstructionId,
      creo: 1,
    };

    setCadFileUploadError('');
    initCadFileUploadStatus();
    setCadUploadIsInProgress(true);
    FileManagerService.retryFileUpload(retryBody)
      .then((response) => {
        setCadUploadIsInProgress(false);
        props.emitIsStatusLoading(false);
        resetMessages(true);
        setStatus('Preparing 3D Model');
        props.emitShowErrorMessage(false, true, 'Preparing 3D Model');
        checkFileStatusInterval();
        //reloadPage();
      })
      .catch(({ response }) => {
        setCadUploadIsInProgress(false);
        if (response?.data?.error) Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
        else Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      });
  };

  const theme = useTheme();
  const RETRY_FILE_CONVERSION_TITLE = t('PROCEDURE.RETRY_CAD_FILE_CONVERSION');
  const RETRY_FILE_CONVERSION_MESSAGE = t('PROCEDURE.RETRY_WARNING_MESSAGE');

  const uniqueLanguages = useMemo(() => {
    const uniqueArray: any = [];
    const uniqueSet = new Set();
    for (let obj of props.procedures) {
      const name = obj?.language?.name;
      if (!uniqueSet.has(name)) {
        uniqueSet.add(name);
        uniqueArray.push(obj.language);
      }
    }
    return uniqueArray;
  }, [props.procedures]);

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };
  const commonStyles = {
    border: 1,
    borderColor: 'text.primary',
    marginRight: '4px',
  };

  const TraineeProcedureCard = ({ p }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [showChats, setShowChats] = React.useState<boolean>(false);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const exportPdfHandler = () => {
      setAnchorEl(null);
      setShowExportPDFDialog(true);
    };
    const { isTrainer } = props;
    const [showRightPaneComment, setShowRightPaneComment] = useState<boolean>(false);
    const [notification, setNotification] = React.useState<any>([]);
    const [activeThreadId, setActiveThreadId] = useState<string>('');
    const [showExportPDFDialog, setShowExportPDFDialog] = useState<boolean>(false);

    return (
      <>
      <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
        {showChats && (
          <>
            <CommentSideBar showRightPaneComment={showRightPaneComment} setShowRightPaneComment={setShowRightPaneComment} isTrainer={isTrainer} activeThreadId={activeThreadId} setActiveThreadId={setActiveThreadId} notification={notification} />
            <CommentSliderWindow showRightPaneComment={showRightPaneComment} setShowRightPaneComment={setShowRightPaneComment} showChats={showChats} setShowChats={setShowChats} activeThreadId={activeThreadId} setActiveThreadId={setActiveThreadId} workInstruction={props.workInstruction} procedure={p} notification={notification} isTrainer={isTrainer} />
          </>
        )}
        <ButtonLink authored={p.isPublished} title={''}>
          <Box component="div" sx={{ display: 'flex', flexGrow: 1, width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box component="div">
              {p.procedureName}
              <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                <Box component="div" sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                  {p?.documentId && (
                    <Box component="div" className="style-text mobile-trainee-card" sx={{ ...commonStyles, borderColor: 'grey.500', fontSize: '0.6em' }}>
                      Document ID: {p?.documentId}{' '}
                    </Box>
                  )}
                  {p?.versionId && (
                    <Box component="div" className="style-text mobile-trainee-card" sx={{ ...commonStyles, borderColor: 'grey.500', fontSize: '0.6em' }}>
                      {t("PROCEDURE.CARD_VERSION")}: {p?.version}{' '}
                    </Box>
                  )}
                  {!p?.isCertificateExpired && moment.unix(p?.certificateExpiresOn).subtract(7, 'days').unix() < Date.now() / 1000 && (
                    <Box component="span" className="style-text style-warning" sx={{ ...commonStyles, textTransform: 'capitalize', borderRadius: '0 !important' }}>
                      {t('NAVBAR.RECERTIFICATION_DUE')}: {moment.unix(p?.certificateExpiresOn).format('MM/DD/YYYY')}
                    </Box>
                  )}
                  {p?.isCertificateExpired && (
                    <Box component="span" className=" style-error" sx={{ ...commonStyles, textTransform: 'capitalize', borderRadius: '0 !important' }}>
                      {t('NAVBAR.EXPIRED')}
                    </Box>
                  )}
                </Box>
                {p.completionStatus?.toUpperCase() === 'COMPLETED' && (
                  <Box sx={{ textAlign: 'left' }} component="div" className="completed-time-font">
                    {t('PROCEDURE.COMPLETED_IN')}{' '}
                    <Box component="span" className="completed plr5">
                      {calculateTimeInMinutes(p.completionTime ?? 0)}
                    </Box>{' '}
                    {t('PROCEDURE.MINS')}{' '}
                  </Box>
                )}
              </Box>
            </Box>
            <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
              <Chip
                onClick={() => {
                  window.location.href = `/assembly/${companyId}/${props.workInstruction.workInstructionId}/${p.procedureId}?workInstructionType=${props.workInstruction.is2d ? '2d' : '3d'}`;
                }}
                icon={<LaunchIcon fontSize="small" color="primary" />}
                color="primary"
                label={t('PROCEDURE.LAUNCH')}
                variant="outlined"
                className="mobile-trainee-card launch-icon"
              />
              <IconButton aria-label="delete" color="primary" id="demo-positioned-button" aria-controls={open ? 'demo-positioned-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                <MoreHoriz fontSize="small" />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                id="table-view-action-menu"
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                  {p.threadCount > 0 && (
                  <MenuItem onClick={() => {
                    setAnchorEl(null);
                    navigate(`/notifications?procedureId=${p?.procedureId}`);
                  }}>
                    <CommentIcon sx={{color: "primary"}} />
                    <Typography sx={{ ml: '8px' }} className="mobile-trainee-card">
                    {t('ANALYTICS_SECTION.COMMENTS')}
                  </Typography>
                  
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    exportPdfHandler();
                  }}
                >
                  <ExportPdfIcon sx={{color:"primary"}} />
                  <Typography sx={{ ml: '8px' }} className="mobile-trainee-card">
                    {t('PROCEDURE.EXPORT_PDF')}
                  </Typography>
                </MenuItem>
              
              </Menu>
            </Box>
          </Box>
        </ButtonLink>
      </Box>
        <ExportPDFDialog 
          openDialog={showExportPDFDialog}
          closeDialog={() => {setShowExportPDFDialog(false); }}
          procedureName={p.procedureName}
          procedureId={p.procedureId}
        />
      </>
      
    );
  };

  return (
    <>
      {loading && (
        <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <Loading />
        </Backdrop>
      )}
      {showRetryConfirmation && (
        <PpeDialog
          title={RETRY_FILE_CONVERSION_TITLE}
          message={RETRY_FILE_CONVERSION_MESSAGE}
          onClose={() => setShowRetryConfirmation(false)}
          actions={
            <>
              <Button
                onClick={() => {
                  setShowRetryConfirmation(false);
                }}
                variant="outlined"
                size="small"
              >
                {t('BUTTON.CANCEL')}
              </Button>
              <Button
                onClick={() => {
                  trackEvent(AmplitudeEvents.RETRY_CAD_FILE_UPLOAD, { created_by: localStorage.getItem('email'), work_instruction_id: props.workInstruction.workInstructionId });
                  retryUploadCadFile();
                  setShowRetryConfirmation(false);
                }}
                variant="contained"
                size="small"
              >
                {t('PROCEDURE.RETRY_CAD_FILE_CONVERSION')}
              </Button>
            </>
          }
        />
      )}
      {cadUploadIsInProgress && (
        <Loading positionFixed={true}>
          <LinearWithValueLabel progress={cadUploadProgress} message={cadUploadProgressStatus || t('ADD_USER.RETRYING_FILE_CONVERSION')} variant={cadUploadProgressStatus.indexOf(t('ADD_USER.PROCESSING')) === -1 ? 'determinate' : 'indeterminate'} />
        </Loading>
      )}

      <Box component="div" className="right-container">
        <Box component="span" className="up-arrow" display={{ xs: 'block', md: 'none' }} />
        <Box component="div" className="description-container" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          {!props.isRevisitRequest && (
            <Typography component="div" variant="h6" className={`work-ins-text section-text ${props.isTrainer ? 'trainer-section-text' : ''}`}>
              {props.procedures.length === 1 ? props.procedures.length + ' ' + t('PROCEDURE.PROCEDURE') : props.procedures.length + ' ' + t('PROCEDURE.PROCEDURES')}
            </Typography>
          )}

          {props.isRevisitRequest && (
            <Typography component="div" variant="h6" className={`work-ins-text section-text ${props.isTrainer ? 'trainer-section-text' : ''}`}>
              {t('PROCEDURE.PROCEDURES')}
            </Typography>
          )}

          {!props.isDelete && (
            <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ fontSize: '16px' }}>{t('PROCEDURE.LANGUAGE')}</Typography>
              <Select onChange={handleLanguageChange} defaultValue={'all'} sx={{ textTransform: 'capitalize !important', boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }, color: theme.palette.primary.main, margin: '0px 4px' }}>
                <MenuItem value="all">{t('ANALYTICS_SECTION.ALL')}</MenuItem>
                {/* <MenuItem value='default'>Default Language</MenuItem> */}
                {uniqueLanguages.map((language) => {
                  return (
                    <MenuItem key={language?.name} value={language?.name?.toLowerCase()} sx={{ textTransform: 'capitalize !important' }}>
                      {language?.name?.toLowerCase()}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          )}
        </Box>

        <Box component="div" className={`procedure-container ${props.isTrainer ? '' : 'procedure-container-scroll'}`}>
          {!props.isDelete && props.isTrainer && (props.showInfoMessage || showInfoMessage) && (
            <Box component="div" className="status-modal">
              <Typography component="div" variant="button" className="section-text modal-icon" sx={{ mr: '10px' }}>
                <ModalInfoIcon />
              </Typography>
              <Typography component="div" className="align-text">
                <Box component="div">{t('PROCEDURE.3D_MODEL_GETTING_READY')}</Box>
                <Box component="div" className="status-modal-msg">
                  {t('PROCEDURE.3D_MODEL_IN_PROGRESS_MESSAGE')}
                </Box>
              </Typography>
            </Box>
          )}
          {!props.isDelete && props.isTrainer && (props.showErrorMessage || showErrorMessage) && (
            <Box component="div" className="status-failed">
              <Typography component="div" variant="button" className="section-text modal-icon" sx={{ mr: '10px' }}>
                <ErrorIcon />
              </Typography>
              <Typography component="div" className="align-text">
                <Box component="div">{props.status === '3D Model Conversion Failed' ? '3D Model Conversion Failed' : 'CAD File Upload Failed'}</Box>
                {/* <Typography>{props.workInstruction.fileStatus}</Typography> */}
                <Box component="div" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                  {cadUploadErrorCode !== 2 && (
                    <Box component="div" className="status-modal-msg-err">
                      <strong style={{ color: 'rgba(150, 33, 33, 1)' }}>{t('PROCEDURE.3D_MODEL_FAILED')}</strong> {t('PROCEDURE.UPLOAD_NEW_MESSAGE')}
                    </Box>
                  )}
                  {cadUploadErrorCode === 2 && (
                    <Box component="div" className="status-modal-msg-err">
                      <strong style={{ color: 'rgba(150, 33, 33, 1)' }}>{t('PROCEDURE.FILE_EXIST')}</strong> {t('PROCEDURE.FILE_UPLOAD_NEW')}
                    </Box>
                  )}
                  <Box className="procedure-button-align" component="div">
                    <Button size="small" sx={{ mr: '10px', mb: '8px', color: '#1C1D1F', backgroundColor: '#ffffff', borderRadius: '12px' }} variant="contained" component="label" startIcon={<AddIcon />}>
                      {t('PROCEDURE.UPLOAD_NEW')} <input type="file" onChange={uploadCadFile} accept=".stp,.step,.pvz,.gltf,.glb" hidden />
                    </Button>
                    {/* <Button size="small" onClick={uploadCadFile} disabled={!(cadUploadErrorCode !== 2 && (props.cadFileUpload || cadFileUpload))} sx={{color: '#1C1D1F', backgroundColor: '#ffffff', borderRadius: '12px'}} startIcon={<RefreshIcon />} variant="contained">Retry</Button> */}
                    <Button size="small" onClick={retryUploadCadFile} sx={{ color: '#1C1D1F', backgroundColor: '#ffffff', borderRadius: '12px', mb: '8px' }} startIcon={<RefreshIcon />} variant="contained">
                      {t('WI_LIST.RETRY')}
                    </Button>
                  </Box>
                </Box>
              </Typography>
            </Box>
          )}
          {!props.isRevisitRequest && !props.isDelete && props.isTrainer && !props.workInstruction.is2d && !(props.showErrorMessage || showErrorMessage || props.showInfoMessage || showInfoMessage) && (
            <Box component="div" sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, alignItems: 'center', justifyContent: 'flex-end', marginTop: '-1rem' }}>
              <Typography sx={{ fontSize: '14px' }}>{t('PROCEDURE.SOMETHING_WRONG') + '?'}</Typography>
              <Button onClick={() => setShowRetryConfirmation(true)}>{t('PROCEDURE.RETRY_CAD_FILE_CONVERSION')}</Button>
            </Box>
          )}
          <Box component="div" sx={{ display: 'flex', justifyContent: props.isTrainer ? 'space-between' : 'start' }}>
            {!props.isTrainer && (
              <Typography
                component="div"
                variant="button"
                className="section-text"
                sx={{
                  fontSize: '1rem',
                }}
              >
                {systemPrerequisite}
              </Typography>
            )}
          </Box>
          {props.procedures &&
            props.procedures.map((p, index) => {
              return props.workInstruction && p.isPrerequisite && (language.toLowerCase() === 'all' || p?.language?.name.toLowerCase() === language.toLowerCase()) && !props.isTrainer && <TraineeProcedureCard p={p} />;
            })}
          {!props.isTrainer && (
            <Typography component="div" variant="button" sx={{ marginTop: '1.5rem', textAlign: 'left', fontSize: '1rem' }} className="section-text">
              {systemCoreProcedure}{' '}
            </Typography>
          )}
          {props.procedures &&
            props.procedures.map((p, index) => {
              return props.workInstruction && !p.isPrerequisite && (language.toLowerCase() === 'all' || p?.language?.name.toLowerCase() === language.toLowerCase()) && !props.isTrainer && <TraineeProcedureCard p={p} />;
            })}
          {props.workInstruction && props.isTrainer && (
            <Box component="div">
              <TrainerProcedureTab status={status} procedureDetails={props.procedures} workInstruction={props.workInstruction} language={language} isDelete={props.isDelete} setProcedures={props.setProcedures} isRevisitRequest={props.isRevisitRequest}  fetchProcedures = {props.fetchProcedures}   />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default RightProceduresCard;
