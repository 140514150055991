import { AccountCircle, ArrowBack, KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { AppBar, Toolbar, IconButton, Typography, Button, Box, MenuItem, Menu, styled, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './CdsAppBar.scss';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { CompanyTheme } from '../../../entities/Trainee/MockTrainee';
import MenuIcon from '@mui/icons-material/Menu';
import Link, { LinkProps } from '@mui/material/Link';
import { Link as RouterLink, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { UserPermissions, WorkInstructionPermissions } from '../../../shared/permissions';
import { Access } from '../../Auth/Access';
import { UserGroupsPermissions } from '../../../shared/permissions';
import { PersonaPermissions } from '../../../shared/permissions';
import { OrganizationPermissions } from '../../../shared/permissions';
import { ResourcesPermissions } from '../../../shared/permissions';
import { RegExpConstants } from '../../../shared/utils/const-helpers';
import SystemSettingsDialog from '../../SystemSettings/SystemSettingsDialog';
import { IAppTheme } from '../../../entities/Organization/Theme/Theme';
import { useFileUpload } from '../../../shared/context';
import usePpeList from '../../../hooks/ppeList';
import { trackEvent } from '../../../App';
import { AmplitudeEvents, APP_SIDEBARS, PROCEDURE_VISIBILITY_SCOPE, USER_TYPES } from '../../../shared/enums';
import UserService from '../../../services/User/UserService';
import Loading from '../../Loading/Loading';
import { useTranslation } from 'react-i18next';
import { environment } from '../../../environment/environment';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useTour from '../../../hooks/tour';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import Delete from '@mui/icons-material/DeleteOutlined';
import TourContext from '../../../context/TourContext';
import { useContext } from 'react';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import AppBarNotification from './AppBarNotification';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import SideBar from '../../Sidebar/Sidebar';
import { setSidebarToOpen } from '../../../redux/features/SideBar/Sidebar.slice';

interface Props {
    theme?: CompanyTheme;
    userLoggedIn: any;
    userRole: any;
    resetTokenCallback: () => void;
    isTrainer?: boolean;
    subscriptionActive?: boolean;
    onThemeChange: (appTheme: IAppTheme) => void;
    workInstructionAppBar?: boolean;
    setWorkInstructionAppBar?: any;
}

interface LinkRouterProps extends LinkProps {
    to: string;
    replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => (
    <Link
        {...props}
        component={RouterLink as any}
    />
);

interface IMenuItem {
    id: string;
    title: string;
    paths: Array<string>;
}
interface IMainMenu {
    [key: string]: IMenuItem;
}

const mainMenu: IMainMenu = {
    WORK_INSTRUCTION: {
        id: 'WORK_INSTRUCTION',
        title: 'Work Instructions',
        paths: ['/home', '/procedures', '/assembly', '/trainer'],
    },
    ANALYTICS: {
        id: 'ANALYTICS',
        title: 'Analytics',
        paths: ['/analytics', '/visitsHistory'],
    },
    ADMIN: {
        id: 'ADMIN',
        title: 'Administration',
        paths: ['/users', '/user-group', '/persona', '/organizations', '/integration', '/integrations'],
    },
    SETUP: {
        id: 'SETUP',
        title: 'Setup',
        paths: ['/resource'],
    },
    JOB: {
        id: 'job',
        title: 'Job Management',
        paths: ['/job'],
    },
};

const CdsAppBar: React.FC<Props> = (props) => {
    const {
        createProcedure: { visibilityScope: typeOfCurrentProcedure },
    } = useAppSelector((state) => state.procedure);

    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();
    const [activeMenu, setActiveMenu] = useState(mainMenu.WORK_INSTRUCTION.id);
    const { showSystemSettings, setShowSystemSettings, setSystemSettingsTab } = usePpeList();
    const [showMenuItem, setShowMenuItem] = useState(true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const companyId = localStorage.getItem('company');
    const open = Boolean(anchorEl);
    const procedureType = useFileUpload();
    const { t } = useTranslation();
    const { isDeletedWorkInstructionBackButton } = useContext(TourContext);
    const handleSystemSettings = () => {
        // Close the menu
        handleClose();
        setShowSystemSettings(true);
    };

    const theme = useTheme();
    const location = useLocation();
    const { createWiTour, setCreateWiTour } = useTour();

    const { search: searchQuery } = location;

    type BACK_BUTTON_PAYLOAD = {
        path: string;
        text: string;
    };

    const STATIC_BACK_BUTTON = 'STATIC_BACK_BUTTON';

    const [backButton, setBackButton] = useState<BACK_BUTTON_PAYLOAD | 'STATIC_BACK_BUTTON'>({
        path: '/',
        text: '',
    });

    const openHelpDesk = () => {
        window.open('https://cdsvisualsupport.zendesk.com/hc/en-us', '_blank', 'noreferrer');
    };

    const editWorkflow = () => {
        setCreateWiTour(true);
        const homePath = new RegExp('/home');
        if (!homePath.test(location.pathname)) navigate('/');
    };
    const openRecycleBin = () => {
        navigate(`/trainer/recycleBin/${companyId}`);
    };
    const handleSystemSettingsDialogClose = () => {
        setShowSystemSettings(false);
        setSystemSettingsTab(1);
    };

    const resetToken = () => {
        props.resetTokenCallback();
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = (instance) => {
        trackEvent(AmplitudeEvents.LOGGED_OUT, { email: localStorage.getItem('email') });
        UserService.disableUserToken()
            .then(async (res) => {
                await instance.initialize();
                instance.logoutRedirect().catch((e) => {
                    console.error(e);
                });
                localStorage.clear();
                handleClose();
                //console.log("Token disabled")
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const [anchorElAdmin, setAnchorElAdmin] = React.useState<null | HTMLElement>(null);
    const [anchorElAnalytics, setAnchorElAnalytics] = React.useState<null | HTMLElement>(null);

    const handleOpenAdminMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElAdmin(event.currentTarget);
    };

    const handleCloseAdminMenu = () => {
        setAnchorElAdmin(null);
        setAnchorElNav(null);
    };

    const handleOpenAnalyticsMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElAnalytics(event.currentTarget);
    };

    const handleCloseAnalyticsMenu = () => {
        setAnchorElAnalytics(null);
        setAnchorElNav(null);
    };

    const [anchorElUserMenu, setAnchorElUserMenu] = React.useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUserMenu(event.currentTarget);
    };

    const handleIntegrationMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUserMenu(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUserMenu(null);
        setAnchorElAdmin(null);
        setAnchorElNav(null);
    };

    const [anchorElSetup, setAnchorElSetup] = React.useState<null | HTMLElement>(null);

    const handleOpenSetupMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElSetup(event.currentTarget);
    };

    const handleCloseSetupMenu = () => {
        setAnchorElSetup(null);
        setAnchorElNav(null);
    };

    const trainerHomePath = new RegExp(`trainer/home`);
    const traineeHomePath = new RegExp('home');

    useEffect(() => {
        const substitutionPath = new RegExp('/substitutions');
        const recycleBinPath = new RegExp('/trainer/recycleBin');
        const recycleBinProcedurePath = RegExp('/trainer/RecycleBinprocedures');
        const RevisitProceduresPath = RegExp('/trainer/RevisitProcedures');
        const notificationsPath = RegExp('/notifications');
        const procedurePath = new RegExp('/procedures/*');
        const addWorkInstructionPath = new RegExp('/trainer/addWorkInstruction');
        const approvalPendingList = new RegExp('/trainer/ApprovalRequests');
        const revisitPendingList = new RegExp('/trainer/RevisitRequests');
        const recertificationDueRequestList = new RegExp('/recertificationDueRequest');
        const addProcedurePath = new RegExp('/trainer/addProcedure');
        const procedureHistory = new RegExp('procedureHistory');
        const editWorkInstruction = new RegExp('trainer/editWorkInstruction');
        const analyticsProcedure = new RegExp('/trainer/analytics/');
        const completionRate = new RegExp('trainees/completionRate');
        // IMPORTANT: When base paths are same then if else order is important
        const analyticsProceduresPending = new RegExp('/analytics/procedures/pending');
        const analyticsProceduresListPage = new RegExp('/analytics/procedures');
        const analyticsProceduresAssignedDetails = new RegExp('/analytics/procedures/assigned/details');
        const analyticsProceduresAssigned = new RegExp('/analytics/procedures/assigned');
        const analyticsTraineesAssignedDetails = new RegExp('/analytics/trainees/assigned/details');
        const analyticsTraineesAssigned = new RegExp('/analytics/trainees/assigned');
        const query = new URLSearchParams(searchQuery);
        const filterType = query.get('filter');
        const startDate = query.get('startDate');
        const endDate = query.get('endDate');
        const progress = query.get('progress');

        setShowMenuItem(true);

        if (addProcedurePath.test(location.pathname)) {
            const path = matchPath({ path: `/trainer/addProcedure/:id` }, location.pathname);

            setBackButton({
                path: typeOfCurrentProcedure === PROCEDURE_VISIBILITY_SCOPE.GLOBAL ? `/trainer/home/${companyId}` : `/trainer/procedures/${companyId}/${path?.params.id}`,
                text: 'All Procedures',
            });
        } else if (editWorkInstruction.test(location.pathname)) {
            const path = matchPath({ path: `/trainer/editWorkInstruction/:id` }, location.pathname);
            setBackButton({
                path: typeOfCurrentProcedure === PROCEDURE_VISIBILITY_SCOPE.GLOBAL ? `/trainer/home/${companyId}` : `/trainer/procedures/${companyId}/${path?.params.id}`,
                text: 'All Procedures',
            });
        } else if (procedureHistory.test(location.pathname)) {
            const path = matchPath({ path: `/trainer/procedureHistory/:company/:procedureId/:versionId` }, location.pathname);
            setBackButton({
                path: location.state?.visibilityScope === PROCEDURE_VISIBILITY_SCOPE.GLOBAL ? `/trainer/home/${companyId}` : `/trainer/procedures/${companyId}/${path?.params.procedureId}`,
                text: 'All Procedures',
            });
        } else if (analyticsProcedure.test(location.pathname)) {
            setShowMenuItem(false);
            const path = matchPath({ path: `/trainer/analytics/:workInstructionId/:procedureId` }, location.pathname);
            setBackButton({
                path: location.state?.visibilityScope === PROCEDURE_VISIBILITY_SCOPE.GLOBAL ? `/trainer/home/${companyId}` : `/trainer/procedures/${companyId}/${path?.params.workInstructionId}`,
                text: 'All Procedures',
            });
        } else if (analyticsProceduresPending.test(location.pathname) || completionRate.test(location.pathname) || (approvalPendingList.test(location.pathname) && startDate && endDate)) {
            setShowMenuItem(false);
            const path = matchPath({ path: `/analytics` }, location.pathname);
            setBackButton({
                path: `/analytics?filter=${filterType}&startDate=${startDate}&endDate=${endDate}`,
                text: 'Analytics',
            });
        } else if (analyticsProceduresListPage.test(location.pathname)) {
            setShowMenuItem(false);
            setBackButton({
                path: `/analytics`,
                text: 'Analytics',
            });
        } else if (analyticsProceduresAssignedDetails.test(location.pathname)) {
            setShowMenuItem(false);
            const path = matchPath({ path: `/analytics` }, location.pathname);
            setBackButton({
                path: `/analytics/trainees/completionRate/details?startDate=${startDate}&endDate=${endDate}&filter=${filterType}`,
                text: 'Completion Rate',
            });
        } else if (analyticsProceduresAssigned.test(location.pathname)) {
            setShowMenuItem(false);
            const path = matchPath({ path: `/analytics` }, location.pathname);
            setBackButton({
                path: `/analytics?filter=${filterType}&startDate=${startDate}&endDate=${endDate}`,
                text: 'Analytics',
            });
        } else if (analyticsTraineesAssignedDetails.test(location.pathname)) {
            setShowMenuItem(false);
            const path = matchPath({ path: `/analytics/trainees/assigned/details/:userId` }, location.pathname);
            setBackButton({
                path: `/analytics/trainees/assigned?filter=${filterType}&startDate=${startDate}&endDate=${endDate}&progress=${progress}`,
                text: 'Trainees Assigned',
            });
        } else if (analyticsTraineesAssigned.test(location.pathname)) {
            setShowMenuItem(false);
            const path = matchPath({ path: `/analytics` }, location.pathname);
            setBackButton({
                path: `/analytics?filter=${filterType}&startDate=${startDate}&endDate=${endDate}`,
                text: 'Analytics',
            });
        } else if (procedurePath.test(location.pathname) || addWorkInstructionPath.test(location.pathname)) {
            setBackButton({
                path: `/trainer/home/${companyId}`,
                text: 'All Work Instructions',
            });
        } else if (recycleBinPath.test(location.pathname)) {
            const path = matchPath({ path: `/trainer/recycleBin` }, location.pathname);
            setShowMenuItem(false);
            setBackButton({
                path: `/trainer/recycleBin/${companyId}/${path?.params.id}`,
                text: 'Back',
            });
        } else if (RevisitProceduresPath.test(location.pathname)) {
            setShowMenuItem(false);
            setBackButton({
                path: `trainer/RevisitRequests`,
                text: 'Periodic Review List',
            });
        } else if (notificationsPath.test(location.pathname)) {
            setShowMenuItem(false);
            setBackButton(STATIC_BACK_BUTTON);
        } else if (recycleBinProcedurePath.test(location.pathname)) {
            setShowMenuItem(false);

            const path = matchPath({ path: `/trainer/RecycleBinprocedures/:id` }, location.pathname);
            if (isDeletedWorkInstructionBackButton) {
                setBackButton({
                    path: `/trainer/recycleBin/${companyId}`,
                    text: 'Deleted Work Instructions',
                });
            } else {
                setBackButton({
                    path: `/trainer/recycleBin/${companyId}`,
                    text: 'Deleted Procedures',
                });
            }
        } else if (substitutionPath.test(location.pathname)) {
            setShowMenuItem(false);
            setBackButton(STATIC_BACK_BUTTON);
        } else if (revisitPendingList.test(location.pathname)) {
            setShowMenuItem(false);
            setBackButton({
                path: `/trainer/home/${companyId}`,
                text: 'All Work Instructions',
            });
        } else {
            setBackButton({
                path: `/`,
                text: '',
            });
        }

        // Set active menu
        let findActiveMenu = mainMenu.WORK_INSTRUCTION.id;
        Object.keys(mainMenu).map((menu) => {
            const menuItem = mainMenu[menu];
            if (menuItem) {
                menuItem.paths.map((mi) => {
                    const regexPath = new RegExp(mi);
                    if (regexPath.test(location.pathname)) {
                        findActiveMenu = menuItem.id;
                    }
                });
            }
        });
        setActiveMenu(findActiveMenu);
    }, [companyId, location.pathname, typeOfCurrentProcedure]);

    const Bar = styled('div')({
        '&::after': {
            content: '""',
            background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, white 98.9%)`,
            position: 'absolute',
            left: '25px',
            width: '50%',
            height: '2px',
        },
    });

    const [loading, setLoading] = useState(false);

    const openSubscription = () => {
        setLoading(true);
        var cbInstance = (window as any).Chargebee.init({
            site: environment.AppConfigs.chargebeeSiteName, // your test site
        });
        var chargebeePortalInstance = cbInstance.createChargebeePortal();
        chargebeePortalInstance.open({
            close: () => {
                window.location.reload();
            },
        });
        setLoading(false);
    };

    const display = (text) => {
        if (text.toUpperCase() === 'ALL WORK INSTRUCTIONS') return t('NAVBAR.ALL_WORK_INSTRUCTIONS');
        if (text.toUpperCase() === 'ALL PROCEDURES') return t('NAVBAR.ALL_PROCEDURES');
        else if (text.toUpperCase() === 'ANALYTICS') return t('NAVBAR.ANALYTICS');
        else if (text.toUpperCase() === 'TRAINEES ASSIGNED') return t('NAVBAR.TRAINEES_ASSIGNED');
        else if (text.toUpperCase() === 'ASSIGNED PROCEDURES') return t('NAVBAR.ASSIGNED_PROCEDURES');
        else if (text.toUpperCase() === 'STATIC_BACK_BUTTON') return t('BUTTON.BACK');
        else return text;
    };

    const navigate = useNavigate();
    const fileUploadDetails = useFileUpload();

    return (
        <>
            {loading && <Loading />}
            <AppBar
                position="relative"
                className="appBar"
                elevation={0}
            >
                <Toolbar>
                    <Box
                        component={'div'}
                        sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                    >
                        <IconButton
                            sx={{ padding: '6px' }}
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            keepMounted
                            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{ display: { xs: 'block', md: 'none' } }}
                        >
                            {(props.userRole === USER_TYPES.TRAINER || props.userRole === USER_TYPES.TRAINEE) && (
                                <Access permission={WorkInstructionPermissions.READ}>
                                    <MenuItem onClick={handleCloseNavMenu}>
                                        <Typography
                                            textAlign="center"
                                            sx={{ display: 'flex', maxWidth: '100px', justifyContent: 'flex-start', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                        >
                                            {t('NAVBAR.WORK_INSTRUCTIONS') as string}
                                        </Typography>
                                    </MenuItem>
                                </Access>
                            )}
                            <Access permission={UserPermissions.CREATE}>
                                <MenuItem
                                    aria-controls="menu-admin"
                                    aria-haspopup="true"
                                    onClick={handleOpenAdminMenu}
                                    onMouseOver={handleOpenAdminMenu}
                                    // onMouseLeave={handleCloseAdminMenu}
                                >
                                    <Typography textAlign="center">{t('NAVBAR.ADMINISTRATION') as string}</Typography>
                                </MenuItem>
                            </Access>
                            <Menu
                                id="menu-admin"
                                anchorEl={anchorElAdmin}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                open={Boolean(anchorElAdmin)}
                                onClose={handleCloseAdminMenu}
                                MenuListProps={{ onMouseLeave: handleCloseAdminMenu }}
                                sx={{ display: { xs: 'block', md: 'none' } }}
                            >
                                <Access permission={UserPermissions.CREATE}>
                                    <MenuItem
                                        aria-controls="menu-user-manage"
                                        aria-haspopup="true"
                                        onClick={handleOpenUserMenu}
                                    >
                                        <Typography textAlign="center">{t('NAVBAR.USER_MANAGEMENT') as string}</Typography>
                                        <KeyboardArrowRight
                                            color="primary"
                                            sx={{ width: 20, height: 20, pl: 1 }}
                                        />
                                    </MenuItem>
                                </Access>
                                <Menu
                                    id="menu-user-manage"
                                    anchorEl={anchorElUserMenu}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    open={Boolean(anchorElUserMenu)}
                                    onClose={() => {
                                        handleCloseUserMenu();
                                        handleCloseAdminMenu();
                                    }}
                                    MenuListProps={{
                                        onMouseLeave: () => {
                                            handleCloseUserMenu();
                                            handleCloseAdminMenu();
                                        },
                                    }}
                                    sx={{ display: { xs: 'block', md: 'none' } }}
                                >
                                    <Access permission={UserPermissions.CREATE}>
                                        <LinkRouter
                                            to="/users"
                                            color={'#000000'}
                                            textAlign="center"
                                        >
                                            <MenuItem onClick={handleCloseUserMenu}>{t('NAVBAR.USER') as string}</MenuItem>
                                        </LinkRouter>
                                    </Access>
                                    <Access permission={UserGroupsPermissions.CREATE}>
                                        <LinkRouter
                                            to="/user-group"
                                            color={'#000000'}
                                            textAlign="center"
                                        >
                                            <MenuItem onClick={handleCloseUserMenu}>{t('NAVBAR.USER_GROUPS') as string}</MenuItem>
                                        </LinkRouter>
                                    </Access>
                                </Menu>
                                <Access permission={PersonaPermissions.CREATE}>
                                    <LinkRouter
                                        to="/persona"
                                        color={'#000000'}
                                        textAlign="center"
                                    >
                                        <MenuItem onClick={handleCloseAdminMenu}>Persona</MenuItem>
                                    </LinkRouter>
                                </Access>
                                <Access permission={OrganizationPermissions.CREATE}>
                                    <LinkRouter
                                        to="/organizations"
                                        color={'#000000'}
                                        textAlign="center"
                                    >
                                        <MenuItem onClick={handleCloseAdminMenu}>Organization</MenuItem>
                                    </LinkRouter>
                                </Access>
                            </Menu>
                            <Access permission={ResourcesPermissions.CREATE}>
                                <>
                                    <MenuItem
                                        aria-controls="menu-setup"
                                        aria-haspopup="true"
                                        onClick={handleOpenSetupMenu}
                                    >
                                        <Typography textAlign="center">Setup</Typography>
                                    </MenuItem>
                                    <Menu
                                        id="menu-setup"
                                        anchorEl={anchorElSetup}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        keepMounted
                                        open={Boolean(anchorElSetup)}
                                        onClose={handleCloseSetupMenu}
                                        MenuListProps={{ onMouseLeave: handleCloseSetupMenu }}
                                        sx={{ display: { xs: 'block', md: 'none' } }}
                                    >
                                        <LinkRouter
                                            to="/resource"
                                            color={'#000000'}
                                            textAlign="center"
                                        >
                                            <MenuItem onClick={handleCloseSetupMenu}>Resource</MenuItem>
                                        </LinkRouter>
                                    </Menu>
                                </>
                            </Access>
                        </Menu>

                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            href="/"
                            sx={{ mr: 2 }}
                        >
                            <img
                                className="logo-banner"
                                src={'/logos/cds_logo_default.svg'}
                                alt="logo"
                            ></img>
                        </IconButton>
                    </Box>

                    <Box
                        component={'div'}
                        sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
                    >
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            href="/"
                            disableRipple
                        >
                            <img
                                className="logo-banner"
                                src={'/logos/cds_logo_default.svg'}
                                alt="logo"
                            ></img>
                        </IconButton>
                        <div className="bar-align-items-center">
                            {backButton === STATIC_BACK_BUTTON ? (
                                <Button
                                    variant="contained"
                                    className="all-work-instructions card"
                                    sx={{
                                        color: theme.palette.primary.main,
                                    }}
                                    startIcon={<ArrowBack />}
                                    onClick={() => {
                                        navigate(-1);
                                    }}
                                >
                                    <Typography sx={{ justifyContent: 'flex-start', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{display(STATIC_BACK_BUTTON)}</Typography>
                                </Button>
                            ) : (
                                backButton.text.length > 0 && (
                                    // <LinkRouter to={backButton.path}>
                                    <Button
                                        variant="contained"
                                        className="all-work-instructions card"
                                        sx={{
                                            color: theme.palette.primary.main,
                                        }}
                                        startIcon={<ArrowBack />}
                                        onClick={() => {
                                            navigate(backButton.path);
                                            fileUploadDetails.dispatch({
                                                type: 'clearCad',
                                                payload: undefined,
                                            });
                                            procedureType.dispatch({
                                                type: 'resetProcedureType',
                                                payload: '',
                                            });
                                        }}
                                    >
                                        <Typography sx={{ justifyContent: 'flex-start', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{display(backButton.text)}</Typography>
                                    </Button>
                                    // </LinkRouter>
                                )
                            )}
                            {(props.userRole === USER_TYPES.TRAINER || props.userRole === USER_TYPES.TRAINEE) && showMenuItem && props.subscriptionActive && (
                                <Access permission={WorkInstructionPermissions.READ}>
                                    <Button
                                        variant={activeMenu === mainMenu.WORK_INSTRUCTION.id ? 'contained' : 'text'}
                                        className={activeMenu === mainMenu.WORK_INSTRUCTION.id ? 'activeButton' : 'nonActiveButton'}
                                        href={trainerHomePath.test(location.pathname) ? `` : `/`}
                                        onClick={() => props.setWorkInstructionAppBar(true)}
                                        sx={{ ml: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', justifyContent: 'flex-start' }}
                                    >
                                        <Typography sx={{ maxWidth: '130px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', justifyContent: 'flex-start' }}>{t('NAVBAR.WORK_INSTRUCTIONS') as string}</Typography>
                                    </Button>
                                </Access>
                            )}

                            {props.isTrainer && showMenuItem && (
                                <Access permission={UserPermissions.CREATE}>
                                    <Button
                                        sx={{ ml: 1 }}
                                        aria-controls="menu-admin-md"
                                        aria-haspopup="true"
                                        variant={activeMenu === mainMenu.ANALYTICS.id ? 'contained' : 'text'}
                                        className={activeMenu === mainMenu.ANALYTICS.id ? 'activeButton' : 'nonActiveButton'}
                                        size="medium"
                                        onClick={handleOpenAnalyticsMenu}
                                        onMouseOver={handleOpenAnalyticsMenu}
                                        endIcon={<KeyboardArrowDown />}
                                        //onMouseLeave={handleCloseAdminMenu}
                                    >
                                        {t('NAVBAR.ANALYTICS') as string}
                                    </Button>
                                </Access>
                            )}
                            <Menu
                                autoFocus={true}
                                id="menu-admin-md"
                                anchorEl={anchorElAnalytics}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                keepMounted
                                open={Boolean(anchorElAnalytics)}
                                onClose={handleCloseAnalyticsMenu}
                                MenuListProps={{ onMouseLeave: handleCloseAnalyticsMenu }}
                                sx={{ display: { xs: 'none', md: 'block' } }}
                            >
                                {props.userRole === USER_TYPES.TRAINER && showMenuItem && props.subscriptionActive && (
                                    <LinkRouter
                                        to="/analytics"
                                        color={'#000000'}
                                        textAlign="center"
                                    >
                                        <Access permission={WorkInstructionPermissions.CREATE}>
                                            <MenuItem
                                                aria-controls="menu-user-manage-md"
                                                aria-haspopup="true"
                                            >
                                                {t('NAVBAR.OVERVIEW') as string}
                                            </MenuItem>
                                        </Access>
                                    </LinkRouter>
                                )}
                                {props.userRole === USER_TYPES.TRAINER && showMenuItem && props.subscriptionActive && (
                                    <LinkRouter
                                        to="/visitsHistory"
                                        color={'#000000'}
                                        textAlign="center"
                                    >
                                        <Access permission={WorkInstructionPermissions.CREATE}>
                                            <MenuItem
                                                aria-controls="menu-user-manage-md"
                                                aria-haspopup="true"
                                            >
                                                {t('NAVBAR.VISITS_HISTORY') as string}
                                            </MenuItem>
                                        </Access>
                                    </LinkRouter>
                                )}
                            </Menu>

                            {showMenuItem && (
                                <Access permission={UserPermissions.CREATE}>
                                    <Button
                                        sx={{ ml: 1 }}
                                        aria-controls="menu-admin-md"
                                        aria-haspopup="true"
                                        variant={activeMenu === mainMenu.ADMIN.id ? 'contained' : 'text'}
                                        className={activeMenu === mainMenu.ADMIN.id ? 'activeButton' : 'nonActiveButton'}
                                        size="medium"
                                        onClick={handleOpenAdminMenu}
                                        onMouseOver={handleOpenAdminMenu}
                                        endIcon={<KeyboardArrowDown />}
                                        //onMouseLeave={handleCloseAdminMenu}
                                    >
                                        {t('NAVBAR.ADMINISTRATION') as string}
                                    </Button>
                                </Access>
                            )}
                            <Menu
                                autoFocus={true}
                                id="menu-admin-md"
                                anchorEl={anchorElAdmin}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                keepMounted
                                open={Boolean(anchorElAdmin)}
                                onClose={handleCloseAdminMenu}
                                MenuListProps={{ onMouseLeave: handleCloseAdminMenu }}
                                sx={{ display: { xs: 'none', md: 'block' } }}
                            >
                                <Access permission={UserPermissions.CREATE}>
                                    <MenuItem
                                        aria-controls="menu-user-manage-md"
                                        aria-haspopup="true"
                                        onClick={handleOpenUserMenu}
                                    >
                                        <Typography
                                            textAlign="center"
                                            className="nonActiveButton"
                                        >
                                            {t('NAVBAR.USER_MANAGEMENT') as string}
                                        </Typography>
                                        <KeyboardArrowRight sx={{ width: 20, height: 20, pl: 1 }} />
                                    </MenuItem>
                                </Access>
                                {!environment.AppConfigs.govServer && (
                                    <Access permission={UserPermissions.CREATE}>
                                        <LinkRouter
                                            to="/integrations"
                                            color={'#000000'}
                                            textAlign="center"
                                        >
                                            <MenuItem onClick={handleCloseUserMenu}>{t('BUTTON.INTEGRATIONS')}</MenuItem>
                                        </LinkRouter>
                                    </Access>
                                )}

                                <Menu
                                    id="menu-user-manage-md"
                                    anchorEl={anchorElUserMenu}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'center',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    open={Boolean(anchorElUserMenu)}
                                    onClose={() => {
                                        handleCloseUserMenu();
                                        handleCloseAdminMenu();
                                    }}
                                    MenuListProps={{
                                        onMouseLeave: () => {
                                            handleCloseUserMenu();
                                            handleCloseAdminMenu();
                                        },
                                    }}
                                    sx={{ display: { xs: 'none', md: 'block' } }}
                                >
                                    <Access permission={UserPermissions.CREATE}>
                                        <LinkRouter
                                            to="/users"
                                            textAlign="center"
                                            className="nonActiveButton"
                                        >
                                            <MenuItem onClick={handleCloseUserMenu}>{t('NAVBAR.USER') as string}</MenuItem>
                                        </LinkRouter>
                                    </Access>
                                    <Access permission={UserGroupsPermissions.CREATE}>
                                        <LinkRouter
                                            to="/user-group"
                                            textAlign="center"
                                            className="nonActiveButton"
                                        >
                                            <MenuItem onClick={handleCloseUserMenu}>{t('NAVBAR.USER_GROUPS') as string}</MenuItem>
                                        </LinkRouter>
                                    </Access>
                                </Menu>

                                <Access permission={PersonaPermissions.CREATE}>
                                    <LinkRouter
                                        to="/persona"
                                        textAlign="center"
                                        className="nonActiveButton"
                                    >
                                        <MenuItem onClick={handleCloseAdminMenu}>Persona</MenuItem>
                                    </LinkRouter>
                                </Access>
                                <Access permission={OrganizationPermissions.CREATE}>
                                    <LinkRouter
                                        to="/organizations"
                                        textAlign="center"
                                        className="nonActiveButton"
                                    >
                                        <MenuItem onClick={handleCloseAdminMenu}>Organization</MenuItem>
                                    </LinkRouter>
                                </Access>
                            </Menu>
                            <Access permission={ResourcesPermissions.CREATE}>
                                <>
                                    <MenuItem
                                        aria-controls="menu-setup"
                                        aria-haspopup="true"
                                        onClick={handleOpenSetupMenu}
                                    >
                                        <Button
                                            sx={{ ml: 1 }}
                                            variant={activeMenu === mainMenu.SETUP.id ? 'contained' : 'text'}
                                            className={activeMenu === mainMenu.SETUP.id ? 'activeButton' : 'nonActiveButton'}
                                            size="medium"
                                        >
                                            Setup
                                        </Button>
                                    </MenuItem>
                                    <Menu
                                        id="menu-setup-md"
                                        anchorEl={anchorElSetup}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        keepMounted
                                        open={Boolean(anchorElSetup)}
                                        onClose={handleCloseSetupMenu}
                                        MenuListProps={{ onMouseLeave: handleCloseSetupMenu }}
                                        sx={{ display: { xs: 'none', md: 'block' } }}
                                    >
                                        <LinkRouter
                                            to="/resource"
                                            textAlign="center"
                                            className="nonActiveButton"
                                        >
                                            <MenuItem onClick={handleCloseSetupMenu}>Resource</MenuItem>
                                        </LinkRouter>
                                    </Menu>
                                </>
                            </Access>

                            {/* {showMenuItem && <Access permission={UserPermissions.CREATE}>
                                <LinkRouter
                                    to="/job"
                                    textAlign="center"
                                    // className="nonActiveButton"
                                >
                                    <Button
                                        sx={{ ml: 1, color: 'black' }}
                                        size="medium"
                                        variant={activeMenu === mainMenu.JOB.id ? 'contained' : 'text'}
                                        className={activeMenu === mainMenu.JOB.id ? 'activeButton' : 'nonActiveButton'}
                                    >
                                        {t('NAVBAR.JOB_MANAGEMENT')}
                                    </Button>
                                </LinkRouter>
                            </Access>} */}
                          
                        </div>
                    </Box>
                    <LanguageSelector />
                    <AppBarNotification
                        isTrainer={props.isTrainer}
                        companyId={companyId ?? undefined}
                    />
                    <Button
                        sx={{
                            mr: 2,
                            border: '1px solid rgba(0, 75, 141, 0.2)',
                            borderRadius: '8px',
                            boxShadow: 'none',
                            '&:hover': {
                                boxShadow: 'none',
                            },
                            display: { xs: 'none', md: 'flex' },
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            justifyContent: 'flex-start',
                        }}
                        className="login-btn"
                        variant="contained"
                        startIcon={<AccountCircle />}
                        endIcon={<KeyboardArrowDown />}
                        aria-controls={open ? 'demo-positioned-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        onMouseOver={handleClick}
                    >
                        <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', justifyContent: 'flex-start' }}>{props.userLoggedIn}</Typography>
                    </Button>

                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        PaperProps={{
                            style: {
                                width: 'auto',
                                boxShadow: 'rgba(149, 157, 165, 0.5) 0px 8px 24px',
                            },
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{ onMouseLeave: handleClose }}
                        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    >
                        <div className="profile-name">{props.userLoggedIn}</div>
                        <div className="role">{props.userRole}</div>
                        <div className="divider"></div>
                        {/* <MenuItem onClick={handleClose}>
              <Profile />
              <div style={{ marginLeft: '8px' }}>t('USER_PROFILE_INFO')</div>
            </MenuItem> */}
                        {/* {props.isTrainer && (
              <MenuItem onClick={handleClose}>
                <Administration />
                <div style={{ marginLeft: '8px' }}>Administration</div>
              </MenuItem>
            )} */}
                        {props.subscriptionActive && (
                            <MenuItem onClick={handleSystemSettings}>
                                <Box
                                    component="div"
                                    sx={{ display: 'flex', minWidth: '30px' }}
                                >
                                    {/* <SystemSettings /> */}
                                    <SettingsSuggestOutlinedIcon />
                                </Box>
                                <div>{t('NAVBAR.USER_SYSTEM_SETTINGS') as string}</div>
                            </MenuItem>
                        )}

                        {!props.subscriptionActive && (
                            <MenuItem onClick={() => navigate('manageSubscription')}>
                                <Box
                                    component="div"
                                    sx={{ display: 'flex', minWidth: '30px' }}
                                >
                                    <InfoOutlinedIcon />
                                </Box>
                                <div>Subscription Details</div>
                            </MenuItem>
                        )}

                        {/* {props.isTrainer && (
              <MenuItem onClick={() => openSubscription()}>
                <Box component="div" sx={{ display: 'flex', minWidth: '30px' }}>
                  <PaymentIcon />
                </Box>
                <div>{t('NAVBAR.MANAGE_SUBSCRIPTION') as string}</div>
              </MenuItem>
            )} */}
                        {props.isTrainer && (
                            <MenuItem onClick={editWorkflow}>
                                <Box
                                    component="div"
                                    sx={{ display: 'flex', minWidth: '30px' }}
                                >
                                    <PlayCircleOutlineOutlinedIcon />
                                </Box>
                                {t('NAVBAR.RUN_DEMO_WALKTHROUGH')}
                            </MenuItem>
                        )}

                        <MenuItem onClick={openHelpDesk}>
                            <Box
                                component="div"
                                sx={{ display: 'flex', minWidth: '30px' }}
                            >
                                <HelpOutlineOutlinedIcon />
                            </Box>
                            <div>{t('NAVBAR.HELP') as string}</div>
                        </MenuItem>

                        {props.isTrainer && (
                            <MenuItem onClick={openRecycleBin}>
                                <Box
                                    component="div"
                                    sx={{ display: 'flex', minWidth: '30px' }}
                                >
                                    <Delete />
                                </Box>
                                <div>{t('NAVBAR.RECYCLE_BIN') as string}</div>
                            </MenuItem>
                        )}

                        {isAuthenticated && (
                            <MenuItem
                                onClick={() => {
                                    handleLogout(instance);
                                    //resetToken();
                                }}
                            >
                                <Box
                                    component="div"
                                    sx={{ display: 'flex', minWidth: '30px' }}
                                >
                                    <ExitToAppOutlinedIcon />
                                </Box>
                                <div>{t('NAVBAR.USER_LOGOUT') as string}</div>
                            </MenuItem>
                        )}
                    </Menu>

                    <a
                        className="navbar-brand"
                        href="/"
                    >
                        <img
                            className="org-logo"
                            src={props.theme ? (RegExpConstants.base64.test(props.theme.logoUrl) ? 'data:image/png;base64,' : '') + props.theme.logoUrl : '/logos/esg_logo.png'}
                            alt="logo"
                        ></img>
                    </a>
                    {isAuthenticated && (
                        <IconButton
                            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                            aria-controls={open ? 'demo-positioned-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            onMouseOver={handleClick}
                        >
                            <AccountCircle />
                        </IconButton>
                    )}
                </Toolbar>
            </AppBar>
            <Bar />
            <SystemSettingsDialog
                isTrainer={props.isTrainer}
                show={showSystemSettings}
                onClose={handleSystemSettingsDialogClose}
                onThemeChange={(appTheme: IAppTheme) => props.onThemeChange(appTheme)}
            />
            <SideBar />
        </>
    );
};

export default CdsAppBar;

const LanguageSelector = (props) => {
    const { companyTheme } = useAppSelector((state) => state.app);
    const dispatch = useAppDispatch();
    const handleClick = () => {
        dispatch(setSidebarToOpen(APP_SIDEBARS.USER_LANGUAGE));
    };
    return (
        <IconButton
            sx={{
                border: '1px solid rgba(0, 75, 141, 0.2)',
                borderRadius: '8px',
                padding: '6px 8px',
                // color: "#000000!important",
            }}
            onClick={handleClick}
        >
            <Typography sx={{ color: 'black' }}>{companyTheme?.language.id}</Typography>
            <KeyboardArrowDown color="primary" />
        </IconButton>
    );
};
