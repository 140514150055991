import React, { useState, useEffect, Fragment, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Grid, IconButton, Typography, useTheme, TableContainer, TableBody, TableCell, TableRow, Table } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useCanvasState } from '../../state/canvasState';
import './MediaContainer.scss';
import { useTranslation } from 'react-i18next';
import LazyImage from '../LazyImage/LazyImage';

interface Props {
    stepMediaReferences: any[];
    is2d?: boolean;
    introStep?: number;
    showIntro?: boolean;
    procedureName?: string;
    workInstructionName?: string;
}

const MediaContainer: React.FC<Props> = ({ stepMediaReferences, is2d, introStep, showIntro, workInstructionName, procedureName }) => {
    const canvasState = useCanvasState();
    const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
    const [hotSpotMedia, setHotSpotMedia] = useState<any>(null);
    const theme = useTheme();
    const containerRef = useRef<HTMLDivElement | null>(null);
    const SCROLL_VALUE = 120;
    useEffect(() => {
        if (stepMediaReferences.length > 0) {
            setHotSpotMedia(stepMediaReferences[0]);
            setCurrentMediaIndex(0);
        }
    }, [stepMediaReferences]);

    const loadMedia = (direction: number) => {
        if (stepMediaReferences.length > 0) {
            let currentIndex = currentMediaIndex;
            if (direction === -1) {
                // Previous
                if (currentIndex > 0) {
                    currentIndex--;
                }
            } else {
                // Next
                if (currentIndex < stepMediaReferences.length) {
                    currentIndex++;
                }
            }

            stepMediaReferences.map((media, i) => {
                if (i === currentIndex) {
                    setCurrentMediaIndex(currentIndex);
                    setHotSpotMedia(media);
                }
            });
        }
    };
    const scrollLeft = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({left:-SCROLL_VALUE,behavior:'smooth'}); 
        }
    };

    const scrollRight = () => {
        if (containerRef.current) {
          containerRef.current.scrollBy({left:SCROLL_VALUE,behavior:'smooth'});
        }
    };

    const noStepMediaReferences = (
        <>
            <Box
                component="div"
                className="no-step-media-container"
            >
                <Typography>No Step Media Found</Typography>
            </Box>
        </>
    );

    const mediaContainerOutput = (
        <>
            <Box
                component="div"
                className="media-container"
                style={{ width: '100%' }}
            >
                {hotSpotMedia && (hotSpotMedia.fileType === 'image/jpeg' || hotSpotMedia.fileType === 'image/png') && (
                    <LazyImage
                        className="img-step-media procho"
                        src={hotSpotMedia.objectUrl ? hotSpotMedia.objectUrl : hotSpotMedia.dataUrl}
                        alt={is2d ? 'Loading...' : 'Media reference not found!'}
                        width="350px"
                    />
                )}
                {hotSpotMedia && (hotSpotMedia.fileType === 'video/mp4' || hotSpotMedia.fileType === 'video/quicktime') && (
                    <Fragment key={hotSpotMedia.objectUrl}>
                        {!hotSpotMedia.objectUrl && !hotSpotMedia.dataUrl ? (
                            'Loading...'
                        ) : (
                            <video
                                className="img-step-media"
                                width="80%"
                                preload="metadata"
                                controls
                            >
                                <source
                                    src={hotSpotMedia.objectUrl ? hotSpotMedia.objectUrl : hotSpotMedia.dataUrl}
                                    type="video/mp4"
                                />
                            </video>
                        )}
                    </Fragment>
                )}
                {hotSpotMedia && hotSpotMedia.fileType.includes('pdf') && (
                    <object
                        className="img-step-media"
                        height={'100%'}
                        type="application/pdf"
                        data={hotSpotMedia.objectUrl ? hotSpotMedia.objectUrl : hotSpotMedia.dataUrl}
                    />
                )}
            </Box>
        </>
    );

    const mediaContainerControls = (
        <>
            <Box
                component="div"
                className="media-container-controls"
            >
                {stepMediaReferences.length > 1 && (
                    <IconButton
                        className="media-2d-control-left"
                        aria-label="previous"
                        title="Previous Media Reference"
                        onClick={() => {
                          loadMedia(-1)
                          scrollLeft();
                        }}
                        color="secondary"
                        disabled={currentMediaIndex <= 0}
                        sx={{ border: '1px solid #fff', background: '#fff', opacity: 0.75, boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px' }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                )}
                <Box
                    component="div"
                    className="media-thumbnail-container"
                    ref={containerRef}
                >
                    {stepMediaReferences &&
                        stepMediaReferences.map((media, i) => (
                            <Box
                                component="div"
                                // className={`media-thumbnail-content ${i == currentMediaIndex ? 'media-thumbnail-content--selected' : ''}`}
                                className="media-thumbnail-content"
                                sx={i == currentMediaIndex ? { height: '75px', width: '100px', borderRadius: '8px', border: '2px solid', borderColor: theme.palette.primary.light, opacity: '1.0!important' } : { height: '75px', width: '100px' }}
                                onClick={() => {
                                    setCurrentMediaIndex(i);
                                    setHotSpotMedia(stepMediaReferences[i]);
                                    //   openHotSpotModel(media);
                                }}
                                key={i}
                            >
                                {(media.fileType === 'image/jpeg' || media.fileType === 'image/png') && (
                                    <img
                                        src={media.objectUrl ? media.objectUrl : media.dataUrl}
                                        alt="Loading..."
                                    />
                                )}
                                {(media.fileType === 'video/mp4' || media.fileType === 'video/quicktime') && (
                                    <Fragment key={media.objectUrl}>
                                        {!media.objectUrl && !media.dataUrl ? (
                                            'Loading...'
                                        ) : (
                                            <video
                                                className="video-thumbnail"
                                                controls={false}
                                                preload="metadata"
                                            >
                                                <source
                                                    src={`${media.objectUrl ? media.objectUrl : media.dataUrl}#t=0.5`}
                                                    type="video/mp4"
                                                />
                                            </video>
                                        )}
                                    </Fragment>
                                )}
                                {media.fileType.includes('pdf') && (
                                    <object
                                        style={{ pointerEvents: 'none', zIndex: -1, width: '100px', height: '100%' }}
                                        type="application/pdf"
                                        height="100%"
                                        data={media.objectUrl ? media.objectUrl : media.dataUrl}
                                    ></object>
                                )}
                            </Box>
                        ))}
                </Box>
                {stepMediaReferences.length > 1 && (
                    <IconButton
                        className="media-2d-control-right"
                        aria-label="next"
                        title="Next Media Reference"
                        onClick={() => {
                          loadMedia(1);
                          scrollRight();
                        }}
                        color="secondary"
                        disabled={currentMediaIndex >= stepMediaReferences.length - 1}
                        sx={{ border: '1px solid #fff', background: '#fff', opacity: 0.75, boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px' }}
                    >
                        <ArrowForwardIcon />
                    </IconButton>
                )}
            </Box>
        </>
    );

    return (
        <Grid
            container
            spacing={0}
            className={canvasState.isShowStepsVisible == false ? 'media-grid' : 'media-grid--padding-left'}
            direction={'column'}
            alignItems="center"
        >
            <Grid
                className="media-container-grid-item"
                item
                xs={10}
            >
                {stepMediaReferences.length > 0 ? mediaContainerOutput : noStepMediaReferences}
            </Grid>
            <Grid
                className="media-container-controls-grid-item"
                item
                xs={2}
            >
                {mediaContainerControls}
            </Grid>
        </Grid>
    );
};

export default MediaContainer;
