import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { Box, Typography, Select, MenuItem, IconButton, Stack, styled, CircularProgress } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { Paper, Card } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';
const checkBox_Cell_Width = '48px';
export const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
    borderRadius: 0,
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiSvgIcon-root': {
        color: 'rgba(204, 204, 204, 1)',
    },
    '&.Mui-checked .MuiSvgIcon-root': {
        color: 'rgba(16, 121, 191, 1)',
    },
    '&.MuiCheckbox-indeterminate .MuiSvgIcon-root': {
        color: 'rgba(16, 121, 191, 1)',
    },
}));
const CustomIconButton = styled(IconButton)(({ theme }) => ({
    width: '24px',
    height: '24px',
    padding: '0',
    border: '1px solid rgba(70, 79, 96, 0.24)',
    background: 'rgba(247, 249, 252, 1)',
    borderRadius: '6px',
}));
export interface ColumnProps {
    label: any;
    id: string;
    isSortBy?: boolean;
    render?: (value, row) => React.ReactNode;
    align?: 'left' | 'right' | 'center';
}
interface TableProps {
    isCheckBoxEnabled: boolean;
    data: any[];
    columns: ColumnProps[];
    setSelectedRow?: React.Dispatch<React.SetStateAction<any>>;
    selectRow?: any;
    onClickRow?: (e, row) => void;
    isPaginationEnabled: boolean;
    hasMore?: boolean;
    loadNext?: () => void;
    totalItems?: number;
    isProgress?: boolean;
}
function descendingComparator(a, b, orderBy) {
    const aValue = a[orderBy];
    const bValue = b[orderBy];
    if (typeof aValue === 'string' && typeof bValue === 'string') {
        return bValue.localeCompare(aValue);
    }
    if (typeof aValue === 'number' && typeof bValue === 'number') {
        return bValue - aValue; // Descending order for numbers
    }
    if (typeof aValue === 'boolean' && typeof bValue === 'boolean') {
        return bValue === aValue ? 0 : bValue ? 1 : -1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
const rowArray = [10, 15, 20];
const CreateTableHead = (props) => {
    const { isCheckBoxEnabled, tableHead, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        console.log(property);
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow sx={{ background: 'rgba(240, 242, 250, 1)', borderBottom: '1px solid rgba(240, 242, 250, 1)' }}>
                {isCheckBoxEnabled && (
                    <TableCell
                        padding="checkbox"
                        align="left"
                        sx={{ border: 'none', width: checkBox_Cell_Width }}
                    >
                        <CustomCheckbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        />
                    </TableCell>
                )}
                {tableHead.map((headCell, index) => (
                    <TableCell
                        key={index}
                        align={headCell.align}
                        sx={{ border: 'none' }}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={headCell.isSortBy ? createSortHandler(headCell.id) : undefined}
                            hideSortIcon={!headCell.isSortBy}
                            sx={{
                                fontSize: '16px',
                                fontWeight: '400',
                                textTransform: 'capitalize',
                                '& .MuiTableSortLabel-icon': {
                                    opacity: 1,
                                    color: 'rgba(0, 0, 0, 0.5)',
                                },
                                '&.Mui-active .MuiTableSortLabel-icon': {
                                    color: 'rgba(0, 0, 0, 1)',
                                },
                            }}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

CreateTableHead.propTypes = {
    tableHead: PropTypes.array.isRequired,
    numSelected: PropTypes.number,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    isCheckBoxEnabled: PropTypes.bool.isRequired,
};

export const PaginatedTableComponent: React.FC<TableProps> = ({ isCheckBoxEnabled, isPaginationEnabled, data, columns, setSelectedRow, selectRow, onClickRow, hasMore, loadNext, totalItems, isProgress }) => {
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(isPaginationEnabled ? rowArray[0] : data.length);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            // const newSelected = data.map((n) => ({ id: n.id, email: n.email }));
            const newSelected = data.map((n) => ({ id: n.id, row: n }));
            // setSelected(newSelected);
            setSelectedRow && setSelectedRow(newSelected);
            return;
        }
        // setSelected([]);
        setSelectedRow && setSelectedRow([]);
    };

    const handleClick = (event, currentRow) => {
        if (!selectRow) return;
        event.stopPropagation();
        const selectedIndex = selectRow.findIndex((item) => item.id === currentRow.id);
        let newSelected = [];
        if (selectedIndex === -1) {
            //@ts-ignore
            // newSelected = newSelected.concat(selectRow, { id, email });
            newSelected = newSelected.concat(selectRow, { id: currentRow.id, row: currentRow });
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectRow.slice(1));
        } else if (selectedIndex === selectRow.length - 1) {
            newSelected = newSelected.concat(selectRow.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selectRow.slice(0, selectedIndex), selectRow.slice(selectedIndex + 1));
        }
        // setSelected(newSelected);
        setSelectedRow && setSelectedRow(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
    const shouldSlice = isPaginationEnabled ? true : false;
    const visibleRows = React.useMemo(() => {
        const sortedData = [...data].sort(getComparator(order, orderBy));
        return shouldSlice ? sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : sortedData;
    }, [order, orderBy, page, rowsPerPage, data, shouldSlice]);
    const startItem = page * rowsPerPage + 1;
    const endItem = Math.min(data.length, (page + 1) * rowsPerPage);
    const totalPage = Math.ceil((totalItems ?? 0) / rowsPerPage);
    useEffect(() => {
        if (endItem >= data.length && hasMore) {
            loadNext && loadNext();
        }
    }, [endItem]);
    return (
        <Paper
            sx={{ padding: 0, width: '100%', height: '100%', overflowY: isPaginationEnabled ? 'unset' : 'auto', borderRadius: '6px', border: '1px solid #E0E2EC', boxShadow: '0px 8px 13px #74777F0F' }}
            elevation={0}
        >
            <TableContainer sx={{ width: '100%' }}>
                <Table
                    sx={{
                        '& .MuiTableCell-root': {
                            padding: '10px 12px',
                            border: 'none',
                        },
                    }}
                    aria-labelledby="user_list"
                >
                    <CreateTableHead
                        isCheckBoxEnabled={isCheckBoxEnabled}
                        tableHead={columns}
                        numSelected={selectRow?.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={data.length}
                    />
                    <TableBody>
                        {visibleRows.map((row, index) => {
                            const isItemSelected = selectRow?.some((item) => item.id === row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <TableRow
                                    hover
                                    //@ts-ignore
                                    onClick={(event) => {
                                        onClickRow && onClickRow(event, row);
                                        handleClick(event, row);
                                    }}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.id}
                                    selected={isItemSelected}
                                    sx={{ cursor: 'pointer', background: 'rgba(255, 255, 255, 1)', borderBottom: '1px solid rgba(240, 242, 250, 1)' }}
                                >
                                    {isCheckBoxEnabled && (
                                        <TableCell
                                            padding="checkbox"
                                            sx={{ border: 'none', width: checkBox_Cell_Width }}
                                            onClick={(event) => handleClick(event, row)}
                                        >
                                            <CustomCheckbox
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                                onClick={(event) => handleClick(event, row)}
                                            />
                                        </TableCell>
                                    )}
                                    {columns.map((col, colIndex) => (
                                        <TableCell
                                            key={colIndex}
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            align={col.align || 'left'}
                                            sx={{ fontSize: '16px', fontWeight: '400', textTransform: 'capitalize', border: 'none' }}
                                        >
                                            {col.render ? col.render(row[col.id], row) : '-'}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 48 * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {isPaginationEnabled && (
                <PageAction
                    rows={data}
                    list={rowArray}
                    page={page}
                    startItem={startItem}
                    endItem={endItem}
                    totalPage = {totalPage}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    isProgress={isProgress}
                />
            )}
        </Paper>
    );
};
export default PaginatedTableComponent;
interface PageActionProps {
    rows: any;
    list: number[];
    startItem: number;
    endItem: number;
    totalPage:number;
    rowsPerPage: number;
    page: number;
    handleChangePage: (e: any, no: any) => void;
    handleChangeRowsPerPage: (e: any) => void;
    isProgress?: boolean;
}
const PageAction: React.FC<PageActionProps> = ({ rows, list, startItem, endItem, rowsPerPage, handleChangePage, page, handleChangeRowsPerPage, totalPage, isProgress }) => {
    const { t } = useTranslation();
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 20px', background: 'rgba(249, 249, 249, .4)' }}>
            <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                {startItem}-{endItem} of {rows.length}
            </Typography>
            <Box
                display="flex"
                alignItems="center"
                gap="20px"
            >
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                >
                    <Typography sx={{ fontSize: '14px', fontWeight: 500, whiteSpace: 'nowrap' }}>Rows Per Page:</Typography>
                    <Select
                        value={rowsPerPage}
                        //@ts-ignore
                        onChange={(e) => handleChangeRowsPerPage(e)}
                        size="small"
                        disabled={isProgress}
                        sx={{
                            minWidth: 'fit-content',
                            fontSize: '14px',
                            fontWeight: 600,
                            color: 'rgba(24, 28, 34, 1)',
                            border: 'none',
                            '& fieldset': {
                                border: 'none',
                            },
                            '& .MuiSelect-select': {
                                padding: '4px 8px 4px 4px',
                            },
                        }}
                    >
                        {list.map((option, index) => (
                            <MenuItem
                                key={index}
                                value={option}
                            >
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </Stack>
                <Box
                    display="flex"
                    gap="8px"
                    alignItems={'center'}
                >
                    <CustomIconButton
                        disabled={page === 0}
                        onClick={(e) => handleChangePage(e, page - 1)}
                        sx={{
                            cursor: page === 0 ? 'default' : 'pointer',
                            opacity: page === 0 ? 0.5 : 1,
                        }}
                    >
                        <KeyboardArrowLeftIcon />
                    </CustomIconButton>
                    <Box
                        component={'span'}
                        sx={{ fontSize: '14px', fontWeight: 500 }}
                    >
                        {page + 1}/{totalPage}
                    </Box>
                    <>
                        {isProgress ? (
                            <CircularProgress size={20} />
                        ) : (
                            <CustomIconButton
                                disabled={endItem >= rows.length}
                                onClick={(e) => handleChangePage(e, page + 1)}
                                sx={{
                                    cursor: endItem >= rows.length ? 'default' : 'pointer',
                                    opacity: endItem >= rows.length ? 0.5 : 1,
                                }}
                            >
                                <KeyboardArrowRightIcon />
                            </CustomIconButton>
                        )}
                    </>
                </Box>
            </Box>
        </Box>
    );
};
export const TableCellHeading: React.FC<{ value: any }> = ({ value }) => {
    const { t } = useTranslation();
    return <Typography sx={{ fontSize: '16px', fontWeight: '400', textTransform: 'none', border: 'none', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'start' }}>{t(value ?? '-')}</Typography>;
};
