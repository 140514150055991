import { useContext, useEffect, useRef, useState } from 'react';
import { mediaType } from '../../../../entities/Trainee/WorkInstructions';
import MediaUploadDialog from '../../../Trainer/Dialog/MediaUploadDialog/MediaUploadDialog';
import { FormControl, FormControlLabel, IconButton, InputAdornment, List, OutlinedInput, Typography, FormHelperText, Box, Button, DialogActions, CircularProgress, useTheme } from '@mui/material';
import { MediaUploadIcon } from '../../../Icons/CdsIcons';
import { useTranslation } from 'react-i18next';
import MediaRef from '../../../Trainer/Dialog/MediaUploadDialog/MediaRef';
import { useFormik } from 'formik';
import { Toast } from '../../../Notifications/Notification';
import FileManagerService from '../../../../services/FileManager/FileManagerService';
import TutorialService from '../../../../services/trainee/TraineeService';
import UserContext from '../../../../context/UserContext';
import { getFormattedDateFromUnixTime, getFormattedTimeFromUnixTime } from '../../../../shared/utils/formateDate';
import Avatar from './Avatar';
import CommentCrud from './CommentCrud';
import {} from './ThreadComment';
import { CommentFilePreview } from './CommonFilePreview';
import { convertFileBlobToMediaType } from '../../../../services/Mapper/Mapper';
import TrainerService from '../../../../services/trainer/TrainerService';

export const AddCommentToExistingThread = (props) => {
    const [showMediaUploadDialog, setShowMediaUploadDialog] = useState<boolean>(false);
    const [selectedFiles, setSelectedFiles] = useState<Array<mediaType>>([]);
    const [mentorIds, setMentorIds] = useState<Array<any>>([]);
    const [mediaFiles, setMediaFiles] = useState<Array<mediaType>>([]);
    const [stepComments, setstepComments] = useState<any>([]);
    const [submitDisableButton, setSubmitDisableButton] = useState<boolean>(false);
    const [editComment, setEditComment] = useState<any>(false);
    const [isHoverCommentCrud, setIsHoverCommentCrud] = useState(false);
    const [disableThreadComment, setDisableThreadComment] = useState<boolean>(false);
    const [editCommentPayload, setEditCommentPayload] = useState<any>(null);

    const handleMediaUploadDialog = () => {
        setShowMediaUploadDialog(true);
    };
    const closeMediaDialogHandler = () => {
        setShowMediaUploadDialog(false);
    };

    const handleSelectedFiles = (files) => {
        setSelectedFiles(files);
    };

    const { currentUser } = useContext(UserContext);
    const submitComment = () => {
        if (formik.values.commentText) {
            setMentorIds([]);
            setSubmitDisableButton(true);
            saveCurrentMediaRef(selectedFiles[0], true);
            formik.setFieldValue('commentMedia', []);
            setSelectedFiles([]);
        } else if (formikEdit.values.commentText) {
            setSubmitDisableButton(true);
            setMentorIds([]);
            saveCurrentMediaRef(selectedFiles[0], true);
            formik.setFieldValue('commentMedia', []);
            setSelectedFiles([]);
        }
    };

    const { t } = useTranslation();

    const listStepCommentThread = (threadId: string) => {
        TutorialService.listStepComments(threadId)
            .then((e) => {
                props.setCommentCountOnThread(e?.data?.data?.comments?.slice(0, -1).length);
                setstepComments(e?.data?.data?.comments?.slice(0, -1));
            })
            .catch(({ response }) => {
                Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response?.data?.messageCode, { ns: 'error' }) || response?.data?.errorMessage);
            });
    };

    useEffect(() => {
        if (props.showMoreCommentOnThread) {
            listStepCommentThread(props.threadId);
        }
    }, [props.showMoreCommentOnThread]);
    useEffect(() => {
        if (mediaFiles.length > 0) {
            saveCurrentMediaRef(mediaFiles[0], false);
        } else {
            if (formik.values.commentText) {
                let data = {
                    comment: formik.values.commentText,
                    files: mentorIds,
                };
                if (props?.threadId) {
                    TutorialService.addCommentToThread(props.threadId, data)
                        .then(() => {
                            props.showCommentonThreadReplyHandler();
                            Toast.success(t('BUTTON.OPERATION_SUCCESS'), t('COMMON.COMMENT_ADDED_SUCCESSFULLY'));
                            listStepCommentThread(props.threadId);
                            setSubmitDisableButton(false);
                            formik.setFieldValue('commentText', '');
                        })
                        .catch(({ response }) => {
                            setSubmitDisableButton(false);
                            Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
                        });
                }
            }
            if (formikEdit.values.commentText) {
                let data = {
                    comment: formikEdit.values.commentText,
                    files: mentorIds,
                    createdOn: editCommentPayload?.createdOn,
                    commentId: editCommentPayload?.commentId,
                };

                formikEdit.setFieldValue('commentText', '');
                if (props?.threadId) {
                    TrainerService.editStepComment(props?.threadId, data)
                        .then(() => {
                            setEditComment(false);
                            setDisableThreadComment(false);
                            disableThreadCommentEditHandler();
                            Toast.success(t('BUTTON.OPERATION_SUCCESS'), t('COMMON.COMMENT_MODIFIED_SUCCESSFULLY'));
                            listStepCommentThread(props?.threadId);
                            setSubmitDisableButton(false);
                        })
                        .catch(({ response }) => {
                            setSubmitDisableButton(false);
                            Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
                        });
                }
            }
        }
    }, [mediaFiles]);

    async function saveCurrentMediaRef(file, initial) {
        if (file) {
            if (!file?.fileId) {
                const metaData = { organizationId: currentUser?.organizationId, workInstructionId: props?.activeThreadData?.workInstructionId };
                await FileManagerService.fileUploadBlob(file, metaData).then((response: any) => {
                    const mentorID = response.data.id; // file id

                    setMentorIds((mentorids) => [...mentorids, { fileId: mentorID, fileName: file.name, mimeType: file?.type, isBlobUpload: true }]);
                });
                setSelectedFiles([]);
                if (file.url) {
                    let shortUrl = file.url.substring(0, 15);
                    shortUrl = shortUrl.concat('  .....');
                    Toast.success(t('COMMON.SAVING_LINK'), `${file?.name}`);
                } else {
                    Toast.success(t('COMMON.UPLOADING_FILE'), `${file?.name}`);
                }
            }

            if (file?.fileId) {
                setMentorIds((mentorids) => [...mentorids, { fileId: file.fileId, fileName: file.name, mimeType: file?.mimeType, isBlobUpload: true }]);
            }
        } else {
            setSelectedFiles([]);
        }
        if (initial) {
            setMediaFiles(selectedFiles.slice(1));
        } else {
            setMediaFiles(mediaFiles.slice(1));
        }
    }

    const formik = useFormik({
        initialValues: {
            commentText: '',
            commentMedia: [],
        },

        onSubmit: () => {},
    });

    const formikEdit = useFormik({
        initialValues: {
            commentText: '',
            commentMedia: [],
        },

        onSubmit: () => {},
    });
    const handleMouseEnter = (e) => {
        setIsHoverCommentCrud(true);
    };

    const handleMouseLeave = (e) => {
        setIsHoverCommentCrud(false);
    };

    const threadCommentClickHandler = () => {
        setDisableThreadComment(true);
    };

    const disableThreadCommentEditHandler = () => {
        setEditComment('');
        formikEdit.setFieldValue('commentMedia', []);
        setSelectedFiles([]);
        formikEdit.setFieldValue('commentText', '');
    };
    const handleEditComment = (comment, isEditing) => {
        setEditComment(comment.commentId);
        setEditCommentPayload(comment);
        formikEdit.setValues({
            ...formik.values,
            commentText: comment.comment,
        });
        setSelectedFiles(comment.files);
    };

    const wrapperRef: any = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                formik.handleSubmit();
            }
        };

        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                if (formik.values.commentText) {
                    formik.handleSubmit();
                }
                if (formikEdit.values.commentText) {
                    formikEdit.handleSubmit();
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [formik.values.commentText, formikEdit.values.commentText]);

    const theme = useTheme();
    return (
        <>
            {showMediaUploadDialog && (
                <MediaUploadDialog
                    closeDialog={closeMediaDialogHandler}
                    openDialog={showMediaUploadDialog}
                    editMode={false}
                    style={{ padding: '24px' }}
                    selectedFiles={selectedFiles}
                    setSelectedFiles={handleSelectedFiles}
                />
            )}
            <form onSubmit={formik.handleSubmit}>
                {props.replyCommentHandler && (
                    <>
                        {' '}
                        <FormControl sx={{ textAlign: 'center', pl: window.location.pathname !== '/notifications' ? '24px' : 0 }}>
                            <FormControlLabel
                                control={<></>}
                                label={
                                    <Typography
                                        paddingX={2}
                                        paddingY={1}
                                        sx={{ float: 'left', fontSize: '12px', opacity: '0.8' }}
                                    >
                                        {t('COMMON.REPLY')}
                                    </Typography>
                                }
                            />

                            <OutlinedInput
                                id="outlined-basic"
                                multiline
                                minRows={4}
                                required
                                placeholder={`${t('COMMON.WRITE_REPLY')}...`}
                                name="commentText"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                }}
                                value={formik.values.commentText}
                                sx={{ width: '368px', borderRadius: '8px' }}
                                endAdornment={
                                    <InputAdornment
                                        position="end"
                                        sx={{ marginTop: 'auto', marginBottom: '12px' }}
                                        onClick={handleMediaUploadDialog}
                                    >
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                        >
                                            <MediaUploadIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                inputProps={{
                                    maxLength: 500,
                                }}
                            />

                            <FormHelperText
                                className="helperText"
                                sx={{ fontWeight: '400', marginTop: '8px', fontSize: '12px' }}
                            >
                                {500 - formik.values.commentText.length} {t('COMMON.CHARACTERS_LEFT')}
                            </FormHelperText>
                        </FormControl>
                        <Box
                            component={'div'}
                            sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', pl: window.location.pathname !== '/notifications' ? '24px' : 0 }}
                        >
                            {selectedFiles?.map((file: any, index) => {
                                let fileToShow = convertFileBlobToMediaType(file);
                                return (
                                    <MediaRef
                                        key={index}
                                        aReference={fileToShow}
                                        index={index}
                                        commentMediaRef={true}
                                    />
                                );
                            })}
                        </Box>
                        <DialogActions style={{ display: 'flex', justifyContent: 'end', paddingRight: window.location.pathname !== '/notifications' ? '32px' : '0px' }}>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    props.showCommentonThreadReplyHandler();
                                }}
                            >
                                {t('BUTTON.CANCEL')}
                            </Button>
                            <Button
                                variant="contained"
                                disabled={submitDisableButton || formik.values.commentText.length === 0}
                                onClick={() => {
                                    submitComment();
                                }}
                            >
                                {submitDisableButton && (
                                    <CircularProgress
                                        sx={{ marginRight: '8px' }}
                                        size="12px"
                                    />
                                )}
                                {t('BUTTON.SUBMIT')}
                            </Button>
                        </DialogActions>
                    </>
                )}
                {props?.showMoreCommentOnThread &&
                    stepComments?.map((comment, index) => {
                        const isEditing = editComment === comment.commentId;

                        return (
                            <div
                                key={index}
                                onMouseEnter={(e) => {
                                    handleMouseEnter(e);
                                }}
                                onMouseLeave={(e) => {
                                    handleMouseLeave(e);
                                }}
                            >
                                {isEditing ? (
                                    <form onSubmit={formikEdit.handleSubmit}>
                                        <FormControl sx={{ textAlign: 'center', paddingLeft: window.location.pathname !== '/notifications' ? '24px' : null }}>
                                            <FormControlLabel
                                                control={<></>}
                                                label={
                                                    <Typography
                                                        paddingX={2}
                                                        paddingY={1}
                                                        sx={{ float: 'left', mt: '16px', fontSize: '12px', fontWeight: disableThreadComment ? '600' : '400', opacity: '0.8' }}
                                                    >
                                                        {t('COMMON.ADD_COMMENTS')}
                                                    </Typography>
                                                }
                                            />
                                            <OutlinedInput
                                                id="outlined-basic"
                                                multiline
                                                required
                                                onClick={() => {
                                                    threadCommentClickHandler();
                                                }}
                                                minRows={4}
                                                name="commentText"
                                                placeholder={`${t('COMMON.WRITE_COMMENT')}...`}
                                                onChange={formikEdit.handleChange}
                                                value={formikEdit.values.commentText}
                                                sx={{ width: '368px', borderRadius: '8px', borderWidth: disableThreadComment ? '1px' : '0.5px', borderColor: disableThreadComment ? theme.palette.primary.main : 'black' }}
                                                endAdornment={
                                                    <InputAdornment
                                                        position="end"
                                                        sx={{ marginTop: 'auto', marginBottom: '12px' }}
                                                        onClick={handleMediaUploadDialog}
                                                    >
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            edge="end"
                                                        >
                                                            <MediaUploadIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                inputProps={{
                                                    maxLength: 500,
                                                }}
                                            />

                                            <FormHelperText
                                                className="helperText"
                                                sx={{ fontWeight: '400', marginTop: '8px', fontSize: '12px' }}
                                            >
                                                {500 - formikEdit.values.commentText.length} {t('COMMON.CHARACTERS_LEFT')}
                                            </FormHelperText>
                                        </FormControl>
                                        <Box
                                            component={'div'}
                                            sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', paddingLeft: window.location.pathname !== '/notifications' ? '24px' : null }}
                                        >
                                            {selectedFiles?.map((file, index) => {
                                                return (
                                                    <CommentFilePreview
                                                        file={file}
                                                        selectedFiles={selectedFiles}
                                                        setSelectedFiles={setSelectedFiles}
                                                    />
                                                );
                                            })}
                                        </Box>

                                        <DialogActions style={{ display: 'flex', justifyContent: 'end', paddingRight: window.location.pathname !== '/notifications' ? '32px' : '0px' }}>
                                            <Button
                                                variant="outlined"
                                                onClick={() => {
                                                    disableThreadCommentEditHandler();
                                                }}
                                            >
                                                {t('BUTTON.CANCEL')}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                sx={{ m: '8px 0px 8px 0px' }}
                                                disabled={submitDisableButton || formikEdit.values.commentText.length === 0}
                                                onClick={() => {
                                                    submitComment();
                                                }}
                                            >
                                                {submitDisableButton && (
                                                    <CircularProgress
                                                        sx={{ marginRight: '8px' }}
                                                        size="12px"
                                                    />
                                                )}
                                                {t('BUTTON.SUBMIT')}
                                            </Button>
                                        </DialogActions>
                                    </form>
                                ) : (
                                    <>
                                        <List
                                            key={index}
                                            sx={{ marginLeft: '48px' }}
                                        >
                                            <Typography sx={{ display: 'flex', flexDirection: 'row', justify: 'space-between' }}>
                                                <Avatar
                                                    firstName={comment?.userFirstName}
                                                    lastName={comment?.userLastName}
                                                    isRead={comment?.isRead}
                                                    color={theme.palette.primary.main}
                                                    role="trainer"
                                                    sx={{ width: '24px', height: '24px', borderRadius: '18px', marginRight: '4px', marginLeft: '6px' }}
                                                />
                                                
                                                
                                                <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '0.5em', fontSize: '14px', color: '#12181F' }}>{`${comment?.userFirstName} ${comment?.userLastName}`} </Typography>
                                                <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '1em', fontWeight: '400', fontSize: '12px', color: '#12181F66' }}>{`${getFormattedTimeFromUnixTime(comment?.createdOn)}`}</Typography>
                                                <CommentCrud
                                                    comment={comment}
                                                    threadId={props.threadId}
                                                    listStepCommentThread={listStepCommentThread}
                                                    editComment={editComment}
                                                    setEditComment={() => handleEditComment(comment, isEditing)}
                                                />
                                            </Typography>
                                            <Typography sx={{ ml: '4px', marginTop: '16px', width: '376px', fontWeight: comment?.isRead ? '400' : '600', fontSize: '14px', color: '#12181F99' }}>{comment.comment}</Typography>
                                        </List>
                                        <Box
                                            component={'div'}
                                            sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', marginLeft: '48px' }}
                                        >
                                            {comment?.files?.map((file, index) => (
                                                <CommentFilePreview file={file} />
                                            ))}
                                        </Box>
                                    </>
                                )}
                            </div>
                        );
                    })}
            </form>
        </>
    );
};
