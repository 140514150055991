import React, { Suspense, useEffect, useState, useLayoutEffect, memo, useRef } from 'react';
import { useCanvasState } from '../../state/canvasState';
import { setAnimationIndex, setAnimationState, setAnnotationsVisibility, setCameraViewPoint, setExplosionState, setHiddenModels, setModelViewMode, setStepIndex } from '../../integrations/commonViewer/commonViewer';
import { Viewer3D, cdsSubscribe } from 'cds-component-library';
import { environment } from '../../environment/environment';
interface Props {
  explode: boolean;
  currentStep?: number;
  intro: boolean;
  bearerToken: string;
  cdsModelId: string;
}

const CommonViewerWrapper: React.FC<Props> = (props) => {
  const canvasState = useCanvasState();
  const currentReplayState = useCanvasState((state) => state.replay);
  const setReplayState = useCanvasState((state) => state.setReplay);
  const currentWorkInstructions = useCanvasState((state) => state.workInstructions);

  const [isExploded, setIsExploded] = useState<boolean>(false);
  const [animationNeedsToWaitForCamera, setAnimationNeedsToWaitForCamera] = useState<boolean>(true);
  const [explosionPlayedLast, setExplosionPlayedLast] = useState<boolean>(false);
  const [cameraControlUnsub, setCameraControlUnsub] = useState<(() => void)[] | null>(null);
  const [isModelLoaded, setIsModelLoaded] = useState<boolean>(false);
  const [isCameraControlComplete, setIsCameraControlComplete] = useState<boolean>(false);
  const [isAnimationControlComplete, setIsAnimationControlComplete] = useState<boolean>(false);
  const [modelLoadAnimation,setModelLoadAnimation] = useState<boolean>(false);

  //   Add required event listeners
  useLayoutEffect(() => {
    const subscriptions = [
      cdsSubscribe('ANIMATION_PLAY_COMPLETE', (animationComplete: boolean) => {
        setIsAnimationControlComplete(animationComplete);
        if (animationComplete) {
          useCanvasState.setState({ currentlyPlaying: false });
          setReplayState(false);
        }
      }),
      cdsSubscribe('MODEL_LOADED', (isLoaded: boolean) => setIsModelLoaded(isLoaded)),
      cdsSubscribe('CAMERA_CONTROL_COMPLETE', (isCameraControlComplete: boolean) => {
        setIsCameraControlComplete(isCameraControlComplete);
        setTimeout(() => {
          if (isCameraControlComplete) {
            if (animationNeedsToWaitForCamera) {
              checkIfCameraEventShouldPlayAnimation();
            }
            useCanvasState.setState({ rotate: null });
            useCanvasState.setState({ cameraRotating: false });
          }
        }, 0);
      }),
    ];

    return () => {
      subscriptions.forEach((unsub) => unsub());
    };
  }, []);

  // Monitoring camera control completion and triggering animation if required.
  useLayoutEffect(() => {
    const unsub = cdsSubscribe('CAMERA_CONTROL_COMPLETE', (isFinished: boolean) => {
      if (isFinished) {
        checkIfCameraEventShouldPlayAnimation();
        useCanvasState.setState({ rotate: null });
        useCanvasState.setState({ cameraRotating: false });
      }
    });

    setCameraControlUnsub((prev) => (prev ? [...prev, unsub] : [unsub]));
  }, [animationNeedsToWaitForCamera, props.currentStep]);

  useEffect(() => {
    if (cameraControlUnsub && cameraControlUnsub.length > 1) {
      setCameraControlUnsub((prev) => {
        const remainingUnsubs = prev?.slice(-1);
        prev?.slice(0, -1).forEach((unsub) => unsub());
        return remainingUnsubs || null;
      });
    }
  }, [cameraControlUnsub]);

  useEffect(() => {
    if (isModelLoaded && isCameraControlComplete && isAnimationControlComplete && !modelLoadAnimation) {
      playAnimatedStep(false, false);
      setModelLoadAnimation(true);
    }
  }, [isModelLoaded, isCameraControlComplete, isAnimationControlComplete]);

  const checkIfCameraEventShouldPlayAnimation: () => void = () => {
    if (animationNeedsToWaitForCamera) {
      setAnimationNeedsToWaitForCamera(false);
      setTimeout(() => playAnimation(false, false), 0); // Delay for animation to start after state is set
    }
  };

  const excludeHiddenModels = () => {
    if (currentWorkInstructions && props.currentStep !== undefined) {
      let cardInfo = currentWorkInstructions?.cardsData[props.currentStep - 1];
      setHiddenModels(cardInfo.hiddenParts);
    }
    else {
      setHiddenModels([]);
    }
  }

  // Set annotation visibility
  useEffect(() => {
    setAnnotationsVisibility(canvasState.showAnnotations);
  }, [canvasState.showAnnotations]);

  // Set visibility mode
  useEffect(() => {
    if (canvasState.changeMode === 'translucent' || canvasState.changeMode === 'outline' || canvasState.changeMode === null) {
      setModelViewMode(canvasState.changeMode as 'translucent' | 'outline' | null);
      if (explosionPlayedLast) {
        setExplosionState('stop');
      }
    } else if (canvasState.changeMode === 'explode') {
      setExplosionState('play');
      setExplosionPlayedLast(true);
    } else {
      setModelViewMode(null);
      if (explosionPlayedLast) {
        setExplosionState('stop');
      }
    }
  }, [canvasState.changeMode]);

  //   On step change, reset and play animation
  useEffect(() => {
    if (props.currentStep && currentWorkInstructions) {
      setStepIndex(props.currentStep - 1);
      if (isExploded && currentWorkInstructions.explosions !== null) {
        resetAnimation(true);
      }
      if (!props.intro && modelLoadAnimation) playAnimatedStep(false, false);
    }
  }, [props.currentStep, props.intro]);

  //   On step change, exclude hidden parts
  useEffect(() => {
    if(isModelLoaded) {
      excludeHiddenModels();
    }    
  }, [isModelLoaded, props.currentStep, props.intro, currentReplayState]);

  //   Replay the same step
  useEffect(() => {
    if (currentReplayState && !props.intro) {
      playAnimatedStep(true, canvasState.changeMode === 'explode');
    }
  }, [currentReplayState]);

  const playAnimatedStep = (isReplay: boolean, explosion: boolean) => {
    // if the hidden parts were not excluded before for any reason
    if(isModelLoaded) {
      excludeHiddenModels();
    }
    
    if (props.currentStep && currentWorkInstructions) {
      useCanvasState.setState({ cameraRotating: true });
      let cardInfo = currentWorkInstructions.cardsData[props.currentStep - 1];
      if (currentWorkInstructions) {
        if (currentWorkInstructions.cardsData[props.currentStep - 1].cameraPosition && !isReplay) {
          // resetAnimation(false);
          if (explosion) {
            setExplosionPlayedLast(true);
          } else {
            setExplosionPlayedLast(false);
          }
          intializeAnimation(explosion);
          setAnimationNeedsToWaitForCamera(true);
          setCameraViewPoint(cardInfo.cameraPosition, cardInfo.cameraTarget, cardInfo.cameraZoom);
        } else if (currentWorkInstructions.cardsData[props.currentStep - 1].cameraPosition && isReplay) {
          if (explosion) {
            setExplosionPlayedLast(true);
          } else {
            setExplosionPlayedLast(false);
          }
          // setAnimationNeedsToWaitForCamera(true);
          // resetAnimation(false);
          useCanvasState.setState({ rotate: null });
          intializeAnimation(explosion);

          playAnimation(true, false);
        }
      }
      // if (explosion) {
      //   useCanvasState.setState({ rotate: null });
      //   resetAnimation(false);
      //   intializeAnimation(explosion);
      //   // playAnimation(isReplay, explosion);
      // }
    }
  };

  const intializeAnimation = (explosion: boolean) => {
    if (props.currentStep && currentWorkInstructions) {
      let cardInfo = currentWorkInstructions.cardsData[props.currentStep - 1];
      let animationIndex = explosion ? -1 : cardInfo.animationIndex;
      setAnimationIndex(animationIndex);
      setAnimationState('init');
      //   Start Animation
      useCanvasState.setState({ animationStarting: true });
    } else {
      stopPlay();
    }
  };

  const resetAnimation = (explosion: boolean) => {
    if (currentWorkInstructions) {
      currentWorkInstructions.cardsData.map((cardInfo) => {
        if (cardInfo.animationIndex !== null) {
          let animationIndex = explosion ? -1 : cardInfo.animationIndex;
          setAnimationIndex(animationIndex);

          //   Reset the animation
          setAnimationState('stop');
        }
      });
    }
    useCanvasState.setState({ cameraRotating: false });
  };

  // const hideModels = () => {
  //   if(currentWorkInstructions) {
  //   }
  // }

  const stopPlay = () => {
    useCanvasState.setState({ currentlyPlaying: false });
  };

  useEffect(() => {
    if (currentWorkInstructions) {
      if (currentWorkInstructions.explosions !== null) {
        if (canvasState.changeMode === 'explode') {
          playAnimatedStep(true, true);
          setIsExploded(true);
        } else if (isExploded) {
          setIsExploded(false);
          playAnimatedStep(false, false);
          resetAnimation(false);
        }
      }
    }
  }, [canvasState.changeMode]);

  /**
   * @description This function plays the current animation. Replay - true, will ignore camera movement
   * explosion - true, will ignore the active animation index and play the explosion
   * @param {boolean} isReplay
   * @param {Moment} endDate
   */
  const playAnimation = (isReplay: boolean, explosion: boolean) => {
    // console.log('HERE isReplay', isReplay)
    setAnimationState('play');
  };

  const [wasIntro, setWasIntro] = useState<boolean>(true);

  useEffect(() => {
    if (props.intro && !wasIntro) {
      resetAnimation(false);
      setWasIntro(true);
      //   Set camera position
      //   setRequestedCameraAttributes({ position: defaultCameraPosition, target: defaultCameraTarget, zoom: defaultCameraZoom, sceneOriginBased: true });
    } else if (!props.intro && wasIntro) {
      setWasIntro(false);
    }
  }, [props.intro]);
  const urlParams = new URLSearchParams(window.location.search);
  const cdsApiKey: any = urlParams.get('cdsInternalApiKey') || environment.AppConfigs.cdsInternalApiKey;
  return (
    <Suspense fallback={null}>
      <Viewer3D bearerToken={props.bearerToken} cdsModelId={props.cdsModelId} environmentSource={process.env.PUBLIC_URL + '/hdrs/evening_road_01_1k.hdr'} apiKey={cdsApiKey} />
    </Suspense>
  );
};

export default memo(CommonViewerWrapper);

