import './App.scss';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { loginRequest } from './authConfig';
import CdsAppBar from './components/ControlBars/CdsAppBar/CdsAppBar';
import EditWorkInstruction from './components/Trainer/EditWorkInstruction/EditWorkInstruction';
import { CompanyTheme } from './entities/Trainee/MockTrainee';
import AddOrganization from './pages/Organization/AddOrganization';
import EditOrganization from './pages/Organization/EditOrganization';
import OrganizationList from './pages/Organization/OrganizationList';
import AddPersona from './pages/Persona/AddPersona';
import EditPersona from './pages/Persona/EditPersona';
import PersonaList from './pages/Persona/PersonaList';
import AddResource from './pages/Resource/AddResource';
import EditResource from './pages/Resource/EditResource';
import ResourceList from './pages/Resource/ResourceList';
import AssemblyInstructions from './pages/Trainee/AssemblyInstructions/AssemblyInstructions';
import ReferenceDocumentation from './pages/Trainee/ReferenceDocumentation/ReferenceDocumentation';
import TraineeHome from './pages/Trainee/TraineeHome/TraineeHome';
import CreateNewProcedure from './pages/Trainer/Procedures/CreateNewProcedure';
import CreateWorkInstructions from './pages/Trainer/Workinstructions/createWorkInstructions';
import AddUser from './pages/User/AddUser';
import SignIn from './pages/SignIn/SignIn';
import EditUser from './pages/User/EditUser';
import UserList from './pages/User/UserList';
import AddUserGroup from './pages/UserGroup/AddUserGroup';
import EditUserGroup from './pages/UserGroup/EditUserGroup';
import UserGroupList from './pages/UserGroup/UserGroupList';
import TutorialService from './services/trainee/TraineeService';
import { AccountInfo } from '@azure/msal-browser';
import UnAuthenticatedBar from './components/ControlBars/UnAuthenticatedBar/UnAuthenticatedBar';
import RbacService from './services/Rbac/RbacService';
import UserContext, { IRbacUser } from './context/UserContext';
import { WorkInstructionPermissions } from './shared/permissions';
import { hasPermissions } from './shared/utils/helpers';
import Loading from './components/Loading/Loading';
import { OrganizationPermissions } from './shared/permissions';
import { getAppTheme } from './shared/utils/theme-helpers';
import { IAppTheme } from './entities/Organization/Theme/Theme';
import RecycleBinProcedures from './pages/RecycleBin/Procedures/Procedures';
import Procedures from './pages/Trainee/Procedures/Procedures';
import ProcedureHistory from './pages/Trainer/Procedures/ProcedureHistory';
import ApprovalRequests from './pages/Trainee/ApprovalRequests/ApprovalRequests';
import Analytics from './pages/Analytics/Analytics';
import ProcedureAnalytics from './pages/Analytics/ProcedureAnalytics';
import PendingProceduresList from './components/Analytics/TraineeReports/PendingProceduresList';
import AssignedProceduresList from './components/Analytics/ProcedureReports/AssignedProceduresList';
import TraineesAssignedList from './components/Analytics/TraineeReports/TraineesAssigned/TraineesAssignedList';
import TraineeAssignedDetails from './components/Analytics/TraineeReports/TraineesAssigned/TraineeAssignedDetails';
import { environment } from './environment/environment';
import SessionActive from './components/SessionActive/SessionActive';
import PpeContext from './context/PpeContext';
import Amplitude from 'amplitude-js';
import memoize from 'lodash/memoize';
import { Alert} from '@mui/material';
import i18n from 'i18next';
import { useTranslation} from 'react-i18next';
import TrainerService from './services/trainer/TrainerService';
import { ManageSubscription } from './pages/ManageSubscription/ManageSubscription';
import { EndSubscription } from './pages/ManageSubscription/EndSubscription';
import TourContext from './context/TourContext';
import Joyride from 'react-joyride';
import DemoBar from './components/ControlBars/DemoBar/DemoBar';
import TourCompleteDialog from './components/Trainer/Dialog/TourCompleteDialog/TourCompleteDialog';
import { getSteps } from './shared/utils/tour-steps';
import { LogSeverity, USER_TYPES } from './shared/enums';
import { TraineeCompletionRate } from './components/Analytics/TraineeReports/TraineeCompletionRate';
import { SubscriptionEnd } from './components/SubscriptionEnd/SubscriptionEnd';
import TranslationContext, { ITranslation, defaultLabels } from './context/TranslationContext';
import RecycleBin from './pages/RecycleBin/RecycleBinHome';
import { IWorkInstructions } from './entities/Trainee/MockTrainee';
import RevisitRequests from './pages/Trainee/RevisitRequests/RevisitRequests';
import AllNotifications from './components/Notifications/AllNotifications';
import RevisitProcedures from './pages/Trainee/RevisitRequests/RevisitProcedures';
import RecertificationDueRequest from './pages/Trainee/RecertificationDueRequest/RecertificationDueRequest';
import IntegrationInstancesList from './pages/Trainer/Integrations/IntegrationInstancesList';
import IntegrationTypes from './pages/Trainer/Integrations/IntegrationTypes';
import CreateNewIntegrationInstance from './pages/Trainer/Integrations/CreateNewIntegrationInstance';
import { useAppDispatch, useAppSelector } from './hooks/redux';
import * as Action from './redux/features/app/app.slice';
import ProcedureList from './components/Analytics/ProcedureReports/ProcedureList/ProcedureList';
import Substitutions from './pages/Substitutions/Substitutions';
import ComponentLibrary from './pages/ComponentLibrary/ComponentLibrary';
import VisitsHistory from './components/VisitsHistory/VisitsHistory';
import JobManagement from './components/JobManagement';
import NewJob from './components/JobManagement/NewJob';
import ViewJob from './components/JobManagement/ViewJob';

const initialize = memoize(() => {
  const key = environment.AppConfigs.amplitudePublicKey;
  if (!key || key == '' || environment.AppConfigs.govServer) return;
  Amplitude.getInstance().init(key, undefined, {
    includeReferrer: true,
    disableCookies: true,
  });
});

export const trackEvent = (name: string, data?: any, severity?: LogSeverity, email?: string) => {
  const key = environment.AppConfigs.amplitudePublicKey;
  if (environment.AppConfigs.env === 'prod' && key && key != '' && !environment.AppConfigs.govServer) {
    setUserForTracking(email);
    Amplitude.getInstance().logEvent(name, data);
  }
  if (!severity) {
    severity = LogSeverity.INFO;
  }
  // TrainerService.sendLogs({
  //   type: severity,
  //   message: name + '-' + JSON.stringify(data) + '-',
  // });
};

export const setUserForTracking = (email?: string) => {
  initialize();
  Amplitude.getInstance().setUserId(localStorage.getItem('currentUserId'));
  Amplitude.getInstance().setUserProperties({ user_email: email ? email : localStorage.getItem('email') });
  Amplitude.getInstance().setUserProperties({ Environment: environment.AppConfigs.env });
};

export const useDocumentTitle = (title) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
};

interface Props {
  workInstruction: IWorkInstructions;
  isTrainer?: boolean;
  isDelete?: boolean;
}

const App = () => {
  //document.title = 'CDS Mentor';
  const userData: IRbacUser = {
    id: '',
    name: '',
    organizationId: '',
    organizationName: '',
    userId: '',
    scopes: {},
  };

  const translationData: ITranslation = JSON.parse(JSON.stringify(defaultLabels));

  const [loading, setLoading] = useState(true);
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [theme, setTheme] = useState<Theme>();
  // const [companyTheme, setCompanyTheme] = useState<CompanyTheme>();
  const [company, setCompany] = useState<string | null>('');
  // const [persona, setPersona] = useState<string | null>('');
  const [accessToken, setAccessToken] = useState(null);
  const [mentorRole, setMentorRole] = useState('');
  // const [currentUser, setCurrentUser] = useState<IRbacUser>(userData);
  const [currentTranslation, setCurrentTranslation] = useState(translationData);
  const [refreshProcedure, setRefreshProcedure] = useState<boolean>(false);
  const [sharedPpeList, setSharedPpeList] = useState([]);
  const [showSystemSettings, setShowSystemSettings] = useState(false);
  const [systemSettingsTab, setSystemSettingsTab] = useState(1);
  const [showIntro, setShowIntro] = useState(false);
  const [introStep, setIntroStep] = useState(1);
  const [tourMaxStep, setTourMaxStep] = useState(3);
  const [preferedLanguage, setPreferedLanguage] = useState({ id: '', value: '' });
  const [isValidPartnerToken, setIsValidPartnerToken] = useState(true);
  const [subscriptionActive, setSubscriptionActive] = useState(true);
  const [subscriptionDetails, setSubscriptionDetails] = useState<any>();
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [tourStep, setTourStep] = useState(0);
  const [tourSteps, setTourSteps] = useState<any>([]);
  const [tourTitle, setTourTitle] = useState('');
  const [tourFinish, setTourFinish] = useState(false);
  const [createWiTour, setCreateWiTour] = useState(false);
  const [subscriptionEndDays, setSubscriptionEndDays] = useState<number>();
  const [workInstructionAppBar,setWorkInstructionAppBar] = useState<boolean>(false);
  const [tourGuide, setTourGuide] = useState<any>({
    CREATE_WORK_INSTRUCTION: false,
    CREATE_PROCEDURE: false,
    ADD_STEPS_IN_STUDIO: false,
    PUBLISH: false,
    ASSIGN: false,
  });
  const [isDeletedWorkInstructionBackButton, setIsDeletedWorkInstructionBackButton] = useState(true);
  const isDelete = true;
  const [isRevisitRequest, setRevisitRequest] = useState<boolean>(true);
  const [timeOfLastTokenRefresh, setTimeOfLastTokenRefresh] = useState<Date>(new Date());

  const dispatch = useAppDispatch();
  const {currentUser, persona, companyTheme} = useAppSelector((state) => state.app);
  const setCurrentUser = (updatedUser) => {
    dispatch(Action.setCurrentUser(updatedUser));
  };
  const setPersona = (updatedPersona: USER_TYPES) => {
    dispatch(Action.setPersona(updatedPersona));
  }

  const setCompanyTheme = (theme: CompanyTheme) => {
    dispatch(Action.setCompanyTheme(theme));
  }

  const name = currentUser.userFullName;
  const role: any = accounts[0] && (accounts[0] as any).idTokenClaims.extension_mentor_role;
  const location = useLocation();
  let navigate = useNavigate();
  const { workInstructionId = '', procedureId = '', companyId = '' } = useParams();

  const partnerPath = new RegExp('/partner');
  const assemblyPath = new RegExp('/assembly');
  const partnerAccess = partnerPath.test(location.pathname);
  const { search: searchQuery } = useLocation();
  const query = new URLSearchParams(searchQuery);
  const partnerAccessToken = query.get('access');

  const addWiPath = new RegExp('/addWorkInstruction');

  useEffect(() => {
    if (!addWiPath.test(location.pathname)) {
      setIsTourOpen(false);
    }
  }, [location.pathname]);

    //Check every minute if the token has expired
    useEffect(() => {
      const interval = setInterval(() => {
        let testTime = new Date();
        //@ts-ignore
        if (testTime - timeOfLastTokenRefresh > 590000) {
          console.log('10 Minutes Past - Checking Auth Token');
          setTimeOfLastTokenRefresh(new Date());
          clearInterval(interval);
          RequestAccessToken();
        }
      }, 60000);
    }, [timeOfLastTokenRefresh]);

  const RequestAccessToken = async () => {
    await instance.initialize();
    const opco = localStorage.getItem('opco');
    const idp = localStorage.getItem('idp');
    const email = localStorage.getItem('email');
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    if (opco) {
      request['extraQueryParameters'] = { opco: opco, login_hint: email };
    }

    if (idp === environment.AppConfigs.doverADIDP) {
      request['extraQueryParameters'] = { ...request['extraQueryParameters'], domain_hint: 'dover.onmicrosoft.com' };
    }

    instance
      .acquireTokenSilent(request)
      .then((response: any) => {
        if (response) {
          setAccessToken(response.accessToken);
          localStorage.setItem('token', response.accessToken);
          if (response.account && !localStorage.getItem('userStats')) {
            localStorage.setItem('userStats', JSON.stringify({ userId: response.account.idTokenClaims.UsernameUPN }));
          }
          getUserInfo(response.account.idTokenClaims.UsernameUPN);
        }
      })
      .catch((e) => {
        getAccessTokenDetails();
      });
  };

  const getUserInfoDetails = (redirect?: boolean) => {
    // TODO: Hack for User Roles (Persona)
    if (currentUser.id) {
      const canCreateWorkInstruction = hasPermissions(currentUser, WorkInstructionPermissions.CREATE, true);
      const canCreateOrganization = hasPermissions(currentUser, OrganizationPermissions.CREATE, true);

      let role = canCreateWorkInstruction ? USER_TYPES.TRAINER : USER_TYPES.TRAINEE;
      if (canCreateOrganization) {
        role = USER_TYPES.ADMIN;
      }
      const companyName = currentUser.organizationName?.toLowerCase() || '';
      setCompany(companyName);
      setPersona(role);
      if (window.location.pathname === '/' || window.location.pathname.indexOf(`/trainer/home/`) > -1 || redirect) {
        let landingPageUrl = '';
        switch (role) {
          case 'trainee':
            landingPageUrl = `/home/${companyName}`;
            break;
          case 'trainer':
            landingPageUrl = `/trainer/home/${companyName}`;
            break;
          case 'admin':
            landingPageUrl = '/users';
            break;
          default:
            landingPageUrl = `/trainer/home/${companyName}`;
            break;
        }
        // Get the previous page's pathname
        const previousPage = localStorage.getItem('lastPath');
        if (previousPage) {
          navigate(previousPage);
          localStorage.removeItem('lastPath');
        } else {
          navigate(landingPageUrl);
        }
        localStorage.setItem('company', companyName);
      }
    }
  };

  useEffect(() => {
    getUserInfoDetails();

    return () => {};
  }, [currentUser]);

  const resetAccessToken = () => {
    setAccessToken(null);
    localStorage.clear();
    instance.logoutRedirect();
    RequestAccessToken();
  };

  useEffect(() => {
    if (mentorRole) {
      let [currentComapny, currentRole] = mentorRole.split(',');
      setCompany(currentComapny.toLowerCase());
      setPersona(currentRole.toLowerCase() as USER_TYPES);
      if (window.location.pathname === '/' || window.location.pathname.indexOf(`/trainer/home/`) > -1) {
        currentRole.toLowerCase() === 'trainee' ? navigate(`/home/${currentComapny}`) : navigate(`/trainer/home/${currentComapny}`);
        localStorage.setItem('company', currentComapny);
      }
    }
  }, [mentorRole]);

  const getAccessToken = async (): Promise<any> => {
    try {
      let tokenResponse = await instance.handleRedirectPromise();
      const opco = localStorage.getItem('opco');
      const idp = localStorage.getItem('idp');
      const email = localStorage.getItem('email');
      const request = {
        ...loginRequest,
        account: accounts[0],
      };

      if (opco && email) {
        request['extraQueryParameters'] = { opco: opco, login_hint: email };
      }

      if (idp === environment.AppConfigs.doverADIDP) {
        request['extraQueryParameters'] = { ...request['extraQueryParameters'], domain_hint: 'dover.onmicrosoft.com' };
      }
      let accountObj: AccountInfo | null;
      if (tokenResponse) {
        accountObj = tokenResponse.account;
      } else {
        accountObj = instance.getAllAccounts()[0];
      }
      if (accountObj && tokenResponse) {
        console.log('Got valid accountObj and tokenResponse');
      } else if (accountObj) {
        console.log('User has logged in, but no tokens.');
        try {
          if (partnerAccessToken) {
            // Do not redirect to login B2C page
          } else {
            tokenResponse = await instance.acquireTokenSilent({
              account: instance.getAllAccounts()[0],
              ...loginRequest,
            });
          }
        } catch (err) {
          await instance.acquireTokenRedirect({ ...request });
        }
      } else {
        console.log('No accountObject or tokenResponse present. User must now login.');
        if (isAuthenticated && !partnerAccessToken) {
          await instance.loginRedirect({ ...request });
        }
      }
      return Promise.resolve(tokenResponse);
    } catch (error) {
      console.error('Failed to handleRedirectPromise()', error);
      return Promise.resolve(null);
    }
  };

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;
    if (action === 'next' && type === 'step:after') {
      setTourStep(tourStep + 1);
    }
    if (action === 'next' && type === 'tour:end') {
      setTourFinish(true);
    }
    if (action === 'prev' && type === 'step:after') {
      setTourStep(tourStep - 1);
    }
    if (action === 'skip') {
      tourClose();
    }
  };

  const getUserInfo = (username) => {
    if (!currentUser.id)
      RbacService.getUserByUsername(username)
        .then((e) => {
          const {
            data: { data: currentUserData },
          } = e;
          const userData: IRbacUser = {
            ...currentUserData,
            id: currentUserData.personaId,
            userId: currentUserData.id,
            scopes: currentUserData.appScopes,
            userScopes: currentUserData.scopes,
            userFirstName: currentUserData.firstName,
            userFullName: currentUserData.firstName + " " + currentUserData.lastName,
          };
          setCurrentUser(userData);
          localStorage.setItem('organizationId', userData.organizationId);
          localStorage.setItem('personaId', userData.id);
          localStorage.setItem('currentUserId', userData.userId);
        })
        .catch((e) => {
          setLoading(false);
        });
  };

  const getAccessTokenDetails = () => {
    getAccessToken().then((response) => {
      if (response) {
        setAccessToken(response.accessToken);
        localStorage.setItem('token', response.accessToken);
        if (response.account && !localStorage.getItem('userStats')) {
          localStorage.setItem('userStats', JSON.stringify({ userId: response.account.idTokenClaims.UsernameUPN }));
        }
        getUserInfo(response.account.idTokenClaims.UsernameUPN);
      }
    });
  };
  const { t } = useTranslation();

  useEffect(() => {
    RequestAccessToken();
    setTourSteps(getSteps(i18n));
  }, []);

  useEffect(() => {
    if (company || partnerAccessToken) {
      TutorialService.getTheme(company, partnerAccessToken)
        .then((e) => {
          setCompanyTheme(e.data.data);
          setTourGuide(e.data.data?.userGuidePrompt);
          setCreateWiTour(e.data.data?.userGuidePrompt?.CREATE_WORK_INSTRUCTION === true);
          setCurrentTranslation(e.data.data.labels);
          setPreferedLanguage(e.data.data.language);
          i18n.changeLanguage(e.data.data.language.id);
        })
        .catch(({ response }) => {
          if (response.status === 401) {
            setIsValidPartnerToken(false);
          }
          setLoading(false);
        });
    }
  }, [company]);

  useEffect(() => {
    if (companyTheme) {
      const themeColor = companyTheme ? companyTheme.mainColor : '#0061a1';
      const muiTheme = getAppTheme(themeColor);

      setTheme(createTheme(muiTheme));
      setLoading(false);
    }
  }, [companyTheme]);

  const handleThemeChange = (appTheme: IAppTheme) => {
    if (appTheme) {
      // setTheme(appTheme.muiTheme);
      setCompanyTheme(appTheme.orgTheme);
    }
  };

  const [tabOpen, setTabOpen] = useState(true);

  useEffect(() => {
    console.log(isAuthenticated);
    if (environment.AppConfigs.appid === 'Mentor') {
      handleBeforeUnloadEvent();
      checkAndSetActiveSession();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (persona) getSubscription();
  }, [isAuthenticated, persona]);

  const getSubscription = () => {
    // TrainerService.getSubscriptionDetails().then((e) => {
    //   setSubscriptionDetails(e.data.data);
    //   if (e.data?.data?.status === 'EXPIRED') {
    //     setSubscriptionActive(false);
    //   } else {
    //     setSubscriptionActive(true);
    //     if (e.data.data?.expiration) {
    //       const timestamp = e.data.data?.expiration;
    //       const now = Math.floor(Date.now() / 1000);
    //       const difference = timestamp - now;
    //       setSubscriptionEndDays(Math.floor(difference / (60 * 60 * 24)));
    //     }
    //   }
    // });
  };

  // Handles the beforeunload event
  const handleBeforeUnloadEvent = () => {
    window.addEventListener('beforeunload', handleBeforeUnload);
  };

  // Handles the logic for checking and setting the active session ID
  const checkAndSetActiveSession = () => {
    const storedSessionId = sessionStorage.getItem('sessionId');
    if (storedSessionId && isAuthenticated) {
      checkAndSetActiveId(storedSessionId);
    } else {
      const newSessionId = generateSessionId();
      sessionStorage.setItem('sessionId', newSessionId);
      if (isAuthenticated) checkAndSetActiveId(newSessionId);
    }
  };

  // Checks if the session ID matches the active ID in localStorage
  // and sets tabOpen accordingly
  const checkAndSetActiveId = (sessionId) => {
    const activeId = localStorage.getItem('activeId');
    if (!activeId) {
      localStorage.setItem('activeId', sessionId);
      setTabOpen(true);
    } else {
      setTabOpen(activeId === sessionId);
    }
  };

  // Generates a new session ID
  const generateSessionId = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  };

  // function to remove active Id if active tab is closed
  const handleBeforeUnload = () => {
    const storedSessionId = sessionStorage.getItem('sessionId');
    const activeId = localStorage.getItem('activeId');
    if (storedSessionId === activeId) {
      localStorage.removeItem('activeId');
    }
  };

  if (partnerAccess && partnerAccessToken && !isValidPartnerToken) {
    return (
      <Alert severity="error" sx={{ textAlign: 'center', justifyContent: 'center' }}>
        {t('PROCEDURE.INVALID_TOKEN')}
      </Alert>
    );
  }

  if (!tabOpen && isAuthenticated && !partnerAccessToken) {
    return <SessionActive />;
  }

  const NotFound = (props) => {
    const location = useLocation();
    useEffect(() => {
      if (props.authRoute && !props.subscriptionActive) {
        if (props.persona === 'trainee') {
          navigate('subscription');
        } else {
          navigate('manageSubscription');
        }
      } else if (props.authRoute) {
        localStorage.removeItem('lastPath');
        getUserInfoDetails(true);
      } else {
        if (inProgress === 'none') localStorage.setItem('lastPath', location.pathname + location.search);
        navigate('/');
      }
    }, [location.pathname]);
    return <></>;
  };

  const customStyles = {
    options: {
      primaryColor: theme?.palette.primary.main,
      disableBeacon: true,
    },
  };

  const tourClose = () => {
    setIsTourOpen(false);
    setTourFinish(false);
    setTourTitle('');
    const tourData = {
      userGuidePrompt: {
        ...tourGuide,
        CREATE_WORK_INSTRUCTION: false,
      },
    };
    TrainerService.editUserTour(tourData).then((r) => {
      //console.log("edited")
    });
    setCreateWiTour(false);
    navigate(`/trainer/home/${localStorage.getItem('company')}`);
  };
  let currStep = 1;
  if (tourStep < 5) {
    currStep = 1;
  } else if (tourStep < 11) {
    currStep = 2;
  } else {
    currStep = 3;
  }

  return (
    <div className="App">
      {!isAuthenticated && (!!(partnerAccessToken && theme) || (!partnerAccessToken && !theme)) && (
        <ThemeProvider theme={theme || createTheme(getAppTheme('#0061a1'))}>
          <TourContext.Provider value={{ isTourOpen, tourStep, setTourStep, setIsTourOpen, tourTitle, setTourTitle, tourSteps, setTourSteps, createWiTour, setCreateWiTour, tourGuide, setTourGuide, isDeletedWorkInstructionBackButton, setIsDeletedWorkInstructionBackButton }}>
            <UserContext.Provider value={{ currentUser, setCurrentUser, refreshProcedure, setRefreshProcedure }}>
              <PpeContext.Provider value={{ sharedPpeList, setSharedPpeList, showSystemSettings, setShowSystemSettings, systemSettingsTab, setSystemSettingsTab, showIntro, setShowIntro, introStep, setIntroStep }}>
                <TranslationContext.Provider value={{ currentTranslation, setCurrentTranslation, refreshProcedure, setRefreshProcedure }}>
                  <UnauthenticatedTemplate>
                    <UnAuthenticatedBar />
                    <Routes>
                      <Route path="/" element={<SignIn />} />
                      {/* <Route path="/signup" element={<SignUp />} /> */}
                      <Route path="/partner" element={<AssemblyInstructions isTrainer={true} bearerToken={accessToken} />} />
                      <Route path="*" element={<NotFound authRoute={false} />} />
                    </Routes>
                  </UnauthenticatedTemplate>
                </TranslationContext.Provider>
              </PpeContext.Provider>
            </UserContext.Provider>
          </TourContext.Provider>
        </ThemeProvider>
      )}

      {loading && isAuthenticated && <Loading />}

      {!loading && isAuthenticated && theme && (
        <ThemeProvider theme={theme}>
          <AuthenticatedTemplate>
            <TourContext.Provider value={{ isTourOpen, tourStep, setTourStep, setIsTourOpen, tourTitle, setTourTitle, tourSteps, setTourSteps, createWiTour, setCreateWiTour, tourGuide, setTourGuide, isDeletedWorkInstructionBackButton, setIsDeletedWorkInstructionBackButton }}>
              <UserContext.Provider value={{ currentUser, setCurrentUser, refreshProcedure, setRefreshProcedure }}>
                <PpeContext.Provider value={{ sharedPpeList, setSharedPpeList, showSystemSettings, setShowSystemSettings, systemSettingsTab, setSystemSettingsTab, showIntro, setShowIntro, introStep, setIntroStep }}>
                  <TranslationContext.Provider value={{ currentTranslation, setCurrentTranslation, refreshProcedure, setRefreshProcedure }}>
                    {accessToken && !isTourOpen && subscriptionEndDays !== undefined && subscriptionEndDays < 7 && <SubscriptionEnd subscriptionEndDays={subscriptionEndDays} isTrainer={persona === 'trainer'} />}
                    {accessToken && location.pathname !== '/' && !isTourOpen && !assemblyPath.test(location.pathname) && !partnerPath.test(location.pathname) && <CdsAppBar subscriptionActive={subscriptionDetails?.status !== 'EXPIRED'} isTrainer={persona === 'trainer'} theme={companyTheme} userLoggedIn={name} userRole={persona} resetTokenCallback={resetAccessToken} onThemeChange={(appTheme: IAppTheme) => handleThemeChange(appTheme)} workInstructionAppBar={workInstructionAppBar} setWorkInstructionAppBar={setWorkInstructionAppBar} />}

                    {isTourOpen && <DemoBar title={tourTitle} step={currStep} maxStep={tourMaxStep} />}

                    {tourFinish && <TourCompleteDialog title={`${t('CREATE_WI.YOU_ARE_AMAZING', { ns: 'demoTour' })} ${name}! 🎉`} message={t('CREATE_WI.YOU_HAVE_FINISHED_DEMO', { ns: 'demoTour' })} image="image" onClose={tourClose} />}

                    <Joyride
                      steps={tourSteps}
                      run={isTourOpen}
                      continuous
                      showSkipButton={true}
                      disableCloseOnEsc={true}
                      hideCloseButton={true}
                      disableOverlayClose={true}
                      stepIndex={tourStep}
                      styles={customStyles}
                      callback={handleJoyrideCallback}
                      locale={{
                        last: t('BUTTON.FINISH'),
                        skip: t('BUTTON.SKIP'),
                        back: t('BUTTON.BACK'),
                        next: t('BUTTON.NEXT'),
                      }}
                    />
                    {partnerAccessToken && (
                      <Routes>
                        <Route path="/partner" element={<AssemblyInstructions bearerToken={accessToken} isTrainer={true} />} />
                      </Routes>
                    )}
                    {accessToken && !partnerAccessToken && (
                      <Routes>
                        {subscriptionActive && (
                          <>
                          {process.env.NODE_ENV == 'development' && (
                            <Route path='/components' element={<ComponentLibrary />}/>
                          )}
                          {persona === 'trainer' && (
                            <Route path='/substitutions' element={<Substitutions />}/>
                          )}
                            {persona === 'trainer' && !environment.AppConfigs.govServer && (
                              <>
                                <Route path="/integrations" element={<IntegrationTypes />} />
                                <Route path="/integration/:id" element={<IntegrationInstancesList />} />
                                <Route path="/integration/connectors/add/:id" element={<CreateNewIntegrationInstance />} />
                                <Route path="/integration/connectors/edit/:id/:integrationId" element={<CreateNewIntegrationInstance />} />
                              </>
                            )}

                            <Route path="/persona" element={<PersonaList />} />
                            <Route path="/persona/add" element={<AddPersona />} />
                            <Route path="/persona/edit/:personaId" element={<EditPersona />} />
                            <Route path="/organizations" element={<OrganizationList />} />
                            <Route path="/organizations/add" element={<AddOrganization />} />
                            <Route path="/organizations/edit/:organizationId" element={<EditOrganization />} />
                            <Route path="/resource" element={<ResourceList />} />
                            <Route path="/resource/add" element={<AddResource />} />
                            <Route path="/resource/edit/:resourceId" element={<EditResource />} />
                            <Route path="/recertificationDueRequest/:companyId" element={<RecertificationDueRequest />} />
                            <Route path="/home/:companyId" element={<TraineeHome />} />
                            <Route path="/procedures/:companyId/:id" element={<Procedures isTrainer={persona === 'trainer'} />} />
                            <Route path="/assembly/:companyId/:workInstructionId/:id" element={<AssemblyInstructions bearerToken={accessToken} isTrainer={persona === 'trainer'} />} />
                            <Route path="/procedures/:companyId/references/:workInstructionId" element={<ReferenceDocumentation />} />
                            {/*<Route path="/" element={<CompanyHome />} />*/}
                            <Route path="/trainer/home/:companyId" element={<TraineeHome isTrainer={persona !== 'trainee'} workInstructionAppBar={workInstructionAppBar} setWorkInstructionAppBar={setWorkInstructionAppBar}/>} />
                            <Route path="/trainer/addWorkInstruction" element={<CreateWorkInstructions />} />
                            <Route path="/trainer/procedures/:companyId/:id" element={<Procedures isTrainer={persona === 'trainer'} />} />
                            <Route path="/trainer/addProcedure/:workInstructionId" element={<CreateNewProcedure />} />
                            <Route path="/trainer/editWorkInstruction/:workInstructionId" element={<EditWorkInstruction />} />
                            <Route path="/trainer/procedureHistory/:companyId/:workInstructionId/:id" element={<ProcedureHistory />} />
                            <Route path="/trainer/ApprovalRequests/" element={<ApprovalRequests isTrainer={persona === 'trainer'} />} />
                            <Route path="/trainer/RevisitRequests/" element={<RevisitRequests isTrainer={persona === 'trainer'} />} />
                            <Route path="/notifications/" element={<AllNotifications isTrainer={persona === 'trainer'} />} />
                            <Route path="/trainer/analytics/:workInstructionId/:procedureId" element={<ProcedureAnalytics />} />
                            <Route path="/analytics" element={<Analytics />} />
                            <Route path="/visitsHistory" element={<VisitsHistory />} />
                            <Route path="/analytics/procedures" element={<ProcedureList />} />
                            <Route path="/analytics/procedures/pending" element={<PendingProceduresList />} />
                            <Route path="/analytics/procedures/assigned" element={<AssignedProceduresList />} />
                            <Route path="/analytics/procedures/assigned/details/:workInstructionId/:procedureId" element={<ProcedureAnalytics />} />
                            <Route path="/analytics/trainees/assigned" element={<TraineesAssignedList />} />
                            <Route path="/analytics/trainees/assigned/details/:userId" element={<TraineeAssignedDetails />} />
                            <Route path="/analytics/trainees/completionRate/details" element={<TraineeCompletionRate />} />
                            <Route path="/trainer/recycleBin/:companyId" element={<RecycleBin isTrainer={persona !== 'trainee'} isDelete={isDelete} />} />
                            <Route path="/trainer/RecycleBinprocedures/:companyId/:id" element={<RecycleBinProcedures isTrainer={persona === 'trainer'} isDelete={isDelete} />} />
                            <Route path="/trainer/RevisitProcedures/:id" element={<RevisitProcedures isTrainer={persona === 'trainer'} isRevisitRequest={isRevisitRequest} />} />
                          </>
                        )}
                        <Route path="/users" element={<UserList getSubscription={getSubscription} />} />
                        <Route path="/users/add" element={<AddUser />} />
                        <Route path="/users/edit/:userId" element={<EditUser />} />
                        <Route path="/user-group" element={<UserGroupList />} />
                        <Route path="/user-group/add" element={<AddUserGroup />} />
                        <Route path="/user-group/edit/:groupId" element={<EditUserGroup />} />
                        
                        {/* <Route path="/partner" element={<AssemblyInstructions isTrainer={true}/>} /> */}
                        {!subscriptionActive && persona === 'trainer' && <Route path="/manageSubscription" element={<ManageSubscription subscriptionDetails={subscriptionDetails} />} />}
                        {!subscriptionActive && persona === 'trainee' && <Route path="/subscription" element={<EndSubscription subscriptionDetails={subscriptionDetails} />} />}
                        <Route path="*" element={<NotFound authRoute={true} persona={persona} subscriptionActive={subscriptionActive} />} />
                        {persona === 'trainer' &&<Route path="/job" element={<JobManagement />} />}
                        {persona === 'trainer' &&<Route path='/new-job' element={<NewJob/>}/>}
                        {persona === 'trainer' && <Route path="/view-job/:jobId" element={<ViewJob/>} />}
                      </Routes>
                    )}
                  </TranslationContext.Provider>
                </PpeContext.Provider>
              </UserContext.Provider>
            </TourContext.Provider>
          </AuthenticatedTemplate>
        </ThemeProvider>
      )}
    </div>
  );
};

export default App;
