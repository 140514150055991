import React, { useEffect, useState } from 'react';
import { Box, Stack, Grid, Typography, IconButton, Skeleton } from '@mui/material';
import { PieChart, Pie, Label, Cell, ResponsiveContainer, Tooltip, LabelProps } from 'recharts';
import CircleIcon from '@mui/icons-material/Circle';
import { JobChartData, PieChartProps } from './interface';
import { useTranslation } from 'react-i18next';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DateRangeSelector from '../DateRangeSelector/DateRangeSelector';
import { SELECT_DATARANGE_OPTIONS } from '../../shared/enums';
import JobMAnagementService from '../../services/JobManagement/JobManagementService';
import { Toast } from '../Notifications/Notification';
const JobOverView: React.FC<{ dateRange; setDateRange,updateList }> = ({ dateRange, setDateRange,updateList }) => {
    const { t } = useTranslation();
    const [jobChartData, setJobChardData] = useState<JobChartData>({ completionTimeStatistics: { late: 0, onTime: 0, total: 0 }, jobOrderStatusStatistics: { completed: 0, failed: 0, inProgress: 0, notStarted: 0, total: 0 }, successRateStatistics: { failedJobs: 0, fpy: 0, fpyPercentChange: 0 } });
    const [loading, setLoading] = useState<boolean>(false);
    const [sort, setSort] = useState<SELECT_DATARANGE_OPTIONS>(SELECT_DATARANGE_OPTIONS.TODAY);

    useEffect(() => {
        if (dateRange.startDate.length < 1 || dateRange.endDate.length < 1) return;
        setLoading(true);
        JobMAnagementService.getJobVisitData({ start: dateRange.startDate, end: dateRange.endDate })
            .then((res) => {
                setJobChardData(res.data.data);
            })
            .catch(({ response }) => {
                console.error(response);
                // Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response?.data?.messageCode, { ns: 'error' }) || response?.data?.errorMessage);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [dateRange,updateList]);
    return (
        <Stack
            gap={'10px'}
            sx={{ padding: '16px', bgcolor: '#FFFFFF' }}
        >
            <Box
                component="div"
                sx={{ width: '320px', minWidth: 'fit-content' }}
            >
                <DateRangeSelector
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    selectedDateRangeOption={sort}
                    setSelectedDateRangeOption={setSort}
                    showAllTimeOption={false}
                    showATodayOption={true}
                    withBorder={true}
                    sx={{ marginRight: '8px', width: '100%', textAlign: 'start', bgcolor: '#FAFBFF' }}
                />
            </Box>

            <Grid
                container
                columnGap={'10px'}
                flexWrap={'nowrap'}
                sx={{ alignItems: 'stretch' }}
            >
                {loading ? (
                    <LoadingState />
                ) : (
                    <>
                        <Grid
                            item
                            md={12}
                            lg={3.2}
                        >
                            <Box
                                component={'div'}
                                sx={{ height: '100%', padding: '16px 10px', bgcolor: '#FAFAFA', borderRadius: '8px', textAlign: 'left', boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.1)' }}
                            >
                                <PieChartGraph
                                    title={t('JOB_MANAGEMENT.TOTAL_JOBS')}
                                    data={[
                                        { label: t('ANALYTICS_SECTION.COMPLETED'), color: '#008F31', value: jobChartData?.jobOrderStatusStatistics?.completed ?? 0 },
                                        { label: t('ANALYTICS_SECTION.IN_PROGRESS'), color: '#F47D59', value: jobChartData?.jobOrderStatusStatistics.inProgress ?? 0 },
                                        { label: t('COMMON.WORKINSTRUCTION_TRAINEE_STATUS_NOT_STARTED'), color: '#CFE4F2', value: jobChartData?.jobOrderStatusStatistics.notStarted ?? 0 },
                                        { label: t('JOB_MANAGEMENT.FAILED'), color: '#E4002B', value: jobChartData?.jobOrderStatusStatistics.failed ?? 0 },
                                    ]}
                                />
                            </Box>
                        </Grid>
                        {/* <Grid
                            item
                            md={12}
                            lg={2.5}
                        >
                            <Box
                                component={'div'}
                                sx={{ height: '100%', padding: '16px 10px', bgcolor: '#FAFAFA', borderRadius: '8px', textAlign: 'left', boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.1)' }}
                            >
                                <PieChartGraph
                                    title={t('ANALYTICS_SECTION.COMPLETED')}
                                    data={[
                                        { label: 'Late', color: '#E4002B', value: jobChartData?.completionTimeStatistics?.late ?? 0 },
                                        { label: 'On time', color: '#008F31', value: jobChartData?.completionTimeStatistics?.onTime ?? 0 },
                                    ]}
                                />
                            </Box>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            lg={2.4}
                        >
                            <Box
                                component={'div'}
                                sx={{ height: '100%', padding: '16px 10px', bgcolor: '#FAFAFA', borderRadius: '8px', textAlign: 'left', boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.1)' }}
                            >
                                {jobChartData ? (
                                    <>
                                        <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>{t('JOB_MANAGEMENT.FPY')}</Typography>
                                        <Stack
                                            gap={'10px'}
                                            direction={'row'}
                                            sx={{ alignItems: 'center' }}
                                        >
                                            <Typography sx={{ fontSize: '36px', fontWeight: 600 }}>{jobChartData?.successRateStatistics?.fpy}</Typography>
                                            <Stack
                                                direction={'row'}
                                                alignItems={'center'}
                                            >
                                                <ArrowUpwardIcon sx={{ fontSize: '22px', color: '#008F31', fontWeight: 600 }} />
                                                <Box
                                                    component={'span'}
                                                    fontSize={'18px'}
                                                    fontWeight={500}
                                                    color={'#008F31'}
                                                >
                                                    {jobChartData?.successRateStatistics.fpyPercentChange}
                                                </Box>
                                            </Stack>
                                        </Stack>
                                        <Typography sx={{ fontSize: '12px', fontWeight: 500, color: '#E4002B' }}>
                                            {jobChartData?.successRateStatistics.failedJobs} {t('JOB_MANAGEMENT.FAILED_JOBS')}
                                        </Typography>
                                    </>
                                ) : (
                                    <Typography sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '24px', fontWeight: 500 }}>N/A</Typography>
                                )}
                            </Box>
                        </Grid> */}
                    </>
                )}
            </Grid>
        </Stack>
    );
};
export default JobOverView;
const PieChartGraph: React.FC<PieChartProps> = (props) => {
    // if (!props.data || props.data.length === 0) {
    //     return null;
    // }
    return (
        <Stack gap={'10px'}>
            <Typography sx={{ fontSize: '16PX', fontWeight: '500' }}>{props.title}</Typography>
            <Box
                component={'div'}
                gap={'50px'}
                sx={{ display: 'flex', alignItems: 'center', width: '100%', position: 'relative', height: '100%', justifyContent: 'start' }}
            >
                <Box component={'div'}>
                    <ResponsiveContainer
                        width={105}
                        minHeight={105}
                    >
                        <PieChart>
                            <Pie
                                isAnimationActive={true}
                                animationDuration={1500}
                                animationBegin={0}
                                data={props.data}
                                dataKey="value"
                                nameKey="label"
                                innerRadius={35}
                                outerRadius={52}
                                stroke="white"
                                strokeWidth={2}
                                startAngle={90}
                                endAngle={-270}
                            >
                                {props.data?.map((entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={entry.color}
                                    />
                                ))}
                                <Label
                                    value={props.data?.reduce((sum, item) => sum + item.value, 0)}
                                    position="center"
                                    style={{
                                        fontSize: '22px',
                                        fontWeight: '500',
                                        textAnchor: 'middle',
                                        fill: '#0A0A0A',
                                    }}
                                />
                            </Pie>
                            <Tooltip />
                        </PieChart>
                    </ResponsiveContainer>
                </Box>
                <Box
                    // item
                    // xs={5}
                    component={'div'}
                >
                    {props.data?.map((item, index) => {
                        return (
                            <Stack
                                key={index}
                                direction={'row'}
                                gap={'6px'}
                                sx={{ justifyContent: 'start', alignItems: 'center', marginBottom: '6px' }}
                            >
                                <CircleIcon sx={{ color: item.color, fontSize: '16px', minWidth: 0 }} />
                                <Box
                                    component={'span'}
                                    sx={{ fontWeight: 500, fontSize: '12px' }}
                                >
                                    {' '}
                                    {`${item.label} (${item.value})`}
                                </Box>
                            </Stack>
                        );
                    })}
                </Box>
            </Box>
        </Stack>
    );
};

const LoadingState = () => {
    return (
        <>
            <Grid
                item
                md={12}
                lg={3.2}
            >
                <Skeleton
                    variant="rounded"
                    sx={{ width: '100%', height: '170px' }}
                />
            </Grid>
            {/* <Grid
                item
                md={12}
                lg={2.5}
            >
                <Skeleton
                    variant="rounded"
                    sx={{ width: '100%', height: '170px' }}
                />
            </Grid>
            <Grid
                item
                md={12}
                lg={2.4}
            >
                <Skeleton
                    variant="rounded"
                    sx={{ width: '100%', height: '170px' }}
                />
            </Grid> */}
        </>
    );
};
