import React, { useEffect, useState } from 'react';
import NewJob from './NewJob';
import { useNavigate, useParams } from 'react-router-dom';
import JobMAnagementService from '../../services/JobManagement/JobManagementService';
import { Skeleton } from '@mui/material';
import Loading from '../Loading/Loading';
import { Toast } from '../Notifications/Notification';
import { useTranslation } from 'react-i18next';
const ViewJob = () => {
    const { jobId } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [jobData, setJobData] = useState<any>();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        setLoading(true);
        JobMAnagementService.getJobByIDService(jobId)
            .then((res) => {
                setJobData(res?.data?.data);
            })
            .catch(({ response }) => {
                Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response?.data?.messageCode, { ns: 'error' }) || response?.data?.errorMessage);
                navigate('/job');
            })
            .finally(() => {
                setLoading(false);
            });
    }, [jobId]);
    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <NewJob
                    isView={true}
                    jobData={jobData}
                    id = {jobId??""}
                />
            )}
        </>
    );
};
export default ViewJob;
