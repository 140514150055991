import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import PaginatedTableComponent, { ColumnProps, TableCellHeading } from '../Table/PaginatedTableComponent';
import JobMAnagementService from '../../services/JobManagement/JobManagementService';
import CloseIcon from '@mui/icons-material/Close';
import { ProcedureLibrarySearchSortFilterOptions } from '../../pages/Trainer/ProcedureLibrary/ProcedureLibraryToolbar';
import { ProcedureLibraryTable } from '../../pages/Trainer/ProcedureLibrary/ProcedureLibrary';
import { BasicDialog } from '../Trainer/Dialog/BasicDialog/BasicDialog';
import { InfoOutlined } from '@mui/icons-material';
import TrainerService from '../../services/trainer/TrainerService';
import { Toast } from '../Notifications/Notification';
import { PageEmptyState, PageLoading } from '.';
import { convertSecondsToTime } from './JobDetail';
interface JobProcedureProps {
    id: string;
    name: string;
    description: string;
    estimated_time: string;
    material: string;
    action: any;
}
const JobProcedure: React.FC<{ data; onChange; procedureList; setProcedureList; isDisableEdit; isView }> = (props) => {
    const { t } = useTranslation();
    const location = useLocation();
    const [materialNo, setMaterialNo] = useState<string>(location.state?.materialNo);
    // const [procedureList, setProcedureList] = useState<any[]>([]);
    const [isSelectTemplateProcedureDialogOpen, setIsSelectTemplateProcedureDialogOpen] = useState(false);
    const [selectedTemplateProcedures, setSelectedTemplateProcedures] = useState<string[]>([]);
    const [isUpdate, setUpdate] = useState<boolean>(false);
    const { jobId } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const columns: ColumnProps[] = useMemo(() => {
        return [
            {
                label: <TableCellHeading value={t('COMMON.NAME')} />,
                id: 'name',
                isSortBy: true,
                align: 'left',
                render: (value, row) => {
                    return <TableCellHeading value={value} />;
                },
            },
            {
                label: <TableCellHeading value={t('COMMON.DESCRIPTION')} />,
                id: 'description',
                isSortBy: false,
                align: 'left',
                render: (value, row) => {
                    return <TableCellHeading value={value} />;
                },
            },
            {
                label: <TableCellHeading value={t('COMMON.ESTIMATED_TIME')} />,
                id: 'estimated_time',
                isSortBy: false,
                align: 'left',
                render: (value, row) => {
                    return <TableCellHeading value={convertSecondsToTime(value as string)} />;
                },
            },
            {
                label: <TableCellHeading value={'JOB_MANAGEMENT.MATERIAL'} />,
                id: 'material',
                isSortBy: false,
                align: 'left',
                render: (value, row) => {
                    const limit = 2;
                    return (
                        <>
                            {value ? (
                                <Stack
                                    direction={'row'}
                                    gap={'4px'}
                                    alignItems={'center'}
                                >
                                    {value?.map((item, index) => index < limit && <Box sx={{ borderRadius: '48px', padding: '4px 8px', border: '1px solid #000000', boxShadow: '0px 1px 2px #1018280D', fontSize: '12px', fontWeight: 400 }}>{item}</Box>)}
                                    {value.length > limit && <Typography sx={{ fontSize: '14px', fontWeight: 500, textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{`+${value.length - limit}`}</Typography>}
                                </Stack>
                            ) : (
                                <Typography sx={{ paddingLeft: '10px', fontSize: '14px', fontWeight: 500, textAlign: 'start' }}>-</Typography>
                            )}
                        </>
                    );
                },
            },
            {
                label: <TableCellHeading value={t('JOB_MANAGEMENT.ACTION')} />,
                id: 'action',
                isSortBy: false,
                align: 'center',
                render: (value, row) => {
                    return (
                        <Box component={'div'}>
                            <Box
                                component={'div'}
                                sx={{ opacity: props.isDisableEdit ? 0.5 : 1, width: '24px', height: '24px', border: '2px solid #E4002B', borderRadius: '50%', margin: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                onClick={() => !props.isDisableEdit && deleteProcedure(row.id)}
                            >
                                <CloseIcon
                                    fontSize="inherit"
                                    sx={{ margin: 'auto', color: '#E4002B', padding: 0 }}
                                />
                            </Box>
                        </Box>
                    );
                },
            },
        ];
    }, [props.isDisableEdit]);

    useEffect(() => {
        if (isUpdate) {
            const tempList = props.procedureList.map((item) => item.id);
            props.onChange('procedures', tempList);
            const totalEstimatedTime = props.procedureList.reduce((total, item) => {
                console.log('item.estimated_time==== ', item.estimated_time);
                return total + item.estimated_time;
            }, 0);
            props.onChange('estimatedTime', convertSecondsToTime(totalEstimatedTime));
            console.log('totalEstimatedTime==== ', totalEstimatedTime);
            setUpdate(false);
        }
    }, [isUpdate]);
    useEffect(() => {
        if (props.isView) {
            setLoading(true);
            setMaterialNo(props?.data?.materialNo);
            JobMAnagementService.getJobProcedureList(jobId)
                .then((res) => {
                    const procedures = res?.data?.data;
                    const tmpList: any[] = [];
                    procedures?.forEach((p) => {
                        const tempData: JobProcedureProps = {
                            id: p.procedureId,
                            name: p.procedureName,
                            description: p.description,
                            estimated_time: p.estimatedTime,
                            material: p.material ?? '',
                            action: '',
                        };
                        tmpList.push(tempData);
                    });
                    props.setProcedureList(tmpList);
                })
                .catch(({ response }) => {
                    Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response?.data?.messageCode, { ns: 'error' }) || response?.data?.errorMessage);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [props.isView]);
    const handleClose = () => {
        setSelectedTemplateProcedures([]);
        setIsSelectTemplateProcedureDialogOpen(false);
    };
    function handleRowClick(row) {
        let temp = [...selectedTemplateProcedures];
        const clickedProcedureId = row.original.procedureId;

        if (temp.includes(clickedProcedureId)) {
            temp = temp.filter((proc) => proc !== clickedProcedureId);
        } else {
            temp.push(clickedProcedureId);
        }
        setSelectedTemplateProcedures(temp);
    }
    const handleAddClick = async () => {
        const promiseArray = selectedTemplateProcedures.map((procedure) => TrainerService.getProcedureById(procedure));
        return Promise.all(promiseArray)
            .then((response) => {
                response?.map((res) => {
                    addProcedure(res?.data?.data);
                    setUpdate(true);
                });
            })
            .catch(({ response }) => {
                Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
            })
            .finally(() => {
                handleClose();
            });
    };
    const addProcedure = (data) => {
        const tempData: JobProcedureProps = {
            id: data.procedureId,
            name: data.procedureName,
            description: data.ppeDescription,
            estimated_time: data.estimated_time,
            material: data.material ? data.material : '',
            action: '',
        };
        props.setProcedureList((prev = []) => [...prev, tempData]);
    };
    const deleteProcedure = (id) => {
        props.setProcedureList((prevList) => prevList.filter((item) => item.id !== id));
        setUpdate(true);
    };
    return (
        <>
            <Stack
                gap={'16px'}
                sx={{ textAlign: 'start', position: 'relative' }}
            >
                <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>{t('JOB_MANAGEMENT.PROCEDURES_FOR', { material: materialNo })}</Typography>
                {loading ? (
                    <PageLoading />
                ) : (
                    <>
                        {' '}
                        <Box
                            component={'div'}
                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
                        >
                            <Button
                                variant="contained"
                                size="small"
                                sx={{
                                    height: '40px',
                                    right: 0,
                                    bgcolor: '#88C23F',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    color: 'white',
                                    borderRadius: '100px',
                                    minWidth: 'fit-content',
                                    padding: '8px 18px',
                                    '&:hover, &:active, &.Mui-disabled': {
                                        background: '#88C23F',
                                        color: 'white',
                                    },
                                    '&.Mui-disabled': {
                                        opacity: 0.7,
                                    },
                                }}
                                disabled={props.isDisableEdit}
                                onClick={() => setIsSelectTemplateProcedureDialogOpen(true)}
                            >
                                {t('BUTTON.ADD')}
                            </Button>
                        </Box>
                        {props.procedureList?.length ? (
                            <Box
                                component={'div'}
                                sx={{
                                    width: '100%',
                                    minWidth: '650px',
                                    maxHeight: '550px',
                                    overflowY: 'auto',
                                    '& .MuiPaper-root': {
                                        boxShadow: 'none',
                                        border: 'none',
                                    },
                                    ' table': {
                                        border: 'none',
                                        tableLayout: 'fixed',

                                        '& th, & td': {
                                            height: '48px',
                                            maxHeight: '40px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            // whiteSpace: 'nowrap',
                                        },
                                        '& th:nth-of-type(1), & td:nth-of-type(1)': {
                                            width: '18%',
                                        },
                                        '& th:nth-of-type(2), & td:nth-of-type(2)': {
                                            width: '35%',
                                        },
                                        '& th:nth-of-type(4), & td:nth-of-type(4)': {
                                            minWidth: 'fit-content',
                                        },
                                        '& th:nth-of-type(5), & td:nth-of-type(5)': {
                                            width: '10%',
                                        },
                                    },
                                }}
                            >
                                <PaginatedTableComponent
                                    isCheckBoxEnabled={false}
                                    data={props.procedureList}
                                    columns={columns}
                                    isPaginationEnabled={false}
                                />
                            </Box>
                        ) : (
                            <PageEmptyState />
                        )}
                    </>
                )}
            </Stack>
            <ProcedureTableModal
                isOpen={isSelectTemplateProcedureDialogOpen}
                handleClose={handleClose}
                handleRowClick={handleRowClick}
                selectedTemplateProcedures={selectedTemplateProcedures}
                handleAddClick={handleAddClick}
            />
        </>
    );
};

export default JobProcedure;

const ProcedureTableModal = (props) => {
    const { isOpen, handleClose, handleRowClick, selectedTemplateProcedures, handleAddClick } = props;
    const { t } = useTranslation();

    const checkIsRowSelected = (row) => {
        return selectedTemplateProcedures.includes(row.original.procedureId);
    };

    const checkIsRowDisabled = (row) => {
        return !row.original.isPublished;
    };
    return (
        <BasicDialog
            onClose={handleClose}
            onOpen={isOpen}
            title={t('PROCEDURE_LIBRARY.IMPORT_PROCEDURE_DIALOG.TITLE')}
            id="select-entity"
            fullWidth={true}
            maxWidth={'lg'}
            actions={
                <DialogueActionContainer
                    handleClose={handleClose}
                    handleAddClick={handleAddClick}
                    selectedProcedures={selectedTemplateProcedures}
                />
            }
        >
            <Box
                component={'div'}
                sx={{
                    display: 'flex',
                    gap: '10px',
                    marginBottom: '10px',
                    '> .sort': {
                        marginLeft: 'auto',
                    },
                }}
            >
                <ProcedureLibrarySearchSortFilterOptions />
            </Box>
            <ProcedureLibraryTable
                handleRowClick={handleRowClick}
                showProcedureOptions={false}
                checkIsRowSelected={checkIsRowSelected}
                checkIsRowDisabled={checkIsRowDisabled}
                scrollableDivId={'dialogue-content-select-entity'}
            />
        </BasicDialog>
    );
};
const DialogueActionContainer = (props) => {
    const { t } = useTranslation();
    const { handleClose, handleAddClick, selectedProcedures } = props;

    const [isAddClicked, setIsAddClicked] = useState(false);
    const handleAddButtonClick = async () => {
        try {
            setIsAddClicked(true);
            await handleAddClick();
        } catch (error) {
        } finally {
            setIsAddClicked(false);
        }
    };
    return (
        <>
            <Box
                component={'div'}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px', marginRight: 'auto', height: '60px' }}
            >
                <InfoOutlined />
                <span>{t('PROCEDURE_LIBRARY.IMPORT_PROCEDURE_DIALOG.ONLY_PUBLISHED_SUPPORTED')}</span>
            </Box>
            <Button
                variant="outlined"
                onClick={handleClose}
            >
                {t('BUTTON.CANCEL')}
            </Button>
            <Button
                endIcon={isAddClicked ? <CircularProgress size={20} /> : null}
                disabled={!selectedProcedures.length || isAddClicked}
                variant="contained"
                onClick={handleAddButtonClick}
                autoFocus
            >
                {t('BUTTON.ADD')} {selectedProcedures.length ? ` (${selectedProcedures.length})` : null}
            </Button>
        </>
    );
};
