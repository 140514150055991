import { IconButton, Popover, Stack, Typography } from '@mui/material';
import { CalendarIcon, DateCalendar } from '@mui/x-date-pickers';
import moment from 'moment';
import React, { useRef } from 'react';
import { EditIcon } from '../Icons/CdsIcons';
import { FormDataProps } from './interface';

interface DateComponentProps {
    label: string;
    value: string;
    iconClickHandler: () => void;
    open: boolean;
    pickerType: string;
    onDateChange: (value: string) => void;
    data: FormDataProps;
    closePicker: () => void;
}
const DateComponent: React.FC<DateComponentProps> = ({ label, value, iconClickHandler, open, pickerType, onDateChange, data, closePicker }) => {
    const startDateRef = useRef(null);
    const dueDateRef = useRef(null);
    return (
        <>
            <Stack
                gap="10px"
                sx={{ width: '100%', paddingBlock: '7px' }}
            >
                <Stack
                    direction="row"
                    gap="10px"
                    sx={{ alignItems: 'center' }}
                >
                    <IconButton
                        disableRipple
                        sx={{ padding: 0 }}
                    >
                        <CalendarIcon />
                    </IconButton>
                    <Typography sx={{ fontSize: '16px', fontWeight: 500, color: '#666666' }}>{label}</Typography>
                </Stack>
                <Stack
                    direction="row"
                    gap="10px"
                    sx={{ alignItems: 'center' }}
                    ref={pickerType === 'start' ? startDateRef : dueDateRef}
                >
                    <Typography sx={{ fontSize: '18px', fontWeight: 500, color: '#1C1D1F', textAlign: 'start' }}>{value}</Typography>
                    <IconButton
                        sx={{ padding: 0 }}
                        onClick={iconClickHandler}
                    >
                        <EditIcon />
                    </IconButton>
                </Stack>
                <DatePicker
                    pickerType={pickerType}
                    open={open}
                    startDate={data?.startDate}
                    endDate={data?.dueDate}
                    anchor={pickerType === 'start' ? startDateRef.current : dueDateRef.current}
                    closePicker={closePicker}
                    onDateChange={(value) => onDateChange(value)}
                />
            </Stack>
        </>
    );
};

export default DateComponent;

interface pickerProps {
    pickerType: string;
    open: boolean;
    startDate: string;
    endDate: string;
    closePicker: () => void;
    anchor: any;
    onDateChange: (value: string) => void;
}
const DatePicker: React.FC<pickerProps> = ({ pickerType, open, startDate, endDate, anchor, closePicker, onDateChange }) => {
    return (
        <>
            <Popover
                id="job-date-picker"
                open={open}
                onClose={closePicker}
                anchorEl={anchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <DateCalendar
                    value={moment(pickerType === 'start' ? startDate : endDate, 'DD-MM-YYYY')}
                    onChange={(momentInstance) => {
                        onDateChange(moment(momentInstance).format('DD-MM-YYYY'));
                    }}
                    disablePast
                    sx={{
                        '& .MuiPickersCalendarHeader-root': {
                            marginBlock: '0px',
                        },
                    }}
                />
            </Popover>
        </>
    );
};
