import React, { useState } from 'react';
import { BasicDialog } from '../Trainer/Dialog/BasicDialog/BasicDialog';
import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { FormField, FormLayout } from '../FormItem/FormItems';
import { NewJobModalProps } from './interface';

const NewJobModal: React.FC<NewJobModalProps> = ({ isOpen, handleClose, setJobNo, jobNo, onClickDone }) => {
    const { t } = useTranslation();
    const [isJobIdInsert, setJobInsert] = useState<boolean>(false);
    const [isSerialNoEnabled, setSerialNoEnabled] = useState<boolean>(false);
    const MAX_LENGTH = 10;
    const onChange = (type, value) => {
        if (type === 'serialNo') {
            setJobNo((prev) => ({
                ...prev,
                serialNo: value.toUpperCase().trim(),
            }));
        } else {
            setJobNo((prev) => ({
                ...prev,
                materialNo: value.toUpperCase().trim(),
            }));
        }
    };
    const checkValidation = () => {
        let isCheck = true;
        if (isJobIdInsert) {
            isCheck = jobNo.serialNo.length < MAX_LENGTH || jobNo.materialNo.length < MAX_LENGTH;
        } else {
            isCheck = jobNo.serialNo.length < MAX_LENGTH;
        }
        return isCheck;
    };
    return (
        <BasicDialog
            title="New Job Order"
            onClose={handleClose}
            onOpen={isOpen}
            id="add-job"
            fullWidth={true}
            dialogWidth={'800px'}
            actions={
                <Box
                    component={'div'}
                    sx={{ width: '100%', marginTop: '100px' }}
                >
                    <Divider sx={{ bgcolor: '#E0E2EC', marginBottom: '8px' }}></Divider>
                    <Stack
                        direction={'row'}
                        sx={{ width: '100%', alignItems: 'center', justifyContent: 'center', gap: '16px' }}
                    >
                        <Button
                            variant="outlined"
                            size="small"
                            sx={{
                                paddingInline: 0,
                                fontSize: '14px',
                                fontWeight: 600,
                                justifySelf: 'center',
                                border: '2px solid #88C23F',
                                borderRadius: '100px',
                                color: '#88C23F',
                                '&:hover, &:active, &.Mui-disabled': {
                                    border: '2px solid #88C23F',
                                },
                            }}
                            onClick={handleClose}
                        >
                            {t('BUTTON.CANCEL')}
                        </Button>
                        <Button
                            variant="contained"
                            size="small"
                            sx={{
                                paddingInline: 0,
                                fontSize: '14px',
                                fontWeight: 600,
                                justifySelf: 'center',
                                background: '#88C23F',
                                borderRadius: '100px',
                                color: '#FFFFFF',
                                border: '2px solid #88C23F',
                                '&:hover, &:active, &.Mui-disabled': {
                                    color: '#FFFFFF',
                                    background: '#88C23F',
                                    border: '2px solid #88C23F',
                                },
                                '&.Mui-disabled': {
                                    opacity: 0.5,
                                },
                            }}
                            disabled={checkValidation()}
                            onClick={() => (isJobIdInsert ? onClickDone() : setJobInsert(true))}
                        >
                            {t(isJobIdInsert ? 'BUTTON.DONE' : 'BUTTON.NEXT')}
                        </Button>
                    </Stack>
                </Box>
            }
        >
            <Stack gap={'16px'}>
                <Divider sx={{ bgcolor: '#E0E2EC' }}></Divider>
                <Box
                    component={'div'}
                    sx={{ bgcolor: '#F3F7FC', padding: '10px' }}
                >
                    <Typography sx={{ color: '#071829', fontSize: '14px', fontWeight: '400' }}>
                        <Trans
                            i18nKey="JOB_MANAGEMENT.SCAN_MESSAGE"
                            components={{
                                bold: <strong style={{ fontSize: '16px', fontWeight: 600, color: '#071829' }} />,
                            }}
                        ></Trans>
                    </Typography>
                </Box>
                <Grid
                    container
                    spacing={'10px'}
                    padding={0}
                    sx={{ display: 'flex', flexDirection: 'column' }}
                >
                    <Grid
                        item
                        xs={11}
                        sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                    >
                        <FormField
                            value={jobNo.serialNo}
                            label={t('JOB_MANAGEMENT.SERIAL_NUMBER')}
                            required={false}
                            placeholder={''}
                            type="text"
                            onChange={(e) => onChange('serialNo', e.target.value)}
                            disabled={isJobIdInsert ? (isSerialNoEnabled ? false : true) : false}
                            maxLength={10}
                        />{' '}
                        {isJobIdInsert && (
                            <Box
                                component={'span'}
                                sx={{ justifySelf: 'flex-start', color: '#1079BF', fontSize: '14px', fontWeight: 400, marginTop: '20px', cursor: 'pointer' }}
                                onClick={() => {
                                    setSerialNoEnabled(true);
                                    setJobNo((prev) => ({
                                        ...prev,
                                        materialNo: '',
                                    }));
                                }}
                            >
                                {t('JOB_MANAGEMENT.CHANGE')}
                            </Box>
                        )}
                    </Grid>

                    {isJobIdInsert && (
                        <Grid
                            item
                            xs={11}
                        >
                            <FormField
                                value={jobNo.materialNo}
                                label={t('JOB_MANAGEMENT.MATERIAL_NUMBER')}
                                required={false}
                                placeholder={''}
                                type="text"
                                onChange={(e) => onChange('materialNo', e.target.value)}
                                disabled={false}
                                maxLength={10}
                            />
                        </Grid>
                    )}
                </Grid>
            </Stack>
        </BasicDialog>
    );
};

export default NewJobModal;
