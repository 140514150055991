import styled from '@emotion/styled';
import { ExpandMore } from '@mui/icons-material';
import { Box, InputBase, MenuItem, Popover, Select, StyledComponentProps, SxProps, Theme, Typography } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { IDateRange } from '../../entities/Analytics/AnalyticsProcedure';
import { useAppSelector } from '../../hooks/redux';
import { SELECT_DATARANGE_OPTIONS } from '../../shared/enums';
interface DateRangeSelectorProps {
    selectedDateRangeOption: SELECT_DATARANGE_OPTIONS;
    setSelectedDateRangeOption: (dateRangeOption: SELECT_DATARANGE_OPTIONS) => void;
    dateRange: IDateRange;
    setDateRange: (dateRange: IDateRange) => void;
    withBorder?: boolean;
    sx?: SxProps<Theme>;
    showAllTimeOption?: boolean;
    showATodayOption?: boolean;
}
const DateRangeSelector = (props: DateRangeSelectorProps) => {
    const {
        withBorder = false,
        sx,
        selectedDateRangeOption,
        setDateRange,
        dateRange: { startDate, endDate },
        setSelectedDateRangeOption,
        showATodayOption = false,
    } = props;
    const { t } = useTranslation();
    const selectDateDropdown = useRef(null);
    const [selectedOption, setSelectedOption] = useState<any>('');
    const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);

    useEffect(() => {
        setSelectedOption(selectedDateRangeOption);
    }, [selectedDateRangeOption]);

    useEffect(() => {
        if (!selectedOption) return;
        const dateRangeToSet: IDateRange = { startDate: '', endDate: '' };
        if (selectedOption === SELECT_DATARANGE_OPTIONS.CUSTOM) {
            return;
        }
        switch (selectedOption) {
            case SELECT_DATARANGE_OPTIONS.TODAY:
                const today = moment().format('DD-MM-YYYY');
                dateRangeToSet.startDate = today;
                dateRangeToSet.endDate = today;
                break;
            case SELECT_DATARANGE_OPTIONS.CURRENT_MONTH:
                dateRangeToSet.startDate = moment().startOf('month').format('DD-MM-YYYY');
                dateRangeToSet.endDate = moment().format('DD-MM-YYYY'); // today
                break;
            case SELECT_DATARANGE_OPTIONS.LAST_MONTH:
                dateRangeToSet.startDate = moment(moment().subtract(1, 'month')).startOf('month').format('DD-MM-YYYY');
                dateRangeToSet.endDate = moment(moment().subtract(1, 'month')).endOf('month').format('DD-MM-YYYY');
                break;
            case SELECT_DATARANGE_OPTIONS.CURRENT_WEEK:
                dateRangeToSet.startDate = moment().startOf('week').format('DD-MM-YYYY');
                dateRangeToSet.endDate = moment().format('DD-MM-YYYY'); // today
                break;
            case SELECT_DATARANGE_OPTIONS.LAST_WEEK:
                dateRangeToSet.startDate = moment(moment().subtract(1, 'week')).startOf('week').format('DD-MM-YYYY');
                dateRangeToSet.endDate = moment(moment().subtract(1, 'week')).endOf('week').format('DD-MM-YYYY');
                break;
        }
        setSelectedDateRangeOption(selectedOption);
        setDateRange(dateRangeToSet);
    }, [selectedOption]);

    const handleChangeDateRange = (event) => {
        if (event.target.value === SELECT_DATARANGE_OPTIONS.CUSTOM) {
            setIsDatePickerOpen(true);
            console.log(startDate, '  custom date   ', endDate);
        }
        setSelectedOption(event.target.value);
    };

    const handleClick = (e) => {
        if (e.target.getAttribute('data-value') === SELECT_DATARANGE_OPTIONS.CUSTOM) {
            setIsDatePickerOpen(true);
        }
    };

    const handleDateRangeChange = (start, end) => {
        const dateRangeToSet: IDateRange = { startDate: start, endDate: end };
        console.log(dateRangeToSet);
        if (start) setDateRange(dateRangeToSet);
        setSelectedDateRangeOption(SELECT_DATARANGE_OPTIONS.CUSTOM);
    };

    return (
        <Box component={'div'}>
            <Select
                sx={sx}
                value={selectedDateRangeOption}
                onClick={handleClick}
                onChange={handleChangeDateRange}
                ref={selectDateDropdown}
                input={withBorder ? undefined : <CustomInput />}
                IconComponent={ExpandMore}
                renderValue={(selected) => {
                    if (selected === SELECT_DATARANGE_OPTIONS.CUSTOM) {
                        return `${startDate ? moment(startDate, 'DD-MM-YYYY').format('MMM D, YYYY') : t('COMMON.START_DATE')} - ${endDate ? moment(endDate, 'DD-MM-YYYY').format('MMM D, YYYY') : t('COMMON.END_DATE')} `;
                    }
                    return t(`ANALYTICS_SECTION.${selected}`);
                }}
            >
                {Object.keys(SELECT_DATARANGE_OPTIONS).map((key) => {
                    if (SELECT_DATARANGE_OPTIONS[key] === SELECT_DATARANGE_OPTIONS.ALL && !props.showAllTimeOption) return null;
                    if (SELECT_DATARANGE_OPTIONS[key] === SELECT_DATARANGE_OPTIONS.TODAY && !props.showATodayOption) return null;

                    return (
                        <MenuItem
                            key={key}
                            value={key}
                        >
                            {t('ANALYTICS_SECTION.' + key)}
                        </MenuItem>
                    );
                })}
            </Select>

            <Popover
                id={'analytics-date-range'}
                open={isDatePickerOpen}
                anchorEl={selectDateDropdown.current}
                onClose={() => setIsDatePickerOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box
                    component="div"
                    sx={{ display: 'flex', flexDirection: 'row' }}
                >
                    <Box
                        component={'div'}
                        sx={{ display: 'flex', flexDirection: 'column' }}
                    >
                        <Typography
                            variant="subtitle1"
                            sx={{ padding: 0, alignSelf: 'center', fontWeight: 700, fontSize: '14px', marginTop: '5px' }}
                        >
                            {t('COMMON.START_DATE')}
                        </Typography>
                        <DateCalendar
                            value={moment(startDate, 'DD-MM-YYYY')}
                            onChange={(momentInstance) => {
                                handleDateRangeChange(moment(momentInstance).format('DD-MM-YYYY'), endDate);
                            }}
                            disableFuture
                            maxDate={moment(endDate, 'DD-MM-YYYY')}
                            sx={{
                                '& .MuiPickersCalendarHeader-root': {
                                    marginBlock: '0px',
                                },
                            }}
                        />
                    </Box>
                    <Box
                        component={'div'}
                        sx={{ display: 'flex', flexDirection: 'column' }}
                    >
                        <Typography
                            variant="subtitle1"
                            sx={{ padding: 0, alignSelf: 'center', fontWeight: 700, fontSize: '14px', marginTop: '5px' }}
                        >
                            {t('COMMON.END_DATE')}
                        </Typography>
                        <DateCalendar
                            // value={moment(endDate, 'DD-MM-YYYY')}
                            value={startDate && endDate ? moment(endDate, 'DD-MM-YYYY') : null}
                            onChange={(momentInstance) => {
                                handleDateRangeChange(startDate, moment(momentInstance).format('DD-MM-YYYY'));
                            }}
                            disableFuture
                            // minDate={moment(startDate, 'DD-MM-YYYY')}
                            minDate={startDate ? moment(startDate, 'DD-MM-YYYY') : null}
                            sx={{
                                '& .MuiPickersCalendarHeader-root': {
                                    marginBlock: '0px',
                                },
                            }}
                        />
                    </Box>
                </Box>
            </Popover>
        </Box>
    );
};

const CustomInput = styled(InputBase)(({ theme }) => ({
    border: 0,
    '& .MuiInputBase-input': {
        paddingBottom: '0px',
        paddingTop: '0px',
    },
}));

export default DateRangeSelector;
