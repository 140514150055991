import { Box, Button, Skeleton, Typography, lighten, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IDateRange } from '../../../entities/Analytics/AnalyticsProcedure';
import { useAnalytics } from '../../../states/analytics';
import { t } from 'i18next';
import AnalyticsService from '../../../services/Analytics/AnalyticsService';
import { Toast } from '../../Notifications/Notification';
import moment from 'moment';
import TableContainer, { columnFilter, TableColumnHeader } from '../ProcedureReports/TableContainer';
import { secondsToHms } from '../ProcedureReports/TraineeTime';
import '../Analytics.scss';
import { BasicDialog } from '../../Trainer/Dialog/BasicDialog/BasicDialog';
import { QuestionResponsesPerUser } from '../Modals/QuestionResponses';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Comments } from '../Modals/Comments';
import { useTranslation } from 'react-i18next';
import { WorkInstructionCompletionStatus } from './TraineesAssigned/TraineeAssignedDetails';

export const TraineeCompletionRate = () => {
    const { t } = useTranslation();

    const [allRecords, setAllRecords] = useState<Array<any>>();
    const [loading, setLoading] = useState(false);
    const [questionResponses, setQuestionResponses] = useState<any>();
    const [showResponses, setShowResponses] = useState<any>();
    const [showComments, setShowComments] = useState<any>();
    const [comments, setComments] = useState<any>();
    const [completionRate, setCompletionRate] = useState<any>();

    const { search: searchQuery } = useLocation();
    const currentDateRange = useAnalytics((state) => state.selectedDateRange);
    const query = new URLSearchParams(searchQuery);
    const startDate = query.get('startDate');
    const endDate = query.get('endDate');
    const filterType = query.get('filter');

    const theme = useTheme();
    const navigate = useNavigate();

    const QuestionResponses = () => {
        return (
            <BasicDialog
                title={t('ANALYTICS_SECTION.RESPONSES')}
                onClose={() => {
                    setShowResponses(false);
                }}
                onOpen={showResponses}
            >
                {questionResponses.map((userResponse) => (
                    <>
                        <Typography sx={{ mb: 2, fontSize: '14px' }}>
                            <AccountCircleIcon sx={{ mb: '-7px' }} /> {userResponse?.firstName + ' ' + userResponse?.lastName}
                        </Typography>
                        <QuestionResponsesPerUser questionResponses={userResponse.questions} />
                    </>
                ))}
            </BasicDialog>
        );
    };

    const columns = useMemo(
        () => [
            {
                Header: <TableColumnHeader label={'ANALYTICS_SECTION.PROCEDURE_NAME'} />,
                disableSortBy: true,
                accessor: 'procedure',
                Cell: ({ value }) => {
                    const { t } = useTranslation();
                    return (
                        <Box
                            component="div"
                            sx={{ display: 'flex', flexDirection: 'column', padding: '20px', alignItems: 'flex-start' }}
                        >
                            <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px' }}>WI: {value.wiName}</Typography>
                            <Button
                                onClick={() => navigate(`/analytics/procedures/assigned/details/${value.workInstructionId}/${value.procedureId}?filter=${filterType}&startDate=${startDate}&endDate=${endDate}`)}
                                sx={{ fontSize: '14px', margin: '8px 0px', minWidth: 0, padding: 0, color: '#004b8d' }}
                            >
                                {value.procedureName}
                            </Button>
                            <Box
                                component="div"
                                sx={{ display: 'flex', alignItems: 'center' }}
                            >
                                <Typography
                                    className="feedback"
                                    sx={{ background: lighten(theme.palette.primary.main, 0.95), color: 'rgba(0, 0, 0, 0.6)' }}
                                >
                                    {t('ANALYTICS_SECTION.RATINGS')} {value.feedback ? (value?.feedback.averageRatings || '0') + '/5' : '(N/A)'}
                                </Typography>
                                <Typography
                                    className="feedback"
                                    sx={{ background: lighten(theme.palette.primary.main, 0.95), color: 'rgba(0, 0, 0, 0.6)' }}
                                >
                                    {t('ANALYTICS_SECTION.RECOMMENDED')} {value.feedback ? (value?.feedback.averageRecommendations || '0') + '/10' : '(N/A)'}
                                </Typography>
                                <Button
                                    sx={{ fontSize: '12px', background: lighten(theme.palette.primary.main, 0.95), borderRadius: '4px', padding: '1px', margin: 0, marginLeft: '6px', minHeight: 0, color: '#004b8d' }}
                                    onClick={() => {
                                        setShowComments(true);
                                        setComments(value.feedback.comments);
                                    }}
                                >
                                    {t('ANALYTICS_SECTION.COMMENTS')} {value?.feedback?.comments.length || 0}
                                </Button>
                            </Box>
                        </Box>
                    );
                },
            },
            {
                Header: <TableColumnHeader label={'COMMON.STATUS'} />,
                accessor: 'status',
                disableSortBy: true,
                Filter: columnFilter,
                Cell: ({ value }) => <WorkInstructionCompletionStatus value={value} />,
            },
            {
                Header: <TableColumnHeader label={'ANALYTICS_SECTION.TRAINEES'} />,
                accessor: 'number_of_trainees',
            },
            {
                Header: <TableColumnHeader label={'ANALYTICS_SECTION.COMPLETED'} />,
                accessor: 'completed',
                Cell: ({ value }) => <span>{moment.unix(value).format('Do MMM,YYYY')}</span>,
            },
            {
                Header: <TableColumnHeader label={'ANALYTICS_SECTION.TIME_TAKEN'} />,
                accessor: 'time_taken',
            },
            {
                Header: <TableColumnHeader label={'ANALYTICS_SECTION.TIME_ALLOTTED'} />,
                accessor: 'time_allotted',
            },
            {
                Header: <TableColumnHeader label={'ANALYTICS_SECTION.RESPONSES'} />,
                accessor: 'responses',
                disableSortBy: true,
                Cell: ({ value }) => {
                    return (
                        <Box
                            component="div"
                            className="left"
                        >
                            {value?.answers?.length > 0 ? (
                                <Button
                                    onClick={() => {
                                        setShowResponses(true);
                                        setQuestionResponses(value.answers);
                                    }}
                                    sx={{
                                        margin: 0,
                                        padding: 0,
                                        minWidth: 0,
                                        color: '#004b8d',
                                    }}
                                >
                                    {t('BUTTON.VIEW', { counts: `(${value?.answers?.length})` })}
                                    {/* ({value?.answers?.length}){' '} */}
                                </Button>
                            ) : (
                                '-'
                            )}
                        </Box>
                    );
                },
            },
        ],
        [],
    );

    useEffect(() => {
        if (currentDateRange && currentDateRange.startDate && currentDateRange.endDate) {
            getCompletionRateList(currentDateRange);
        } else {
            getCompletionRateList({
                startDate: startDate,
                endDate: endDate,
            } as IDateRange);
        }
    }, [currentDateRange.startDate, currentDateRange.endDate]);

    const getCompletionRateList = (dateRange: IDateRange) => {
        setLoading(true);
        AnalyticsService.getAnalyticsOrganizationCompletionRateList(dateRange.startDate, dateRange.endDate)
            .then((e) => {
                const {
                    data: { data: records },
                } = e;
                let completionRateNumerator = 0;
                let completionRateDenominator = 0;
                const arr: any = [];
                records?.map((procedure) => {
                    completionRateNumerator += procedure.totalCompleted;
                    completionRateDenominator += procedure.totalNumberOfTrainees.length || 0;
                    arr.push({
                        procedure: { procedureId: procedure.procedureId, workInstructionId: procedure.workInstructionId, procedureName: procedure.procedureName, wiName: procedure.workInstructionName, feedback: procedure.feedback },
                        status: procedure.status,
                        number_of_trainees: procedure.totalNumberOfTrainees.length,
                        completed: procedure.lastCompletedOn || null,
                        time_taken: secondsToHms(procedure.averageTimeTakenInSeconds) || null,
                        time_allotted: secondsToHms(procedure.estimatedTimeInSeconds) || null,
                        responses: { procedure: procedure.procedureName, answers: procedure.questions },
                    });
                });
                if (completionRateDenominator <= 0 || completionRateNumerator <= 0) {
                    setCompletionRate('0%');
                } else setCompletionRate(((completionRateNumerator / completionRateDenominator) * 100).toFixed(2) + '%');
                const sortedRecords: any = arr.sort((a, b) => b.completed - a.completed);
                setAllRecords(sortedRecords);
                setLoading(false);
            })
            .catch(({ response }) => {
                if (response) Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response?.data?.messageCode, { ns: 'error' }) || response?.data?.errorMessage);
                setLoading(false);
            });
    };
    return (
        <>
            <Box
                component={'div'}
                sx={{ display: 'flex', flexDirection: 'column', padding: '24px' }}
            >
                <Box
                    component="div"
                    sx={{ textAlign: 'left' }}
                >
                    {loading && (
                        <Skeleton
                            variant="rectangular"
                            height={'60px'}
                            width="200px"
                        />
                    )}
                    {!loading && (
                        <>
                            <Typography sx={{ fontSize: '24px' }}>
                                {completionRate} {t('ANALYTICS_SECTION.COMPLETION_RATE')}
                            </Typography>
                            <Typography sx={{ fontSize: '16px', mt: '8px', mb: '32px' }}>{t('ANALYTICS_SECTION.COMPLETION_RATE_SUBHEADING')}</Typography>
                        </>
                    )}
                </Box>
                {loading && (
                    <Box
                        component="div"
                        sx={{ display: 'flex', flexGrow: 1, mt: '32px' }}
                    >
                        <Skeleton
                            variant="rectangular"
                            height={'500px'}
                            width="100%"
                        />
                    </Box>
                )}

                {!loading && allRecords && (
                    <TableContainer
                        columns={columns}
                        data={allRecords}
                    />
                )}
                {showResponses && <QuestionResponses />}
                {showComments && (
                    <BasicDialog
                        title={`${t('ANALYTICS_SECTION.COMMENTS')} (${comments.length})`}
                        onClose={() => {
                            setShowComments(false);
                        }}
                        onOpen={showComments}
                    >
                        <Comments comments={comments} />
                    </BasicDialog>
                )}
            </Box>
        </>
    );
};
