import { Box, Breadcrumbs, Button, Card, Grid, Link, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link as RouterLink } from 'react-router-dom';
import { FormDataProps, JobRightPanelProps, JobViewList, LinkRouterProps } from './interface';
import { useTranslation } from 'react-i18next';
import JobDetail, { convertSecondsToTime, convertTimeToSeconds } from './JobDetail';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import JobProcedure from './JobProcedure';
import JobMAnagementService from '../../services/JobManagement/JobManagementService';
import { Toast } from '../Notifications/Notification';
import { getFormattedDateFromUnixTime } from '../../shared/utils/formateDate';
import { EditIcon } from '../Icons/CdsIcons';
import { getDueDateTime } from '.';
import UserService from '../../services/User/UserService';
import { useAppSelector } from '../../hooks/redux';
const NewJob: React.FC<{ isView?: boolean; jobData?: any; id?: string }> = ({ isView = false, jobData = null, id = '' }) => {
    const initialFormData: FormDataProps = {
        serialNo: '',
        materialNo: '',
        PO: '',
        salesNo: '',
        MRP: '',
        quantity: '',
        jobName: '',
        productionPlant: '',
        version: '',
        orderType: '',
        location: '',
        assignedUser: { name: '', email: '', id: '' },
        startDate: '',
        dueDate: '',
        estimatedTime: '',
        procedures: [],
        bom: [],
    };
    const location = useLocation();
    const [currentView, setCurrentView] = useState<string>(JobViewList.GENERAL_DETAILS);
    const [formData, setFormData] = useState<FormDataProps>(initialFormData);
    const [procedureList, setProcedureList] = useState<any[]>([]);
    const [errors, setErrors] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [isDisableEdit, setIsDisableEdit] = useState<boolean>(isView);
    const { currentUser } = useAppSelector((state) => state.app);
    const [assignedUserList, setAssignedUserList] = useState<any[]>([]);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        setFormData((prev) => {
            return {
                ...prev,
                serialNo: location.state?.serialNo || '',
                materialNo: location.state?.materialNo || '',
            };
        });
    }, [location.state]);
    useEffect(() => {
        let userList: any[] = [];
        const assignedUser = { id: currentUser.userId, name: currentUser.userFullName, email: currentUser.email };
        setAssignedUserList((prev) => [...prev, assignedUser]);
        if (!formData.assignedUser.name) onFormValueChange('assignedUser', assignedUser);
        UserService.getUsers()
            .then((res) => {
                res?.data?.data?.map((obj, index) => {
                    const newObj = {
                        name: obj.firstName + ' ' + obj.lastName,
                        id: obj.id,
                        email: obj.email,
                        persona: obj.personas[0].name,
                    };
                    userList.push(newObj);
                });
                userList = userList.filter((user) => user.persona.includes('Mentor Trainee'));
                setAssignedUserList((prev) => [...prev, ...userList]);
            })
            .catch(({ response }) => {});
    }, [currentUser]);
    useEffect(() => {
        if (isView && jobData) {
            setFormData((prev) => {
                return {
                    ...prev,
                    serialNo: jobData.jobId,
                    jobName: jobData.jobName,
                    assignedUser: { name: jobData.assignee.firstName + ' ' + jobData.assignee.lastName, email: jobData.assignee.email, id: jobData.assignee.id },
                    PO: jobData.purchaseOrderNumber,
                    materialNo: jobData.material,
                    quantity: jobData.quantity,
                    dueDate: getDueDateTime(jobData.dueDate),
                    estimatedTime: convertSecondsToTime(jobData.estimatedTime),
                    timeTaken: convertSecondsToTime(jobData.timeTaken),
                    completedOn: jobData.completedOn,
                    procedures: jobData.procedures,
                    location: jobData.storageLocation,
                    salesNo: jobData.salesOrderNumber,
                    MRP: jobData.MRPController,
                    productionPlant: jobData.productionPlant,
                    version: jobData.version,
                    orderType: jobData.orderType,
                    bom: [],
                    startDate: jobData.createdOn,
                };
            });
        }
    }, [isView]);
    const onViewChange = (value) => {
        setCurrentView(value);
    };
    const onFormValueChange = (key, value) => {
        setFormData((prev) => ({
            ...prev,
            [key]: prev[key] && typeof prev[key] === 'object' ? { ...prev[key], ...value } : value,
        }));
        if (key === 'procedures') {
            setFormData((prev) => ({
                ...prev,
                procedures: value,
            }));
        }
        const newErrors: any = {};
        newErrors.materialNo = formData.materialNo && '';
        newErrors.serialNo = newErrors.serialNo && '';
        newErrors.jobName = newErrors.jobName && '';
        newErrors.PO = formData.PO && '';
        newErrors.assignedUser = formData.assignedUser.name && '';
        setErrors(newErrors);
    };
    const validateForm = () => {
        const newErrors: any = {};
        if (!formData.materialNo) newErrors.materialNo = 'Material  no is required';
        if (!formData.serialNo) newErrors.serialNo = 'Job Id/ Serial No is required';
        if (!formData.jobName) newErrors.jobName = 'Job Name is required';
        if (!formData.PO) newErrors.PO = 'PO No is required';
        if (!formData.assignedUser.name) newErrors.assignedUser = 'Please select assignee';
        if (formData.procedures.length < 1) newErrors.procedure = 'Please select at least 1 procedure';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            if (currentView === JobViewList.GENERAL_DETAILS) {
                setCurrentView(JobViewList.PROCEDURES);
            } else {
                if (isView) {
                    updateJobForm();
                } else {
                    submitJobForm();
                }
            }
        } else {
            setCurrentView(JobViewList.GENERAL_DETAILS);
        }
    };
    const getFormData = () => {
        const data = {
            jobId: formData.serialNo,
            jobName: formData.jobName,
            material: formData.materialNo,
            productionPlant: formData.productionPlant,
            purchaseOrderNumber: formData.PO,
            version: formData.version,
            salesOrderNumber: formData.salesNo,
            orderType: formData.orderType,
            MRPController: formData.MRP,
            storageLocation: formData.location,
            quantity: formData.quantity,
            assignee: formData.assignedUser.id,
            // startDate: formData.startDate,
            dueDate: formData.dueDate,
            estimatedTime: convertTimeToSeconds(formData.estimatedTime) as number,
            procedures: formData.procedures,
            // BOM:[] as [] ,
        };
        return data;
    };
    const submitJobForm = () => {
        setLoading(true);
        const payload = getFormData();
        JobMAnagementService.createJobService(payload)
            .then((e) => {
                Toast.success(t('OPERATION_SUCCESS', { ns: 'success' }), t(e?.data?.messageCode, { ns: 'success' }) || e?.data?.message);
            })
            .catch(({ response }) => {
                Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response?.data?.messageCode, { ns: 'error' }) || response?.data?.errorMessage);
            })
            .finally(() => {
                setLoading(false);
                navigate('/job');
            });
    };
    const updateJobForm = () => {
        if (!id) {
            navigate('/job');
        }
        setLoading(true);
        const { jobId, material, ...newPayload } = getFormData();
        try {
            JobMAnagementService.updateJobService(id, newPayload)
                .then((e) => {
                    Toast.success(t('OPERATION_SUCCESS', { ns: 'success' }), t(e?.data?.messageCode, { ns: 'success' }) || e?.data?.message);
                })
                .catch(({ response }) => {
                    if (response?.data?.messageCode) {
                        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response?.data?.messageCode, { ns: 'error' }) || response?.data?.errorMessage);
                    }
                })
                .finally(() => {
                    setLoading(false);
                    navigate('/job');
                });
        } catch (error) {
            navigate('/job');
        }
    };
    return (
        <Box
            component={'form'}
            sx={{ bgcolor: '#F9FAFC' }}
            onSubmit={(e) => handleSubmit(e)}
        >
            <Box
                component={'div'}
                sx={{ paddingBlock: '24px', marginInline: '24px' }}
            >
                <Stack
                    direction={'row'}
                    sx={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: '24px' }}
                >
                    <JobBreadCrumbs />
                    {isView && (
                        <Button
                            variant="contained"
                            disableRipple
                            sx={{
                                height: '36px',
                                borderRadius: '100px',
                                bgcolor: '#88C23F',
                                fontSize: '14px',
                                color: 'white',
                                fontWeight: 600,
                                '&:hover, &:active, &.Mui-disabled': {
                                    bgcolor: '#88C23F',
                                    color: 'white',
                                },
                            }}
                            startIcon={<EditIcon color="#FFFFFF" />}
                            onClick={() => setIsDisableEdit(false)}
                        >
                            {t('COMMON.EDIT')}
                        </Button>
                    )}
                </Stack>

                <PanelLayout
                    leftContent={
                        <>
                            {loading ? (
                                <Skeleton
                                    variant="rounded"
                                    width={'100%'}
                                    sx={{ height: '100%' }}
                                />
                            ) : (
                                <JobLeftPanel
                                    viewList={JobViewList}
                                    onViewChange={onViewChange}
                                    currentView={currentView}
                                />
                            )}
                        </>
                    }
                    rightContent={
                        <>
                            {loading ? (
                                <Skeleton
                                    variant="rounded"
                                    width={'100%'}
                                    sx={{ height: '100%' }}
                                />
                            ) : (
                                <JobRightPanel
                                    view={currentView}
                                    formData={formData}
                                    procedureList={procedureList}
                                    onChange={onFormValueChange}
                                    setProcedureList={setProcedureList}
                                    assignedUserList={assignedUserList}
                                    errors={errors}
                                    isDisableEdit={isDisableEdit}
                                    isView={isView}
                                />
                            )}
                        </>
                    }
                />
            </Box>
            {!loading && !isDisableEdit && (
                <JobFooterAction
                    view={currentView}
                    onCreate={(e) => handleSubmit(e)}
                    formData={formData}
                    isView={isView}
                />
            )}
        </Box>
    );
};

export default NewJob;

const PanelLayout = ({ leftContent, rightContent, leftWidth = 2, rightWidth = 10 }) => {
    return (
        <Grid
            container
            columnSpacing={'16px'}
            sx={{ height: '670px' }}
        >
            <Grid
                item
                xs={leftWidth}
                sx={{ height: '100%' }}
            >
                <Card
                    elevation={0}
                    sx={{ height: '100%', border: '1px solid #E0E2EC', borderRadius: '8px', boxShadow: '0px 8px 13px #74777F0F' }}
                >
                    {leftContent}
                </Card>
            </Grid>
            <Grid
                item
                xs={rightWidth}
                sx={{ height: '100%' }}
            >
                <Card
                    elevation={0}
                    sx={{ height: '100%', overflowY: 'auto', border: '1px solid #E0E2EC', borderRadius: '8px', boxShadow: '0px 8px 13px #74777F0F' }}
                >
                    {rightContent}
                </Card>
            </Grid>
        </Grid>
    );
};

const LinkRouter = (props: LinkRouterProps) => (
    <Link
        {...props}
        component={RouterLink as any}
    />
);
const JobBreadCrumbs = () => {
    const { t } = useTranslation();
    const breadcrumbs = [
        <LinkRouter
            key="1"
            to="/job"
            sx={{ color: '#1079BF', fontSize: '14px', fontWeight: 500 }}
        >
            {t('JOB_MANAGEMENT.JOB_ORDERS_TITLE') as string}
        </LinkRouter>,
        <Link
            underline="hover"
            key="2"
            sx={{ fontSize: '28px', fontWeight: 500, color: '#333333', lineHeight: '28px' }}
        >
            {t('JOB_MANAGEMENT.ADD_NEW_JOB') as string}
        </Link>,
    ];
    return (
        <Breadcrumbs
            sx={{ display: 'flex' }}
            separator={
                <NavigateNextIcon
                    fontSize="small"
                    sx={{ color: '#CCCCCC' }}
                />
            }
            aria-label="breadcrumb"
        >
            {breadcrumbs}
        </Breadcrumbs>
    );
};
const JobLeftPanel: React.FC<{ viewList: any; onViewChange: (view: string) => void; currentView: string }> = ({ viewList, onViewChange, currentView }) => {
    const { t } = useTranslation();
    return (
        <Box sx={{ textAlign: 'start' }}>
            {Object.values(viewList).map((value, index) => (
                <Box
                    key={index}
                    component={'div'}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => onViewChange(value as string)}
                >
                    <Typography sx={{ textOverflow: 'ellipsis', wordWrap: 'break-word', padding: '16px', bgcolor: currentView === value ? '#0B79BF' : '#F5F5F5', color: currentView === value ? 'white' : '#394654', fontSize: '16px', fontWeight: 400 }}>{t('JOB_MANAGEMENT.' + value)}</Typography>
                </Box>
            ))}
        </Box>
    );
};
const JobRightPanel: React.FC<JobRightPanelProps> = ({ view, formData, onChange, procedureList, setProcedureList, errors, isDisableEdit, isView, assignedUserList }) => {
    const renderView = () => {
        switch (view) {
            case JobViewList.GENERAL_DETAILS:
                return (
                    <JobDetail
                        formData={formData}
                        onChange={onChange}
                        errors={errors}
                        isDisableEdit={isDisableEdit}
                        assignedUserList={assignedUserList}
                    />
                );
            case JobViewList.PROCEDURES:
                return (
                    <JobProcedure
                        data={formData}
                        onChange={onChange}
                        procedureList={procedureList}
                        setProcedureList={setProcedureList}
                        isView={isView}
                        isDisableEdit={isDisableEdit}
                    />
                );
        }
    };
    return (
        <Box
            component={'div'}
            sx={{ padding: view === JobViewList.GENERAL_DETAILS ? '24px' : '16px' }}
        >
            {renderView()}
        </Box>
    );
};
const JobFooterAction = ({ view, onCreate, formData, isView }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const onClickNext = (e) => {
        onCreate(e);
    };
    const checkValidation = () => {
        const newErrors: any = {};
        if (!formData.materialNo) newErrors.materialNo = 'Material  no is required';
        if (!formData.serialNo) newErrors.serialNo = 'Job Id/ Serial No is required';
        if (!formData.jobName) newErrors.jobName = 'Job Name is required';
        if (!formData.PO) newErrors.PO = 'PO No is required';
        if (!formData.assignedUser.name) newErrors.assignedUser = 'Please select assignee';
        if (formData.procedures.length < 1) newErrors.procedure = 'Please select at least 1 procedure';
        const errorMessages = Object.values(newErrors);
        return { isValid: Object.keys(newErrors).length === 0, msg: errorMessages.length > 0 ? errorMessages[0] : undefined };
    };
    return (
        <Stack
            direction={'row'}
            sx={{ padding: '12px', width: '100%', alignItems: 'center', justifyContent: 'center', gap: '16px', bgcolor: 'white', border: '1px solid #E0E2EC' }}
        >
            <Button
                variant="outlined"
                size="small"
                sx={{
                    height: '40px',
                    borderRadius: '100px',
                    border: '2px solid #88C23F',
                    color: '#88C23F',
                    fontSize: '14px',
                    fontWeight: 600,
                    '&:hover, &:active, &.Mui-disabled': {
                        border: '2px solid #88C23F',
                    },
                }}
                onClick={() => {
                    navigate('/job');
                }}
            >
                {t('BUTTON.CANCEL')}
            </Button>
            <Tooltip
                title={checkValidation().msg as string}
                disableHoverListener={!checkValidation().msg}
                disableFocusListener={!checkValidation().msg}
            >
                <Box component={'div'}>
                    <Button
                        variant="contained"
                        size="small"
                        type={view === JobViewList.GENERAL_DETAILS ? 'submit' : 'button'}
                        sx={{
                            height: '40px',
                            borderRadius: '100px',
                            bgcolor: '#88C23F',
                            color: 'white',
                            fontSize: '14px',
                            fontWeight: 600,
                            border: '1px solid #88C23F',
                            '&:hover, &:active, &.Mui-disabled': {
                                color: 'white',
                                bgcolor: '#88C23F',
                                border: '1px solid #88C23F',
                            },
                            '&.Mui-disabled': {
                                opacity: 0.5,
                            },
                        }}
                        disabled={!checkValidation().isValid}
                        onClick={(e) => view !== JobViewList.GENERAL_DETAILS && onClickNext(e)}
                    >
                        {t(view === JobViewList.GENERAL_DETAILS ? 'BUTTON.NEXT' : isView ? 'BUTTON.SAVE' : 'BUTTON.CREATE')}
                    </Button>
                </Box>
            </Tooltip>
        </Stack>
    );
};
