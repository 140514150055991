import { Avatar, Box, ClickAwayListener, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputBase, MenuItem, Popover, Select, Stack, styled, Tab, Tabs, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { FormDataProps } from './interface';
import { FormField, FormLayout } from '../FormItem/FormItems';
import { useTranslation } from 'react-i18next';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { EditIcon } from '../Icons/CdsIcons';
import moment from 'moment';
import UserService from '../../services/User/UserService';
import { Toast } from '../Notifications/Notification';
import DateComponent from './DateComponent';
import PauseOutlinedIcon from '@mui/icons-material/PauseOutlined';
import { useAppSelector } from '../../hooks/redux';
const StyledTabs = styled(Tabs)(({ theme }) => ({
    minHeight: '38px',
    maxHeight: '38px',
    '& .MuiTab-root': {
        textTransform: 'none',
        fontWeight: 600,
        fontSize: '16px',
    },
    '& .MuiTabs-indicator': {
        bottom: '0px',
        backgroundColor: '#1079BF',
    },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    minHeight: '38px',
    maxHeight: '38px',
    '&.Mui-selected': {
        color: '#1079BF',
        opacity: 1,
    },
    '&:not(.Mui-selected)': {
        color: '#7fa5c6',
        opacity: 1,
    },
    '& .MuiTab-wrapper': {
        borderBottom: '2px solid transparent',
    },
    '&.Mui-selected .MuiTab-wrapper': {
        borderBottom: '2px solid #1079BF',
    },
}));
export const CustomSelect = styled(InputBase)(({ theme }) => ({
    border: 'none',
    borderRadius: '5px',
    padding: '0 0',
    textAlign: 'start',
    '& .MuiInputBase-input': {
        paddingBottom: '2px',
        paddingTop: '2px',
        fontSize: '14px',
        fontWeight: '600',
        color: '#333333',
        opacity: 1,
    },
}));
interface JobDetailsProps {
    formData: FormDataProps;
    onChange: (key: string, value: string) => void;
    errors: any;
    isDisableEdit: boolean;
    assignedUserList: any[];
}
const JobDetail: React.FC<JobDetailsProps> = ({ formData, onChange, errors, isDisableEdit, assignedUserList }) => {
    const [tabValue, setTabValue] = useState<number>(0);
    const { t } = useTranslation();
    const handleChange = (e, value) => {
        setTabValue(value);
    };
    return (
        <Stack gap={'16px'}>
            <StyledTabs
                value={tabValue}
                onChange={handleChange}
                aria-label="basic tabs"
            >
                <StyledTab
                    label={t('USER_GROUP.DETAILS')}
                    value={0}
                />
                {/* <StyledTab
                    label="BOM"
                    value={1}
                /> */}
            </StyledTabs>
            <TabPanel
                value={tabValue}
                index={0}
            >
                <Grid
                    container
                    columnSpacing={'24px'}
                    sx={{ alignItems: 'start', overflowY: 'auto' }}
                >
                    <Grid
                        item
                        xs={8}
                    >
                        <JobForm
                            data={formData}
                            onChange={onChange}
                            errors={errors}
                            isDisableEdit={isDisableEdit}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={4}
                    >
                        <Box sx={{ minHeight: '420px', height: '100%', bgcolor: '#F5F5F5', padding: '16p', borderRadius: '10px' }}>
                            <AssignDetails
                                data={formData}
                                onChange={onChange}
                                errors={errors}
                                isDisableEdit={isDisableEdit}
                                assignedUserList={assignedUserList}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </TabPanel>
            {/* <TabPanel
                value={tabValue}
                index={1}
            >
                <Box>BOM Content</Box>
            </TabPanel> */}
        </Stack>
    );
};

export default JobDetail;

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <Box
            component={'div'}
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <Box component={'div'}>{children}</Box>}
        </Box>
    );
}
interface JobFormProps {
    data: FormDataProps;
    onChange: (key, value) => void;
    errors;
    isDisableEdit;
}
const JobForm: React.FC<JobFormProps> = ({ data, onChange, errors, isDisableEdit }) => {
    const { t } = useTranslation();
    const MAX_NUMBER_FIELD_LENGTH = 20;
    return (
        <FormLayout>
            <FormField
                value={data.serialNo}
                label={t('JOB_MANAGEMENT.SERIAL_NO')}
                required
                placeholder={''}
                type="text"
                onChange={(e) => onChange('serialNo', e.target.value)}
                disabled={true}
                error={errors.serialNo}
            />
            <FormField
                value={data.jobName}
                label={t('JOB_MANAGEMENT.JOB_NAME')}
                required
                placeholder={''}
                type="text"
                onChange={(e) => onChange('jobName', e.target.value)}
                disabled={isDisableEdit}
                error={errors.jobName}
            />
            <FormField
                value={data.materialNo}
                label={t('JOB_MANAGEMENT.MATERIAL')}
                required
                placeholder={''}
                type="text"
                onChange={(e) => onChange('materialNo', e.target.value)}
                disabled={true}
                error={errors.materialNo}
            />
            <FormField
                value={data.productionPlant}
                label={t('JOB_MANAGEMENT.PRODUCTION_PLANT')}
                placeholder={''}
                type="text"
                onChange={(e) => onChange('productionPlant', e.target.value)}
                disabled={isDisableEdit}
            />
            <FormField
                value={data.PO}
                label={t('JOB_MANAGEMENT.PO_NO')}
                required
                placeholder={''}
                type="number"
                onChange={(e) => {
                    if (e.target.value.length < MAX_NUMBER_FIELD_LENGTH) {
                        onChange('PO', e.target.value);
                    }
                }}
                disabled={isDisableEdit}
                error={errors.PO}
            />
            <FormField
                value={data.version}
                label={t('COMMON.VERSION')}
                placeholder={''}
                type="text"
                onChange={(e) => onChange('version', e.target.value)}
                disabled={isDisableEdit}
            />
            <FormField
                value={data.salesNo}
                label={t('JOB_MANAGEMENT.SALES_ORDER_NO')}
                placeholder={''}
                type="number"
                onChange={(e) => {
                    if (e.target.value.length < MAX_NUMBER_FIELD_LENGTH) {
                        onChange('salesNo', e.target.value);
                    }
                }}
                disabled={isDisableEdit}
            />
            <FormField
                value={data.orderType}
                label={t('JOB_MANAGEMENT.ORDER_TYPE')}
                placeholder={''}
                type="text"
                onChange={(e) => onChange('orderType', e.target.value)}
                disabled={isDisableEdit}
            />
            <FormField
                value={data.MRP}
                label={t('JOB_MANAGEMENT.MRP_CONTROLLER')}
                placeholder={''}
                type="text"
                onChange={(e) => onChange('MRP', e.target.value)}
                disabled={isDisableEdit}
            />
            <FormField
                value={data.location}
                label={t('JOB_MANAGEMENT.STORAGE_LOCATION')}
                placeholder={''}
                type="text"
                onChange={(e) => onChange('location', e.target.value)}
                disabled={isDisableEdit}
            />
            <FormField
                value={data.quantity}
                label={t('JOB_MANAGEMENT.QUANTITY')}
                placeholder={''}
                type="text"
                onChange={(e) => onChange('quantity', e.target.value)}
                disabled={isDisableEdit}
            />
        </FormLayout>
    );
};

interface AssignDetailsProps {
    data: FormDataProps;
    onChange: (key, value) => void;
    errors: any;
    isDisableEdit: boolean;
    assignedUserList: any[];
}
const AssignDetails: React.FC<AssignDetailsProps> = ({ data, onChange, errors, isDisableEdit, assignedUserList }) => {
    const { t } = useTranslation();
    const [startDateOpen, setStartOpen] = useState<boolean>(false);
    const [dueOpen, setDueDateOpen] = useState<boolean>(false);

    const [isEditable, setEditable] = useState<boolean>(false);
    useEffect(() => {
        const today = moment().format('DD-MM-YYYY');
        if (!data.startDate) onChange('startDate', today);
        if (!data.dueDate) onChange('dueDate', today);
        if (!data.estimatedTime) onChange('estimatedTime', convertSecondsToTime(0));
    }, []);

    const onEstimatedTimeChange = (e, isKeyEnter) => {
        if (isKeyEnter && e.key !== 'Enter') return;
        const value = e.target.value;
        const regex = /^(\d{0,3})h (\d{0,2})m$/;
        if (regex.test(value)) {
            onChange('estimatedTime', e.target.value);
        } else {
            onChange('estimatedTime', '0h 0m');
            console.error('in valid format');
        }
        if (isKeyEnter && e.key === 'Enter') {
            setEditable(false);
        }
    };
    const handleAssignedUser = (id) => {
        const trainee = assignedUserList?.find((user) => user.id === id);
        onChange('assignedUser', trainee);
    };
    return (
        <Stack
            gap={'10px'}
            sx={{ padding: '16px' }}
        >
            {/* {isDisableEdit && (
                <Stack
                    direction={'row'}
                    gap={'8px'}
                    alignItems={'center'}
                >
                    <Box
                        component={'div'}
                        sx={{ width: '16px', height: '16px', borderRadius: '50%', bgcolor: '#008F31' }}
                    ></Box>
                    <Typography sx={{ fontSize: '16px', fontWeight: '500', color: 'black' }}>On time</Typography>
                </Stack>
            )} */}
            <Typography sx={{ fontSize: '16px', fontWeight: 500, textAlign: 'start' }}>{t('JOB_MANAGEMENT.ASSIGNED_TO')}</Typography>
            <Stack
                direction={'row'}
                gap={'10px'}
                sx={{ alignItems: 'center' }}
            >
                <Avatar
                    sx={{
                        width: 24,
                        height: 24,
                        bgcolor: '#F15C2F',
                        fontSize: '14px',
                        fontWeight: 500,
                        alignSelf: 'start',
                        '& .MuiAvatar-fallback': {
                            display: 'none', // Hide the default icon
                        },
                    }}
                >
                    {data?.assignedUser ? getInitials(data?.assignedUser.name) : ''}
                </Avatar>
                {isDisableEdit && <Typography sx={{ fontSize: '14px', fontWeight: 600, color: '#333333' }}>{data?.assignedUser?.name}</Typography>}
                {!isDisableEdit && (
                    <FormControl>
                        <Select
                            value={data?.assignedUser?.id || ''}
                            displayEmpty
                            onChange={(e) => handleAssignedUser(e.target.value)}
                            input={<CustomSelect />}
                            sx={{
                                minWidth: 'fit-content',
                                background: '#F5F5F5',
                            }}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        maxHeight: 350,
                                        overflow: 'auto',
                                    },
                                },
                            }}
                            disabled={isDisableEdit}
                        >
                            <MenuItem
                                value={''}
                                sx={{ display: 'none' }}
                            >
                                {t('JOB_MANAGEMENT.SELECT_ASSIGNEE')}
                            </MenuItem>
                            {assignedUserList?.map((user, index) => {
                                return (
                                    <MenuItem
                                        key={index}
                                        value={user.id}
                                    >
                                        {user.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        {errors.assignedUser && <FormHelperText sx={{ color: 'red', padding: 0, margin: 0 }}>{errors.assignedUser ? errors.assignedUser : ''}</FormHelperText>}
                    </FormControl>
                )}
            </Stack>
            {/* {isDisableEdit && (
                <StatusBar
                    status={'In progress'}
                    progress={'0%'}
                />
            )} */}
            <Stack
                component={'div'}
                direction={'row'}
            >
                {/* <DateComponent
                    label={t('COMMON.START_DATE')}
                    closePicker={() => setStartOpen(false)}
                    data={data}
                    value={data?.startDate}
                    iconClickHandler={() => setStartOpen(true)}
                    onDateChange={(value) => onChange('startDate', value)}
                    open={startDateOpen}
                    pickerType={'start'}
                /> */}
                <DateComponent
                    label={t('COMMON.DUE_DATE')}
                    closePicker={() => setDueDateOpen(false)}
                    data={data}
                    value={data?.dueDate}
                    iconClickHandler={() => !isDisableEdit && setDueDateOpen(true)}
                    onDateChange={(value) => onChange('dueDate', value)}
                    open={dueOpen}
                    pickerType={'due'}
                />
            </Stack>
            <Stack
                direction={'row'}
                gap={'10px'}
            >
                <Stack
                    gap={'10px'}
                    width={'100%'}
                >
                    <Stack
                        direction={'row'}
                        gap={'10px'}
                    >
                        <IconButton sx={{ padding: 0 }}>
                            <AccessTimeOutlinedIcon sx={{ color: '#666666' }} />
                        </IconButton>
                        <Typography sx={{ color: '#666666', fontSize: '16px', fontWeight: 500 }}>{t('COMMON.ESTIMATED_TIME')}</Typography>
                    </Stack>

                    <ClickAwayListener onClickAway={() => setEditable(false)}>
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                            gap={'10px'}
                        >
                            {isEditable ? (
                                <TextField
                                    value={data.estimatedTime}
                                    onChange={(e) => onEstimatedTimeChange(e, false)}
                                    onKeyDown={(e) => onEstimatedTimeChange(e, true)}
                                    variant="outlined"
                                    size="small"
                                    inputProps={{
                                        maxLength: 10,
                                    }}
                                    disabled={!isEditable}
                                    placeholder="0h 0m"
                                    sx={{
                                        minWidth: 'fit-content',
                                        '& .MuiOutlinedInput-root': {
                                            maxWidth: '100px',
                                            height: '30px',
                                            maxHeight: '30px',
                                            bgcolor: isEditable ? '#FFFFFF' : '#F5F5F5',
                                            color: '#333333',
                                            fontSize: '18px',
                                            fontWeight: 500,
                                            '& fieldset': {
                                                borderRadius: '4px',
                                                border: '0px solid #CCCCCC',
                                            },
                                            '& input': {
                                                padding: '4px',
                                                margin: 0,
                                            },
                                        },
                                    }}
                                />
                            ) : (
                                <Typography sx={{ fontSize: '18px', fontWeight: 500, color: '#333333' }}>{data.estimatedTime}</Typography>
                            )}

                            <IconButton
                                sx={{ padding: 0 }}
                                onClick={() => !isDisableEdit && setEditable(true)}
                            >
                                <EditIcon />
                            </IconButton>
                        </Stack>
                    </ClickAwayListener>
                </Stack>
                {/* {isDisableEdit && (
                    <Stack
                        gap={'10px'}
                        width={'100%'}
                    >
                        <Stack
                            direction={'row'}
                            gap={'10px'}
                        >
                            <IconButton sx={{ padding: 0 }}>
                                <AccessTimeOutlinedIcon sx={{ color: '#666666' }} />
                            </IconButton>
                            <Typography sx={{ color: '#666666', fontSize: '16px', fontWeight: 500 }}>{t('JOB_MANAGEMENT.REMAINING_TIME')}</Typography>
                        </Stack>
                        <Typography sx={{ fontSize: '18px', fontWeight: 500, color: '#1079BF', textAlign: 'left' }}>{data.estimatedTime}</Typography>
                    </Stack>
                )} */}
            </Stack>
        </Stack>
    );
};

const StatusBar = ({ status, progress }) => {
    const [progressWidth, setProgressWidth] = useState<string>('50%');
    return (
        <Stack
            sx={{ paddingBlock: '10px', paddingRight: '16.2px' }}
            gap={'6px'}
        >
            <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
            >
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    gap={'5.4px'}
                >
                    <Box
                        component={'div'}
                        sx={{ display: 'inline-flex', width: '27px', height: '27px', bgcolor: '#F15C2F', borderRadius: '50%', alignItems: 'center', justifyContent: 'center' }}
                    >
                        <PauseOutlinedIcon sx={{ color: 'white', width: '20px', height: '20px' }} />
                    </Box>
                    <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#333333' }}>{status}</Typography>
                </Stack>
                <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#333333' }}>{progress}</Typography>
            </Stack>
            <Box
                component={'div'}
                sx={{ bgcolor: '#CCCCCC', width: '100%', height: '8.1px', borderRadius: '13.5px' }}
            >
                <Box
                    component={'div'}
                    sx={{ bgcolor: '#F79D82', width: `${progressWidth}`, height: '8.1px', borderRadius: '13.5px' }}
                ></Box>
            </Box>
        </Stack>
    );
};
export const convertSecondsToTime = (seconds) => {
    if (typeof seconds !== 'number' || seconds < 0) return 'Invalid input';
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
};
export const convertTimeToSeconds = (timeStr) => {
    const timeRegex = /(\d+)h\s*(\d*)m?/; // Regex to match hours and optional minutes
    const match = timeStr.match(timeRegex);
    if (!match) return 'Invalid input format';
    const hours = parseInt(match[1], 10);
    const minutes = match[2] ? parseInt(match[2], 10) : 0;
    return hours * 3600 + minutes * 60;
};
const getInitials = (name) => {
    const words = name.split(' '); // Split the name into words
    const initials = words
        .slice(0, 2)
        .map((word) => word.charAt(0).toUpperCase())
        .join(''); // Take first letter of each word
    return initials;
};
