import { Box, Typography, TableContainer, Table, TableBody, TableRow, TableCell, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './IntroStepContainer.scss';
import { Fragment, useEffect, useState } from 'react';
import StepsPpeList from './StepsPpeList';
import { secondsToHms } from '../Analytics/ProcedureReports/TraineeTime';
import { getFormattedDateFromUnixTime } from '../../shared/utils/formateDate';
import TrainerService from '../../services/trainer/TrainerService';
import { useCanvasState } from '../../state/canvasState';
import { useLocation } from 'react-router-dom';

interface props {
    introStep?: any;
    procedure?: any;
    workInstructionName?: string;
}

export const IntroStepContainer = ({ introStep, procedure, workInstructionName }: props) => {
    const { t } = useTranslation();
    const canvasState = useCanvasState();
    const theme = useTheme();
    const { search: searchQuery } = useLocation();
    const [ppeList, setPpeList] = useState<any[]>([]);
    const [page, setPage] = useState<{ page: number; pageSize: number }>({ page: 1, pageSize: 21 });
    const [loading, setLoading] = useState(false);
    const [infiniteLoading, setInfiniteLoading] = useState(false);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const query = new URLSearchParams(searchQuery);
    const partnerAccessToken = query.get('access');

    const rows = [
        {
            name: t('ADD_WI.PROCEDURE_NAME'),
            value: procedure.procedureName,
        },
        {
            name: t('ADD_WI.WORK_INSTRUCTION_NAME'),
            value: workInstructionName,
        },
        {
            name: t('ADD_WI.CYCLE_TIME'),
            value: secondsToHms(procedure.estimated_time) || '0s',
        },
        {
            name: t('PROCEDURE_HISTORY.PUBLISHED_BY'),
            value: procedure.publisher,
        },
        {
            name: t('PROCEDURE_HISTORY.PUBLISHED_DATE'),
            value: procedure.publishedOn ? getFormattedDateFromUnixTime(procedure.publishedOn) : null,
        },
        {
            name: t('PROCEDURE.CARD_VERSION'),
            value: procedure.isPublished ? procedure.version : t('ANALYTICS_SECTION.DRAFT'),
        },
    ];
    const getPpeList = () => {
        if (page.page === 1) setLoading(true);
        else {
            setInfiniteLoading(true);
        }
        TrainerService.getPPEOfProcedureV2(canvasState.procedureId, partnerAccessToken, page)
            .then((response) => {
                const { data, currentPage, totalPages } = response?.data?.data;
                setHasMore(currentPage < totalPages);
                setPpeList((prev) => [...prev, ...data]);
            })
            .catch((e) => {})
            .finally(() => {
                setLoading(false);
                setInfiniteLoading(false);
            });
    };
    useEffect(() => {
        getPpeList();
    }, [page.page]);
    const loadNext = () => {
        if (hasMore) {
            setInfiniteLoading(true);
            setPage((prev) => ({ ...prev, page: prev.page + 1 }));
        }
    };
    return (
        <Box
            component="div"
            className="intro-container"
        >
            {introStep === 1 ? (
                <>
                    <TableContainer className="intro-table">
                        <Table aria-label="simple table">
                            <TableBody>
                                {rows.map((row) => (
                                    <Fragment key={row.name}>
                                        {(row.value || row.value === 0) && (
                                            <TableRow>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={{ color: theme.palette.primary.main, fontSize: '14px', border: '1px solid rgba(28, 29, 31, 0.10)', width: '30%' }}
                                                >
                                                    {row.name}
                                                </TableCell>
                                                <TableCell
                                                    sx={{ fontSize: '14px', border: '1px solid rgba(28, 29, 31, 0.10)', width: '70%' }}
                                                    align="left"
                                                >
                                                    {row.value}
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            ) : (
                <>
                    <Typography
                        className="intro-section-text"
                        sx={{ mb: '8px' }}
                    >
                        {procedure?.ppeTitle ? `${procedure.ppeTitle}` : `Safety manual and requirements`}
                    </Typography>
                    <Typography className="section-text">{procedure?.ppeDescription ? `${procedure.ppeDescription}` : `${t('3D_VIEWER.SAFETY_MANUAL')}. ${t('3D_VIEWER.WEAR_SAFETY_EQUIPMENT')}.`}</Typography>
                    <StepsPpeList
                        ppeList={ppeList}
                        loading={loading}
                        setLoading={setLoading}
                        infiniteLoading={infiniteLoading}
                        loadNext={loadNext}
                        hasMore={hasMore}
                    />
                </>
            )}
        </Box>
    );
};
