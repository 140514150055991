import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { Box, Button, Grid, IconButton, Menu, MenuItem, Modal, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState, useRef, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
// import CameraController from '../../../components/Canvas/Camera/CameraController';
// import CanvasScene from '../../../components/Canvas/CanvasScene/CanvasScene';
import AssemblyBar from '../../../components/ControlBars/Assembly/AssemblyBar/AssemblyBar';
import { DialogClose, LeftArrow } from '../../../components/Icons/CdsIcons';
import LinearWithValueLabel from '../../../components/LinearProgressWithLabel/LinearProgressWithLabel';
import Loading from '../../../components/Loading/Loading';
import { ScanQR } from '../../../components/Trainer/Dialog/ScanQR/ScanQR';
import { AssemblyState } from '../../../entities/AssemblyState';
import { IProcedures } from '../../../entities/Trainee/MockTrainee';
import { MediaReference } from '../../../entities/Trainee/WorkInstructions';
import { environment } from '../../../environment/environment';
import usePpeList from '../../../hooks/ppeList';
import TutorialService from '../../../services/trainee/TraineeService';
import { mediaWidth } from '../../../shared/appSettings';
import { useCanvasState } from '../../../state/canvasState';
import './AssemblyInstructions.scss';
import { useDocumentTitle } from '../../../App';
import MediaContainer from '../../../components/MediaContainer/MediaContainer';
import { DemoDialog } from '../../../components/Dialog/DemoDialog/DemoDialog';
import useTour from '../../../hooks/tour';
import TrainerService from '../../../services/trainer/TrainerService';
import { createObjectUrlFromDataUrl } from '../../../shared/utils/mediaUtilities';
import { Toast } from '../../../components/Notifications/Notification';
import TraineeService from '../../../services/trainee/TraineeService';
import { IntroStepContainer } from '../../../components/IntroStepContainer/IntroStepContainer';
import { Viewer3D } from 'cds-component-library';
import CommonViewerWrapper from '../../../components/CommonViewerWrapper/CommonViewerWrapper';
import FileHelper from '../../../services/FileManager/FileHelper';
import { ArrowBack } from '@mui/icons-material';
interface Props {
  isTrainer?: boolean;
  bearerToken: string | null;
}
export class MeshToolset {
  editorState: 'color' | 'translate' | 'rotate' | 'scale' | null = null;
  transformState: number = 0;
  transformStateOptions = ['translate', 'rotate', 'scale'];
}

const AssemblyInstructions: React.FC<Props> = (props) => {
  const canvasState = useCanvasState();
  const mediaReferencesEnabled = useCanvasState((state) => state.mediaReferencesEnabled);

  const [workInstructionName, setWorkInstructionName] = useState('');
  const [explode, setExplode] = useState<boolean>(true);
  const [zoom, setZoom] = useState<number>(100);
  const [assemblyState, setAssemblyState] = useState<AssemblyState>(new AssemblyState());
  const [procedure, setProcedure] = useState<IProcedures>();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const { companyId, id, workInstructionId } = useParams<string>();
  const [seconds, setSeconds] = useState<number>(0);
  const [progressPercentage, setProgressPercentage] = React.useState(0);
  const [loading, setLoading] = useState<boolean>(true);
  const matches = useMediaQuery(mediaWidth);
  const [containerHeight, setContainerHeight] = useState<string>('');
  const [hotSpotMedia, setHotSpotMedia] = useState<MediaReference | null>(null);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [isDataReady, setIsDataReady] = useState(false);
  const [searchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openScanDialog, setOpenScanDialog] = React.useState<boolean>(false);
  const [timerLoading, setTimerLoading] = React.useState(false);
  const [stepMediaAssociation, setStepMediaAssociation] = useState<MediaReference[]>([]);
  const [showDemo, setShowDemo] = useState<Boolean>(false);
  const [demoStep, setDemoStep] = useState(1);
  const { showIntro, introStep, setIntroStep } = usePpeList();

  const partnerPath = new RegExp('/partner');
  const location = useLocation();
  const partnerAccess: any = partnerPath.test(location.pathname);

  const { search: searchQuery } = useLocation();
  const query = new URLSearchParams(searchQuery);
  const access = query.get('access');
  const wiType = query.get('workInstructionType');
  const isRevisitRequest = query.get('isRevisitRequest');
  const isViewCommentString = query.get('isViewComment');
  const [isViewComment, setIsViewComment] = useState<boolean>(isViewCommentString === 'true' ? true : false);
  const isDelete = query.get('isDelete');
  const [cycleTime, setCycleTime] = React.useState<any>();
  const [elapsedSecondsCount, setElapsedSecondsCount] = React.useState(0);
  const [arrowClick, setArrowClick] = React.useState<boolean>(false);
  const [timeAllotedForStep, setTimeAllotedForStep] = React.useState<number>(0);
  const cycleTimeRef: any = useRef(cycleTime);
  cycleTimeRef.current = cycleTime;
  const [timer, setTimer] = useState<any>([]);
  const [isTemplateWI, setIsTemplateWI] = useState<boolean>(false);

  const updateElapsedTimeAndCounter = async (count) => {
    const stepIndex = assemblyState.currentStep - 1;
    try {
      if (workInstructionId && procedure?.procedureId) {
        await TraineeService.updateElapsedTime(workInstructionId, procedure?.procedureId, stepIndex, count);
      }
    } catch (error) {
      console.error('Error updating elapsed time:', error);
    }
  };

  useEffect(() => {
    updateElapsedTimeAndCounter(elapsedSecondsCount);
  }, [elapsedSecondsCount]);

  const startCountDown = () => {
    let timerTemp;
    while (timer.length > 0) {
      clearInterval(timer[timer.length - 1]);
      timer.pop();
    }
    timerTemp = setInterval(() => {
      if (cycleTimeRef.current !== undefined) {
        const newTime = cycleTimeRef.current - 1;
        if (newTime % 5 === 0) {
          setElapsedSecondsCount((prevCount) => prevCount + 5);
        }
        setCycleTime(newTime);
      }
    }, 1000);
    setTimer([...timer, timerTemp]);
  };
  useEffect(() => {
    return () => {
      while (timer.length > 0) {
        clearInterval(timer[timer.length - 1]);
        timer.pop();
      }
    };
  }, []);

  useEffect(() => {
    if (!props.isTrainer && !isViewComment) {
      const stepIndex = assemblyState.currentStep - 1;
      const hours = canvasState?.workInstructions?.cardsData[stepIndex].stepCounterHours;
      const minutes = canvasState?.workInstructions?.cardsData[stepIndex].stepCounterMinutes;
      const seconds = canvasState?.workInstructions?.cardsData[stepIndex].stepCounterSeconds;
      const timeLeftFromApi = ((hours ? hours * 3600 : 0) + (minutes ? minutes * 60 : 0) + (seconds ? seconds : 0)).toString();
      const initialTimeInSeconds = parseInt(timeLeftFromApi, 10);
      setTimeAllotedForStep(initialTimeInSeconds);

      while (timer.length > 0) {
        clearInterval(timer[timer.length - 1]);
        timer.pop();
      }

      if (workInstructionId && procedure?.procedureId && !showIntro && introStep >= 2) {
        setTimerLoading(true);
        TraineeService.getElapsedTime(workInstructionId, procedure?.procedureId, stepIndex).then((response) => {
          setTimerLoading(false);
          const elapsedSeconds: number = response?.data?.data;
          setElapsedSecondsCount(elapsedSeconds);
          setCycleTime(initialTimeInSeconds - elapsedSeconds);
          startCountDown();
        });
      }
    }
  }, [assemblyState.currentStep, showIntro]);

  const formatTime = (timeInSeconds) => {
    const isNegative = timeInSeconds < 0;
    const absoluteTime = Math.abs(timeInSeconds);

    const hours = Math.floor(absoluteTime / 3600);
    const minutes = Math.floor((absoluteTime % 3600) / 60);
    const seconds = absoluteTime % 60;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${isNegative ? '-' : ''}${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  const [gltfId, setGltfId] = useState<string>('');
  useDocumentTitle('CDS Mentor 3D Viewer');

  useEffect(() => {
    setProcedureDetails();
  }, []);

  const setProcedureDetails = () => {
    TraineeService.getWorkInstructionsById(workInstructionId, access)
      .then((response) => {
        setIsTemplateWI(!!response.data.data.isTemplate)
        setWorkInstructionName(response.data.data.workInstructionName);
      })
      .catch(({ response }) => {
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
      });
  };

  useEffect(() => {
    if ((id && workInstructionId) || partnerAccess) {
      let versionId = searchParams.get('version');
      setLoading(true);
      if (props.isTrainer || !isViewComment) {
        setIntroStep(1);
      }
      TutorialService.getTasks(workInstructionId, id, versionId, access,props.isTrainer,isViewComment)
      .then((e) => {
        const wi = e.data.data;
        let mapFileIdToData: Record<string, string> = {};
        let uniqueFileIds: any;
        let featuresTools: any;
        const {
          features: { tools },
        } = wi;
        if (wi.fileInfo?.cad?.models[0]?.gltfId) {
          setGltfId(wi.fileInfo.cad.models[0].gltfId);
        }
        if (tools) {
          featuresTools = Object.keys(tools).map((key) => [key, tools[key]]) as any;
          // TODO: need to revisit when BE returns with img and title
          featuresTools = undefined;
        }
        useCanvasState?.setState({ procedureId: wi?.procedureId });

        uniqueFileIds = new Set();
        const referencesMap = {};
        wi?.features?.mediaReferences?.forEach((m) => {
          uniqueFileIds.add(m?.imageEditorImageId || m?.mentorID)
          referencesMap[m?.imageEditorImageId || m?.mentorID] = m;
        })
        wi?.workInstructions?.cardsData?.flatMap((subArray) =>
          subArray?.mediaAssociation?.forEach((aMediaAssociation) => {
            uniqueFileIds.add(aMediaAssociation?.imageEditorImageId || aMediaAssociation?.mentorID);
            referencesMap[aMediaAssociation?.imageEditorImageId || aMediaAssociation?.mentorID] = aMediaAssociation;
          })
        );

        const references: MediaReference[] = Object.values(referencesMap);
        uniqueFileIds = Array.from(uniqueFileIds);
        
        const updateFileData = async () => {
          await Promise.all(
            uniqueFileIds.map(async (fileId) => {
              if (referencesMap[fileId].isBlobUpload) {
                const fileType = referencesMap[fileId].fileType;
                if (fileType == 'application/pdf') {
                  await FileHelper.getObjectUrlByFileId(fileId, fileType).then((objectUrl) => {
                    mapFileIdToData[`${fileId}_dataUrl`] = objectUrl;
                    mapFileIdToData[`${fileId}_objectUrl`] = objectUrl;
                  });
                } else {
                  let fileIdToUse = referencesMap[fileId].imageEditorImageId || referencesMap[fileId].mentorID;
                  mapFileIdToData[`${fileId}_dataUrl`] = `${environment.AppConfigs.fileManagerApi}/v1/files/${fileIdToUse}?cache=true`;
                  mapFileIdToData[`${fileId}_objectUrl`] = `${environment.AppConfigs.fileManagerApi}/v1/files/${fileIdToUse}?cache=true`;
                }
              } else {
                await axios.get(`${environment.AppConfigs.fileManagerApi}/v1/files/${fileId}?cache=true`).then((res) => {
                  mapFileIdToData[`${fileId}_dataUrl`] = res.data as string;
                  mapFileIdToData[`${fileId}_objectUrl`] = createObjectUrlFromDataUrl(res.data as string);
                });
              }
              const globalMediaToUpdate = references.find((item) => item.imageEditorImageId === fileId || item.mentorID === fileId);

              if (globalMediaToUpdate) {
                globalMediaToUpdate.dataUrl = mapFileIdToData[`${fileId}_dataUrl`];
                globalMediaToUpdate.objectUrl = mapFileIdToData[`${fileId}_objectUrl`];
              }

               wi.workInstructions.cardsData.map((cardData) => {
                 if(wiType === '2d'){
                    cardData.is2dStep = true;
                 }
                if (Array.isArray(cardData.mediaAssociation)) {
                  let stepMediaToUpdate = cardData.mediaAssociation.find((item, index) => item.imageEditorImageId === fileId || item.mentorID === fileId);
                  if (stepMediaToUpdate) {
                    stepMediaToUpdate.dataUrl = mapFileIdToData[`${fileId}_dataUrl`];
                    stepMediaToUpdate.objectUrl = mapFileIdToData[`${fileId}_objectUrl`];
                  }
                }
              });
              return;
            }),
          );
        };
        updateFileData();
        const gltfPathSuffix = new RegExp(/\?.+=.*/g).test(wi.fileInfo.cad.models[0].gltf_path) ? '&cache=true' : '?cache=true';
        useCanvasState.setState({
          workInstructions: {
            cardsData: wi.workInstructions.cardsData.map((cardData) => {
              if (wiType === '2d') {
                  cardData.is2dStep = true;
              }
              return cardData;
            }),
            tools: featuresTools,
            views: wi.features.views,
            hotSpots: wi.features.hotspots,
            gltfFilePath: wi.fileInfo.cad.models[0].gltf_path?.replace(environment.AppConfigs.internalLinkIdentifier, environment.AppConfigs.fileManagerApi) + gltfPathSuffix,
            animation: wi.features.animations,
            materials: wi.features.materials,
            annotations: wi.features.annotations.annotationData,
            mediaReferences: references,
            explosions: wi.features.explosions,
          },
        });
      }).finally(() => {
          setLoading(false);
      });
    }
  }, [companyId, id, workInstructionId, searchParams, partnerAccess, access]);

  const removeObjectUrlsOnReload = () => {
    canvasState.workInstructions?.mediaReferences.map((mediaRef) => {
      if (mediaRef.objectUrl) URL.revokeObjectURL(mediaRef.objectUrl);
    });

    canvasState.workInstructions?.cardsData.map((cardData) => {
      if (Array.isArray(cardData.mediaAssociation)) {
        cardData.mediaAssociation.map((aMediaAssociation) => {
          if (aMediaAssociation.objectUrl) URL.revokeObjectURL(aMediaAssociation.objectUrl);
        });
      }
    });
  };

  useEffect(() => {
    window.addEventListener('beforeunload', removeObjectUrlsOnReload);

    return () => {
      window.removeEventListener('beforeunload', removeObjectUrlsOnReload);
    };
  }, []);

  const setUserStatsData = (e: any) => {
    const {
      data: {
        data: [userStats],
      },
    } = e;
    if (userStats && canvasState.workInstructions) {
      const { completionTime = '0' } = userStats;
      // Set current step from user stats
      let totalsteps = canvasState.workInstructions.cardsData.length;
      setAssemblyState((state) => {
        return {
          ...state,
          currentStep: userStats.currentStep,
          totalSteps: totalsteps,
          currentTime: Number(completionTime),
          userStats: userStats,
        };
      });
      // Set the seconds from userStats
      setSeconds(Number(completionTime));
      // Data ready
      setIsDataReady(true);
    }
  };

  const setAssemblyData = () => {
    if (canvasState.workInstructions) {
      // Set current step from user stats
      let totalsteps = canvasState.workInstructions.cardsData.length;
      setAssemblyState((state) => {
        return {
          ...state,
          currentStep: 1,
          totalSteps: totalsteps,
        };
      });
      // Data ready
      setIsDataReady(true);
    }
  };

  useEffect(() => {
    if ((canvasState.procedureId && workInstructionId) || partnerAccess) {
      TutorialService.getProcedure(canvasState.procedureId, access).then((e) => {
        const procedureData = e.data.data;
        setProcedure(procedureData);
        if (procedureData) {
          const { completionStatus = 'NOT STARTED' } = procedureData;
          // Start or Restart based on Status
          const statusToCallStart = ['NOT STARTED', 'COMPLETED'];
          if (statusToCallStart.includes(completionStatus.toUpperCase()) && workInstructionId && canvasState.procedureId && !props.isTrainer && !partnerAccess && !isViewComment) {
            // Start user stats
            TutorialService.startUserStats(workInstructionId, canvasState.procedureId).then((e) => {
              setUserStatsData(e);
            });
          } else if (workInstructionId && canvasState.procedureId && !props.isTrainer && !partnerAccess && !isViewComment) {
            TutorialService.getUserStats(workInstructionId, canvasState.procedureId).then((e) => {
              setUserStatsData(e);
            });
          } else if ((workInstructionId && canvasState.procedureId) || partnerAccess) {
            setAssemblyData();
          }
        }
      });
    }

    return () => {};
  }, [canvasState.procedureId, workInstructionId]);

  useEffect(() => {
    let myInterval = setInterval(() => {
      setSeconds(seconds + 1);
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  useEffect(() => {
    if (assemblyState) {
      setSeconds(assemblyState.currentTime);
    }
  }, [assemblyState?.currentStep]);

  useEffect(() => {
    setZoom(zoom);
  }, [zoom]);

  useEffect(() => {
    setContainerHeight(matches ? 'calc(100% - 80px)' : 'calc(100% - 229px)');
  }, [matches]);

  const openHotSpotModel = (media: MediaReference) => {
    setOpen(true);
    setHotSpotMedia(media);
  };

  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleScanDialogClose = () => {
    setOpenScanDialog(false);
  };

  const loadMedia = (direction: number) => {
    let lengthToCompare: number | undefined;
    if (showIntro) {
      lengthToCompare = canvasState.workInstructions?.mediaReferences.length;
    } else {
      lengthToCompare = canvasState.workInstructions?.cardsData[assemblyState.currentStep - 1].mediaAssociation.length;
    }
    if (lengthToCompare === undefined) return;

    if (lengthToCompare > 0) {
      let currentIndex = currentMediaIndex;
      if (direction === -1) {
        // Previous
        if (currentIndex > 0) {
          currentIndex--;
        }
      } else {
        // Next
        if (currentIndex < lengthToCompare) {
          currentIndex++;
        }
      }

      let dataToMap = showIntro ? canvasState.workInstructions?.mediaReferences : canvasState.workInstructions?.cardsData[assemblyState.currentStep - 1].mediaAssociation;
      dataToMap?.map((media, i) => {
        if (i === currentIndex) {
          setCurrentMediaIndex(currentIndex);
          setHotSpotMedia(media);
        }
      });
    }
  };

  const { tourGuide } = useTour();

  const endTour = () => {
    const tourData = {
      userGuidePrompt: {
        ...tourGuide,
        TRAINING: false,
      },
    };
    TrainerService.editUserTour(tourData);
  };

  useEffect(() => {
    if (tourGuide?.TRAINING) {
      setShowDemo(true);
    }
  }, [tourGuide]);

  const handleBackButton = () => {
    if(isRevisitRequest)
      navigate(`/trainer/RevisitProcedures/${workInstructionId}`);
    else if(isDelete)
      navigate(`/trainer/RecycleBinprocedures/${companyId}/${workInstructionId}`)
    else if(isTemplateWI)
      navigate('/');
    else
      navigate(`/procedures/${companyId}/${workInstructionId}`)
  }

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      {showDemo && (
        <DemoDialog
          demoStep={demoStep}
          setDemoStep={setDemoStep}
          onClose={() => {
            setShowDemo(false);
            endTour();
          }}
        />
      )}
      <div className="assembly-container">
        <Grid container spacing={2} className="assembly-header">
          <Grid item md={4}>
            <div className="icons-links">
              <Box component="div" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Box component="span" sx={{ display: { xs: 'none', md: 'flex' } }} className="logo">
                  <IconButton size="large" edge="start" color="inherit" aria-label="menu" href="/">
                    <img className="logo-banner-mini" src={'/logos/cds_logo_default.svg'} alt="logo"></img>
                  </IconButton>
                </Box>

                {!partnerAccess && (
                  <Button
                    size="small"
                    startIcon={<ArrowBack />}
                    onClick={handleBackButton}
                    sx={{ textTransform: 'none', fontSize: '1em', borderRadius: '20px', backgroundColor: '#0000000a', minHeight: '32px', maxHeight: '32px', lineHeight: 1, display: { xs: 'none', md: 'flex' } }}
                  >
                    {t('NAVBAR.ALL_PROCEDURES')} &nbsp;{' '}
                  </Button>
                )}
              </Box>
            </div>
          </Grid>
          <Grid item md={4}>
            <Box component="div" className="header-title" sx={{ display: { xs: 'none', md: 'block' } }}>
              {/* <div className='header-title'> */}
              {procedure?.procedureName}
              {/* </div> */}
            </Box>
          </Grid>
          <Grid item md={4}>
            {!props.isTrainer && !isViewComment && (
              <Box component="div" sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end', alignItems: 'center', padding: '10px 20px' }}>
                {timeAllotedForStep > 0 && showIntro === false && (
                  <>
                    <Typography sx={{ marginRight: '8px' }}>{t('COMMON.STEP_CYCLE_TIME')} </Typography>
                    <Typography className="bg-red-400" sx={{ marginRight: '16px', color: cycleTime > 0 ? '#008000' : 'rgba(207, 50, 50, 1)', backgroundColor: cycleTime > 0 ? '#90ee90' : 'rgba(207, 50, 50, 0.1)', padding: '4px', display: 'inline-block', borderRadius: '4px', textAlign: 'center', width: '150px' }}>
                      {' '}
                      {timerLoading ? (
                        <Skeleton />
                      ) : (
                        <>
                          {formatTime(cycleTime)}/{formatTime(timeAllotedForStep)}
                        </>
                      )}
                    </Typography>
                  </>
                )}

                <Button variant="outlined" onClick={handleClick} startIcon={<MoreHorizIcon />}>
                  {t('COMMON.MORE')}
                </Button>

                <Menu sx={{ display: { xs: 'none', md: 'flex' } }} id="basic-menu" anchorEl={anchorEl} open={openMenu && !!anchorEl} onClose={handleMenuClose} MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
                  {' '}
                  <MenuItem
                    onClick={() => {
                      setShowDemo(true);
                      setDemoStep(1);
                      handleMenuClose();
                    }}
                  >
                    {t('ASSEMBLY_PAGE.RUN_3D_WALKTHROUGH')}
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </Grid>

          <Box component="div" sx={{ width: '100%', margin: '0px 24px 0px 36px', justifyContent: 'space-between', display: { xs: 'flex', md: 'none' } }}>
            {!partnerAccess && (
              <Button size="small" href={`/procedures/${companyId}/${workInstructionId}`} sx={{ margin: '12px 0px', textTransform: 'none', fontSize: '0.875rem', borderRadius: '8px', backgroundColor: '#0000000a', color: '#000000', display: { xs: 'flex', md: 'none' }, marginRight: '8px' }}>
                <Box component="div" sx={{ display: 'flex', marginRight: '10px', marginLeft: '10px' }}>
                  <LeftArrow color={'#000000'} />
                </Box>
                <Box component="div" sx={{ marginRight: '10px', maxWidth: '50vw', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  {procedure?.procedureName} &nbsp;{' '}
                </Box>
              </Button>
            )}
            {partnerAccess && <Box component="div"></Box>}
            <Box component="div" sx={{ display: 'flex' }}>
              {!props.isTrainer && timeAllotedForStep > 0 && showIntro === false && !isViewComment && (
                <>
                  <Typography className="bg-red-400" sx={{ color: cycleTime > 0 ? '#008000' : 'rgba(207, 50, 50, 1)', backgroundColor: cycleTime > 0 ? '#90ee90' : 'rgba(207, 50, 50, 0.1)', padding: '8px', borderRadius: '8px', textAlign: 'center', maxHeight: '30px', margin: '12px 8px 12px 0px', minWidth: '0 !important', display: { xs: 'flex', md: 'none' } }}>
                    {' '}
                    {timerLoading ? <Skeleton /> : <>{formatTime(cycleTime)}</>}
                  </Typography>
                </>
              )}
              <Button size="small" variant="outlined" sx={{ margin: '12px 0px', textTransform: 'none', fontSize: '0.875rem', borderRadius: '8px', minWidth: '0 !important', display: { xs: 'flex', md: 'none' } }} id="basic-button" aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                <MoreHorizIcon color="primary" />
              </Button>
              <Menu sx={{ display: { xs: 'flex', md: 'none' } }} id="basic-menu" anchorEl={anchorEl} open={openMenu} onClose={handleMenuClose} MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
                <MenuItem
                  onClick={() => {
                    setOpenScanDialog(true);
                    handleMenuClose();
                  }}
                >
                  <ViewInArIcon />
                  {t('ASSEMBLY_PAGE.OPEN_IN_AR')}
                </MenuItem>
              </Menu>
            </Box>
            <ScanQR handleScanDialogClose={handleScanDialogClose} openDialog={openScanDialog} value={JSON.stringify({ source: 'cdsmentor', url: 'value' })} />
          </Box>
        </Grid>
        {loading && (
          <Loading>
            <LinearWithValueLabel progress={progressPercentage} message={introStep >= 2 ? t('3D_VIEWER.LOADING_THE_MODEL') : 'Loading'} />
          </Loading>
        )}
        {canvasState.workInstructions && isDataReady && (
          <>
            <Grid container spacing={0} className="assembly-grid" direction={'column'}>
              <Grid item className="canvas-grid" xs zeroMinWidth>
                {assemblyState && mediaReferencesEnabled && !canvasState.workInstructions?.cardsData[assemblyState.currentStep - 1].is2dStep && !showIntro && (
                  <Box component="div" className="hot-spot-container">
                    {/* Step specific media references shown during step */}
                    {!showIntro &&
                      assemblyState &&
                      canvasState.workInstructions.cardsData[assemblyState.currentStep - 1].mediaAssociation &&
                      mediaReferencesEnabled &&
                      canvasState.workInstructions.cardsData[assemblyState.currentStep - 1].mediaAssociation.map((media, i) => {
                        let mediaDataSource: string = media.objectUrl ? media.objectUrl : media.dataUrl;
                        return (
                          <Box
                            component="div"
                            className="hot-spot-content"
                            onClick={() => {
                              setCurrentMediaIndex(i);
                              openHotSpotModel(media);
                            }}
                            key={i}
                          >
                            {(media.fileType === 'image/jpeg' || media.fileType === 'image/png') && <img src={mediaDataSource} alt="media" />}
                            {(media.fileType === 'video/mp4' || media.fileType === 'video/quicktime') && (
                              <Fragment key={mediaDataSource}>
                                {!mediaDataSource ? (
                                  'Loading...'
                                ) : (
                                  <video width="100%" controls={false} preload="metadata">
                                    <source src={mediaDataSource} type="video/mp4" />
                                  </video>
                                )}
                              </Fragment>
                            )}
                            {media.fileType.includes('pdf') && <object style={{ pointerEvents: 'none', zIndex: -1 }} type="application/pdf" width="100%" height="100%" data={mediaDataSource}></object>}
                          </Box>
                        );
                      })}

                    {/* Global Media References shown during intro */}
                    {showIntro &&
                      assemblyState &&
                      canvasState.workInstructions.mediaReferences &&
                      mediaReferencesEnabled &&
                      canvasState.workInstructions.mediaReferences.map((media, i) => {
                        let mediaDataSource: string = media.objectUrl ? media.objectUrl : media.dataUrl;
                        return (
                          <Box
                            component="div"
                            className="hot-spot-content"
                            onClick={() => {
                              setCurrentMediaIndex(i);
                              openHotSpotModel(media);
                            }}
                            key={i}
                          >
                            {(media.fileType === 'image/jpeg' || media.fileType === 'image/png') && <img src={mediaDataSource} alt="media" />}
                            {(media.fileType === 'video/mp4' || media.fileType === 'video/quicktime') && (
                              <Fragment key={mediaDataSource}>
                                {!mediaDataSource ? (
                                  'Loading...'
                                ) : (
                                  <video width="100%" controls={false} preload="metadata">
                                    <source src={mediaDataSource} type="video/mp4" />
                                  </video>
                                )}
                              </Fragment>
                            )}
                            {media.fileType.includes('pdf') && <object style={{ pointerEvents: 'none', zIndex: -1 }} type="application/pdf" width="100%" height="100%" data={mediaDataSource}></object>}
                          </Box>
                        );
                      })}
                  </Box>
                )}
                {assemblyState && procedure && showIntro === true && (
                  <Box component="div" sx={{ display: 'flex', height: 'calc(100% - 80px)', width: '100%' }}>
                    <IntroStepContainer introStep={introStep} procedure={procedure} workInstructionName={workInstructionName} />
                  </Box>
                )}

                {assemblyState && canvasState.workInstructions?.cardsData[assemblyState.currentStep - 1].is2dStep == true && showIntro == false && (introStep === 2 || isViewComment) && (
                  <Box component="div" className={assemblyState && canvasState.workInstructions?.cardsData[assemblyState.currentStep - 1].is2dStep == true ? '' : 'media-container-hidden'} sx={{ height: containerHeight, width: '100%' }}>
                    <MediaContainer is2d={wiType === '2d'} stepMediaReferences={canvasState.workInstructions?.cardsData[assemblyState.currentStep - 1].mediaAssociation} />
                  </Box>
                )}

                <Box className={(assemblyState && canvasState.workInstructions?.cardsData[assemblyState.currentStep - 1].is2dStep === true) || showIntro ? 'canvas-container-hidden' : ''} component="div" sx={{ height: showIntro ? '2px' : containerHeight, width: '100%' }}>
                  {((wiType === '3d' && props.bearerToken && gltfId) || partnerAccess) && (
                    <>
                      <CommonViewerWrapper explode={explode} currentStep={assemblyState.currentStep} intro={showIntro} bearerToken={props?.bearerToken ? props?.bearerToken : ''} cdsModelId={gltfId} />
                    </>
                  )}
                </Box>
                <Box component="div" className="assembly-bar-container">
                  <AssemblyBar timer={seconds} currentZoomLevel={zoom} zoom={(zoomLevel) => setZoom(zoomLevel)} assemblyState={assemblyState} onAssemblyStateChange={(assembly) => setAssemblyState(assembly)} isTrainer={props?.isTrainer} timerLoading={timerLoading} isViewComment={isViewComment} procedure={procedure} stepIndex={assemblyState.currentStep - 1} />
                </Box>
              </Grid>
            </Grid>

            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
              <Box component="div" className="hot-spot-modal" sx={hotSpotMedia && hotSpotMedia.fileType.includes('pdf') ? { width: '80%', height: '80%' } : {}}>
                <Box component="div" sx={{ mb: '5px' }}>
                  <Button color="inherit" aria-label="menu" endIcon={<DialogClose />} onClick={handleClose}>
                    {t('ANALYTICS_SECTION.CLOSE')}
                  </Button>
                </Box>
                <Box component="div" className="hot-spot-media" sx={hotSpotMedia && hotSpotMedia.fileType.includes('pdf') ? { width: '100%', height: '100%' } : {}}>
                  {assemblyState && hotSpotMedia && (hotSpotMedia.fileType === 'image/jpeg' || hotSpotMedia.fileType === 'image/png') && <img src={hotSpotMedia.objectUrl ? hotSpotMedia.objectUrl : hotSpotMedia.dataUrl} alt={wiType === '2d' ? 'Loading...' : 'Media reference not found!'} />}
                  {assemblyState && hotSpotMedia && (hotSpotMedia.fileType === 'video/mp4' || hotSpotMedia.fileType === 'video/quicktime') && (
                    <Fragment key={hotSpotMedia.objectUrl}>
                      {!hotSpotMedia.objectUrl && !hotSpotMedia.dataUrl ? (
                        'Loading...'
                      ) : (
                        <video width="100%" preload="metadata" controls>
                          <source src={hotSpotMedia.objectUrl ? hotSpotMedia.objectUrl : hotSpotMedia.dataUrl} type="video/mp4" />
                        </video>
                      )}
                    </Fragment>
                  )}
                  {assemblyState && hotSpotMedia && hotSpotMedia.fileType.includes('pdf') && <object type="application/pdf" width="100%" height="100%" data={hotSpotMedia.objectUrl ? hotSpotMedia.objectUrl : hotSpotMedia.dataUrl}></object>}
                </Box>
                {canvasState.workInstructions?.mediaReferences?.length > 1 && (
                  <IconButton
                    aria-label="previous"
                    title="Previous Media Reference"
                    onClick={() => {
                      loadMedia(-1);
                    }}
                    color="secondary"
                    disabled={currentMediaIndex <= 0}
                    sx={{ border: '1px solid #fff', background: '#fff', opacity: 0.75, position: 'absolute', left: '1em', top: 'calc(50% - .5em)', boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px' }}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                )}
                {canvasState.workInstructions?.mediaReferences?.length > 1 && (
                  <IconButton
                    aria-label="next"
                    title="Next Media Reference"
                    onClick={() => {
                      loadMedia(1);
                    }}
                    color="secondary"
                    disabled={currentMediaIndex >= canvasState.workInstructions?.mediaReferences?.length - 1}
                    sx={{ border: '1px solid #fff', background: '#fff', opacity: 0.75, position: 'absolute', right: '1em', top: 'calc(50% - .5em)', boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px' }}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                )}
              </Box>
            </Modal>
          </>
        )}
      </div>
    </>
  );
};

export default AssemblyInstructions;
