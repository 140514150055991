import { FormControl, FormLabel, Grid, InputBase, MenuItem, Select, styled, Box, TextField, FormHelperText, InputBaseComponentProps } from '@mui/material';
import React, { useState } from 'react';
import { FormFieldProps, FormSelectProps } from './interface';
export const CustomInput = styled(InputBase)(({ theme }) => ({
    height: '38px',
    paddingBlock: '10px',
    paddingInline: '14px',
    border: '1px solid #FF0000',
    fontWeight: 400,
    fontSize: '16px',
    borderRadius: '4px',
    background: 'white',
    color: 'rgba(51, 51, 51, 1)',

    '& .MuiOutlinedInput-root': {
        padding: '0px',
        '& fieldset': {
            border: 'none',
        },
    },
    '& .MuiInputBase-input': {
        padding: '0px',
    },
    '&.Mui-disabled': {
        backgroundColor: 'rgba(245, 245, 245, 1)',
        color: 'rgba(153, 153, 153, 1)',
    },
    '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
        display: 'none',
    },
    '& input[type=number]': {
        '-moz-appearance': 'textfield',
    },
}));
export const CustomLabel = styled(FormLabel)(({ theme }) => ({
    fontWeight: '400',
    fontSize: '14px',
    color: 'rgba(51, 51, 51, 1)',
    textTransform: 'capitalize',
    textAlign: 'start',
    marginBottom: '4px',
    '& .MuiFormLabel-asterisk': {
        color: 'red',
    },
    '& .MuiFormLabel-focused': {
        color: 'red',
    },
    '&.Mui-focused': {
        color: 'rgba(51, 51, 51, 1)',
    },
    '&.Mui-disabled': {
        color: 'rgba(51, 51, 51, 1)',
    },
}));
interface FormInputSelectProps extends FormSelectProps {
    type: string;
    placeholder: string;
    onChange: (e, value) => void;
}
export const FormField: React.FC<FormFieldProps> = ({ label, required = false, type = 'text', placeholder, value, ...props }) => {
    return (
        <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
        >
            <FormControl
                required={required}
                fullWidth
                disabled={props.disabled}
            >
                <CustomLabel required={required}>{label}</CustomLabel>
                <TextField
                    value={value !== undefined ? value || '' : undefined}
                    type={type}
                    onChange={(e) => props.onChange(e)}
                    placeholder={placeholder}
                    disabled={props.disabled}
                    inputProps={{ maxLength: props.maxLength ?? 20 }}
                    sx={{
                        border: '1px solid #CCCCCC',
                        '& .MuiInputBase-input': {
                            padding: 0,
                            '&:-webkit-autofill': {
                                backgroundColor: 'white !important',
                                WebkitBoxShadow: '0 0 0px 1000px white inset !important',
                                WebkitTextFillColor: 'rgba(51, 51, 51, 1) !important',
                                transition: 'background-color 5000s ease-in-out 0s',
                            },
                        },
                        '& .MuiOutlinedInput-root': {
                            height: '38px',
                            paddingBlock: '10px',
                            paddingInline: '14px',
                            fontWeight: 400,
                            fontSize: '16px',
                            borderRadius: '4px',
                            background: 'white',
                            color: 'rgba(51, 51, 51, 1)',

                            '& fieldset': {
                                border: 'none',
                            },
                            '&.Mui-disabled': {
                                backgroundColor: 'rgba(245, 245, 245, 1)',
                                color: 'rgba(153, 153, 153, 1)',
                            },
                            '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
                                display: 'none',
                            },
                            '& input[type=number]': {
                                '-moz-appearance': 'textfield',
                            },
                        },
                    }}
                />
                {props.error && <FormHelperText sx={{ color: 'red', padding: 0, margin: 0 }}>{props.error ? props.error : ''}</FormHelperText>}
            </FormControl>
        </Grid>
    );
};

export const FormSelect: React.FC<FormSelectProps> = ({ value, label, options = [], defaultValue, required = false, ...props }) => {
    return (
        <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
        >
            <FormControl
                fullWidth
                required={required}
                disabled={props.disabled}
            >
                <CustomLabel>{label}</CustomLabel>
                <Select
                    value={value || ''}
                    displayEmpty
                    input={<CustomInput />}
                    onChange={(e) => {
                        props.onChange(e);
                    }}
                    sx={{ color: 'rgba(51,51,51, 1)' }}
                >
                    <MenuItem
                        value={''}
                        sx={{ display: 'none' }}
                    >
                        {defaultValue}
                    </MenuItem>
                    {options.map((option, index) => {
                        return (
                            <MenuItem
                                key={index}
                                value={option.value}
                            >
                                {option.label}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </Grid>
    );
};

export const FormInputSelect: React.FC<FormInputSelectProps> = ({ value, type, label, options = [], defaultValue, required = false, ...props }) => {
    const [myValue, setMyValue] = useState(defaultValue);
    return (
        <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
        >
            <CustomLabel required={required}>{label}</CustomLabel>

            <Box
                display="flex"
                alignItems="center"
                sx={{ border: '1px solid rgba(204, 204, 204, 1)', bgcolor: props.disabled ? 'rgba(245, 245, 245, 1)' : 'white' }}
            >
                <FormControl disabled={props.disabled}>
                    <Select
                        // {...props}
                        defaultValue={defaultValue}
                        input={<CustomInput />}
                        sx={{
                            width: '60px',
                            paddingRight: 0,
                            border: 'none',
                            '& .MuiInputBase-input': {
                                paddingRight: '8px !important', // Reduce the default padding
                            },
                            '& .MuiSelect-icon': {
                                right: '0px', // Adjust the arrow icon position if needed
                            },
                        }}
                        onChange={(e) => setMyValue(e.target.value)}
                    >
                        {options.map((option, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={option.value}
                                >
                                    {' '}
                                    {option.label}{' '}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <Box
                    component={'span'}
                    sx={{ paddingRight: '8px', color: props.disabled ? 'rgba(153, 153, 153, 1)' : 'rgba(51, 51, 51, 1)' }}
                >
                    {myValue}
                </Box>
                <CustomInput
                    type={type}
                    value={value || ''}
                    sx={{ flexGrow: 1, border: 'none', paddingInline: 0 }}
                    onChange={(e) => props.onChange(e, myValue)}
                    placeholder={props.placeholder}
                    disabled={props.disabled}
                />
            </Box>
        </Grid>
    );
};

export const FormLayout = ({ children }) => {
    return (
        <Grid
            container
            spacing={'24px'}
            sx={{ maxWidth: '900px' }}
        >
            {children}
        </Grid>
    );
};
