import { Avatar, Box, Button, Card, CardActions, CardContent, Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, IconButton, Skeleton, Tab, Tabs, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid/Grid';
import React, { useEffect, useState } from 'react';
//import { ppeList } from "../NewProcedure/ppe";
import { useFormik } from 'formik';
import TrainerService from '../../../services/trainer/TrainerService';
import { useFileUpload } from '../../../shared/context';
import { useNavigate } from 'react-router-dom';
import './AddPPE.scss';
import { IProcedures } from '../../../entities/Trainee/MockTrainee';
import { LeftArrow, NoPPEIcon } from '../../Icons/CdsIcons';
import AddCustomPPEDialog from '../Dialog/AddCustomPPEDialog/AddCustomPPEDialog';
import { UserPermissions } from '../../../shared/permissions';
import { ActionMenu } from '../../ActionMenu/ActionMenu';
import { Toast } from '../../Notifications/Notification';
import usePpeList from '../../../hooks/ppeList';
import { trackEvent } from '../../../App';
import { AmplitudeEvents, PROCEDURE_VISIBILITY_SCOPE } from '../../../shared/enums';
import { useTranslation } from 'react-i18next';
import useTour from '../../../hooks/tour';
import { ppeList as tourPpeList } from '../NewProcedure/ppe';
import { closeSnackbar } from 'notistack';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { useAppSelector } from '../../../hooks/redux';

interface Props {
    workInstructionData: any;
    procedureData: any;
    ppeEquipment: string[];
    procedureDetails: null | IProcedures;
    showBackButton: boolean;
    procedureRefDoc?: any;
    onGoNext: (formData: string[]) => void;
    onGoBack: (formData: string[]) => void;
}

export const AddNewPpe = (props) => {
    const [addCustomPPEDialog, setAddCustomPPEDialog] = useState<boolean>(false);
    const { setShowSystemSettings, setSystemSettingsTab } = usePpeList();
    const [loading, setLoading] = useState(false);
    const [getListLoading, setGetListLoading] = useState(false);

    const addCustomPpe = () => {
        setAddCustomPPEDialog(true);
    };

    const closeDialogHandler = () => {
        setAddCustomPPEDialog(false);
    };

    const handleSystemSettingsDialogClose = () => {
        setShowSystemSettings(false);
    };

    const uploadCustomPpe = (ppeFormData) => {
        setLoading(true);
        TrainerService.createPPEEquipment(ppeFormData)
            .then((res) => {
                setLoading(false);
                props.getPpeList();
                setAddCustomPPEDialog(false);
            })
            .catch(({ response }) => {
                Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
                setLoading(false);
            });
    };

    const { t } = useTranslation();

    return (
        <>
            <AddCustomPPEDialog
                closeDialog={closeDialogHandler}
                openDialog={addCustomPPEDialog}
                procedure={props.procedureDetails}
                uploadCustomPpe={uploadCustomPpe}
                style={{ padding: '24px' }}
                loading={loading}
            />

            {props.children && React.cloneElement(props.children, { onClick: addCustomPpe })}
        </>
    );
};

export const PPEListComp: React.FC<any> = (props) => {
    const [value, setValue] = React.useState(props.defaultValue || 0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const theme = useTheme();
    const tabs = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };
    const { isTourOpen, tourStep, setTourStep } = useTour();

    const { t } = useTranslation();

    return (
        <>
            <Box
                component="div"
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    <Tab
                        sx={{
                            color: theme.palette.primary.main,
                            opacity: '0.5',
                            '&.Mui-selected': {
                                color: theme.palette.primary.main,
                                opacity: '1',
                            },
                        }}
                        label={
                            <Box
                                component="div"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                {t('COMMON.STANDARD') as string}{' '}
                                <Avatar
                                    className="ppeCount"
                                    sx={{ backgroundColor: theme.palette.primary.main + '1c' }}
                                    variant="rounded"
                                >
                                    {' '}
                                    {props.standardPpeCount}{' '}
                                </Avatar>
                            </Box>
                        }
                        {...tabs(0)}
                    />
                    <Tab
                        sx={{
                            color: theme.palette.primary.main,
                            opacity: '0.5',
                            '&.Mui-selected': {
                                color: theme.palette.primary.main,
                                opacity: '1',
                            },
                        }}
                        label={
                            <Box
                                component="div"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                {t('COMMON.CUSTOM') as string}{' '}
                                <Avatar
                                    className="ppeCount"
                                    sx={{ backgroundColor: theme.palette.primary.main + '1c' }}
                                    variant="rounded"
                                >
                                    {' '}
                                    {props.customPpeCount}{' '}
                                </Avatar>
                            </Box>
                        }
                        {...tabs(1)}
                    />
                </Tabs>
                {props.addNew}
            </Box>

            <CardContent sx={{ height: '300px', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                {props.customPpeCount === 0 && value === 1 && props.contentReady && (
                    <Box
                        className="align-center"
                        component="div"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <NoPPEIcon />
                        <Typography sx={{ fontSize: '20px', marginTop: '20px' }}>No Custom PPE Added</Typography>
                        {props.addCustomPPE && (
                            <AddNewPpe
                                customPpeCount={props.customPpeCount}
                                title={t('COMMON.ADD_PPE') as string}
                                getPpeList={props.getPpeList}
                            >
                                <Button
                                    size="small"
                                    variant="outlined"
                                    sx={{ float: 'right', margin: '0px 6px' }}
                                    type="submit"
                                    disabled={!props.contentReady}
                                    //onClick={() => addCustomPpe()}
                                >
                                    {t('COMMON.ADD_PPE') as string}
                                </Button>
                            </AddNewPpe>
                        )}
                    </Box>
                )}
                <Box
                    sx={{
                        flex: 1,
                        overflowY: 'auto',
                        padding: '0 8px',
                        '&::-webkit-scrollbar': {
                            width: '8px',
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: '#f1f1f1',
                            borderRadius: '4px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: '4px',
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            backgroundColor: theme.palette.primary.dark,
                        },
                        scrollbarWidth: 'thin',
                        scrollbarColor: theme.palette.primary.main,
                    }}
                >
                    <Grid
                        className="add-ppe card-container"
                        container
                        spacing={{ xs: 1, md: 1 }}
                        sx={{ textAlign: 'left', margin: 0, padding: 0 }}
                    >
                        {!props.contentReady &&
                            !isTourOpen &&
                            [1, 2, 3, 4, 5, 6, 7, 8].map((e, i) => {
                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        lg={6}
                                        key={i}
                                    >
                                        <Skeleton height={70} />
                                    </Grid>
                                );
                            })}

                        {props.ppeList?.length > 0 &&
                            props.ppeList.map((e, i) => {
                                if ((value === 0 && e.isStandard) || (value === 1 && !e.isStandard))
                                    return (
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            lg={6}
                                            key={i}
                                        >
                                            {!props.contentReady && !isTourOpen && <Skeleton height={70} />}
                                            {(props.contentReady || isTourOpen) && (
                                                <FormControlLabel
                                                    className="tour-ppe-check-box"
                                                    sx={{
                                                        className: 'tour-ppe-check-box',
                                                        border: `1px solid ${theme.palette.primary.main + '1c'}`,
                                                        borderRadius: '8px',
                                                        width: '100%',
                                                        padding: '5px 0',
                                                        margin: 0,
                                                    }}
                                                    control={
                                                        <>
                                                            {!props.systemSettings && (
                                                                <Checkbox
                                                                    defaultChecked={props?.ppeEquipment?.includes(e.id)}
                                                                    checked={
                                                                        isTourOpen
                                                                            ? i === 0 && true
                                                                            : !(
                                                                                  props.checked.find((item) => {
                                                                                      return item === e.id;
                                                                                  }) === undefined
                                                                              )
                                                                    }
                                                                    onChange={props.handlePpeValues}
                                                                    value={e.id}
                                                                />
                                                            )}
                                                        </>
                                                    }
                                                    label={
                                                        <Box
                                                            component="div"
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                width: '100%',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between',
                                                            }}
                                                        >
                                                            <Box
                                                                component="div"
                                                                sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                {/* <CardMedia component="img" className="wi-img" image={'data:image/jpeg;base64,'+e.previewImgUrl} alt="PPE" /> */}
                                                                <Avatar
                                                                    sx={{
                                                                        borderRadius: '12px',
                                                                        marginLeft: props.systemSettings ? '8px' : '0px',
                                                                        objectFit: 'contain',
                                                                    }}
                                                                    imgProps={{
                                                                        style: {
                                                                            objectFit: 'contain',
                                                                        },
                                                                    }}
                                                                    variant="square"
                                                                    alt="PPE"
                                                                    src={'data:image/jpeg;base64,' + e.previewImgUrl}
                                                                />
                                                                <Tooltip title={e.name}>
                                                                    <Typography
                                                                        component="div"
                                                                        variant="subtitle1"
                                                                        style={{ marginLeft: '1rem', display: 'inline-block !important' }}
                                                                        className="section-text"
                                                                        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '125px' }}
                                                                    >
                                                                        {e.name}
                                                                    </Typography>
                                                                </Tooltip>
                                                            </Box>
                                                            {props.systemSettings && !e.isStandard && (
                                                                <Box
                                                                    component="div"
                                                                    sx={{ marginRight: '8px' }}
                                                                >
                                                                    <ActionMenu
                                                                        onEdit={() => props.handleEdit(e.id)}
                                                                        onDelete={() => props.handleDelete(e.id)}
                                                                        editPermission={UserPermissions.UPDATE}
                                                                        deletePermission={UserPermissions.DELETE}
                                                                    />
                                                                </Box>
                                                            )}
                                                        </Box>
                                                    }
                                                />
                                            )}
                                        </Grid>
                                    );
                            })}
                    </Grid>
                </Box>
            </CardContent>
        </>
    );
};

const AddPPE: React.FC<Props> = (props) => {
    const { visibilityScope } = useAppSelector((state) => state.procedure.createProcedure);
    const [checked, setChecked] = useState<string[]>([]);
    const { sharedPpeList, setSharedPpeList } = usePpeList();
    const [ppeList, setPpeList] = useState<any>(sharedPpeList);
    const [standardPpeCount, setStandardPpeCount] = useState<any>();
    const [customPpeCount, setCustomPpeCount] = useState<any>();
    const [contentReady, setContentReady] = useState<boolean>(true);
    const fileUpload = useFileUpload();
    const companyId = localStorage.getItem('company');
    const navigate = useNavigate();
    const search = window.location.pathname;
    const { refreshProcedure, setRefreshProcedure } = useCurrentUser();
    const workInstructionId = search
        .split('/')
        .splice(search.split('/').length - 1)
        .toString();
    const theme = useTheme();

    useEffect(() => {
        fileUpload.dispatch({ type: 'canUpload', payload: false });
        return () => {};
    }, []);

    useEffect(() => {
        var standard = 0;
        var custom = 0;
        sharedPpeList?.forEach((item) => {
            if ((item as any)?.isStandard) {
                standard++;
            } else {
                custom++;
            }
        });
        setStandardPpeCount(standard);
        setCustomPpeCount(custom);
    }, [ppeList, sharedPpeList]);

    const formRequestObj = (obj, workInstructionId) => {
        obj.isPrerequisite = obj.procedureType;
        obj.workInstructionId = workInstructionId;
        obj.ppeEquipments = [...checked];
        obj.isPrerequisite === 'Pre Requisite' ? (obj.isPrerequisite = true) : (obj.isPrerequisite = false);
        obj.estimated_time = obj.hours * 60 * 60 + obj.minutes * 60 + obj.seconds;
        delete obj.procedureType;
        delete obj.isPpe;
        delete obj.hours;
        delete obj.minutes;
        delete obj.seconds;
        return obj;
    };
    const { t } = useTranslation();
    const uploadReferenceDocument = async () => {
        if (props.procedureRefDoc && props.procedureDetails) {
            const formData = new FormData();
            formData.append('workInstructionId', workInstructionId);
            formData.append('procedureId', props.procedureDetails?.procedureId);
            for (const files of props.procedureRefDoc) {
                formData.append('file', files);
            }
            setContentReady(false);
            if (props.procedureRefDoc.length !== 0) {
                Toast.info(`${t('COMMON.FILE_UPLOADING')}`, `${t('COMMON.PDF_FILE_IS_BEING_UPLOADED')} ${t('COMMON.PLEASE_WAIT')}.`, true);
            }
            await TrainerService.refFileUpload(formData)
                .then((response) => {
                    setContentReady(true);
                    closeSnackbar();
                    setRefreshProcedure(!refreshProcedure);
                })
                .catch(({ response }) => {
                    closeSnackbar();
                    setTimeout(() => {
                        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
                    }, 2000);
                });
        }
    };

    const handleFinish = async () => {
        setContentReady(false);
        let request;
        let responseData: any = {};
        if (props.workInstructionData === null) {
            request = formRequestObj(props.procedureData, workInstructionId);
            request.signOffUsers = request.signOffUsers.map((user) => user.userId);
            request.ppeTitle = formik.values.ppeTitle;
            request.ppeDescription = formik.values.ppeDescription;
            await TrainerService.createProcedure(request)
                .then((response) => {
                    responseData.procedureId = response.data.data.procedureId;
                    trackEvent(AmplitudeEvents.CREATE_PROCEDURE, {
                        created_by: localStorage.getItem('email'),
                        work_instruction_id: response.data.data.workInstructionId,
                        procedure_name: response.data.data.procedureName,
                        procedure_id: response.data.data.procedureId,
                    });

                    if (!response.data.data.is2d) {
                        fileUpload.dispatch({
                            type: 'selectedProcedure',
                            payload: responseData.procedureId,
                        });

                        fileUpload.dispatch({ type: 'canUpload', payload: true });
                        if (visibilityScope === PROCEDURE_VISIBILITY_SCOPE.GLOBAL) {
                            navigate(`/trainer/home/${companyId}`);
                        } else {
                            navigate(`/trainer/procedures/${companyId}/${workInstructionId}`);
                        }
                    }
                    uploadReferenceDocument();
                })
                .catch(({ response }) => {
                    console.log(response);
                })
                .finally(() => {
                    //setContentReady(true);
                });
        } else {
            await TrainerService.createWorkInstruction(props.workInstructionData)
                .then(async (response) => {
                    responseData.workInstructionId = response.data.data.workInstructionId;
                    request = formRequestObj(props.procedureData, responseData.workInstructionId);

                    trackEvent(AmplitudeEvents.CREATE_WORK_INSTRUCTION, {
                        created_by: localStorage.getItem('email'),
                        work_instruction_id: response.data.data.workInstructionId,
                        work_instruction_name: response.data.data.workInstructionName,
                    });
                    request.signOffUsers = request.signOffUsers.map((user) => user.userId);
                    request.ppeTitle = formik.values.ppeTitle;
                    request.ppeDescription = formik.values.ppeDescription;
                    return await TrainerService.createProcedure(request).then((response) => {
                        responseData.procedureId = response.data.data.procedureId;
                        if (!response.data.data.is2d) {
                            fileUpload.dispatch({
                                type: 'selectedProcedure',
                                payload: responseData.procedureId,
                            });
                            fileUpload.dispatch({ type: 'canUpload', payload: true });
                        }
                        trackEvent(AmplitudeEvents.CREATE_PROCEDURE, {
                            created_by: localStorage.getItem('email'),
                            work_instruction_id: response.data.data.workInstructionId,
                            procedure_name: response.data.data.procedureName,
                            procedure_id: response.data.data.procedureId,
                        });
                        navigate(`/trainer/procedures/${companyId}/${responseData.workInstructionId}`);
                        uploadReferenceDocument();
                    });
                })
                .catch((response) => {
                    console.log(response);
                })
                .finally(() => {
                    //setContentReady(true);
                });
        }
    };

    const { isTourOpen, tourStep, setTourStep, setIsTourOpen, setTourTitle } = useTour();
    const handleSave = async () => {
        if (!props.procedureDetails) return;
        setContentReady(false);
        const request = Object.create(props.procedureDetails);
        request.ppeEquipments = [...checked];
        request.procedureName = props.procedureData.procedureName;
        request.signOffUsers = props.procedureData.signOffUsers.map((user) => user.userId);
        request.ppeTitle = formik.values.ppeTitle;
        request.ppeDescription = formik.values.ppeDescription;
        request.documentId = props.procedureData.documentId;
        request.language = props.procedureData.language;
        request.isPrerequisite = props.procedureData.procedureType === 'Pre Requisite';
        request.estimated_time = props.procedureData.hours * 60 * 60 + props.procedureData.minutes * 60 + props.procedureData.seconds;
        request.procedureId = props.procedureDetails.procedureId;
        request.workInstructionId = props.procedureDetails.workInstructionId;
        uploadReferenceDocument();
        await TrainerService.editProcedure(props.procedureDetails.procedureId, request)
            .then((response) => {
                if (props.procedureData.visibilityScope === PROCEDURE_VISIBILITY_SCOPE.GLOBAL) navigate(`/trainer/home/${companyId}`);
                else navigate(`/trainer/procedures/${companyId}/${props.procedureDetails?.workInstructionId}`);
            })
            .finally(() => {
                setContentReady(true);
            });
    };

    const formik = useFormik({
        initialValues: {
            checked: [],
            ppeTitle: '',
            ppeDescription: '',
        },

        onSubmit: () => {},
    });

    const handleBackButtonClick = () => {
        props.onGoBack(checked);
    };

    const handlePpeValues = (event) => {
        var updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, event.target.value];
        } else {
            updatedList.splice(checked.indexOf(event.target.value), 1);
        }
        setChecked(updatedList);
    };

    useEffect(() => {
        getPpeList();
        if (props.procedureDetails) {
            getExistingPpe(props.procedureDetails);
        }
    }, []);

    useEffect(() => {
        formik.setValues({
            ...formik.values,
            ppeTitle: props?.procedureDetails?.ppeTitle ?? 'Safety manual and requirements',
            ppeDescription: props?.procedureDetails?.ppeDescription ?? 'Safety manual and any local safety requirements must be consulted and followed before initiating work. Wear appropriate personal protective equipment.',
        });
    }, []);

    const getExistingPpe = (procedureDetails) => {
        TrainerService.getPPEOfProcedure(procedureDetails.procedureId)
            .then((res) => {
                const Ids = res.data.data.map((object) => object.id);
                setChecked(Ids);
            })
            .catch(({ response }) => {
                Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
            });
    };

    const getPpeList = () => {
        setContentReady(false);
        TrainerService.getAllPPEEquipments()
            .then((response) => {
                setPpeList(response.data.data);
                setSharedPpeList(response.data.data);
                setContentReady(true);
            })
            .catch(({ response }) => {
                setContentReady(true);
                Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
            });
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Card
                    className="procedure-create-card"
                    elevation={3}
                    style={{
                        borderRadius: '12px',
                        maxWidth: '548px',
                        minWidth: '548px',
                        padding: '16px',
                        margin: '10px 10px 20px 10px',
                    }}
                >
                    <Box
                        component="div"
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                        }}
                    >
                        <Typography sx={{ fontSize: '20px', padding: '16px', textAlign: 'left' }}>{t('ADD_WI.SELECT_PPE')}</Typography>
                        {checked.length > 0 && (
                            <Typography sx={{ fontSize: '20px', padding: '16px', opacity: 0.5 }}>
                                {checked.length} {t('COMMON.SELECTED') as string}
                            </Typography>
                        )}
                    </Box>
                    <FormControl
                        sx={{ fontSize: '20px', padding: '16px', textAlign: 'left' }}
                        className="tour-instruction-name"
                    >
                        <FormLabel
                            component="legend"
                            className="formStyle"
                            sx={{
                                color: 'rgba(28, 29, 31, .8)',
                                fontSize: '12px',
                                fontWeight: '500',
                                paddingBottom: '8px',
                            }}
                        >
                            {t('ADD_WI.PPE_NAME') as string}
                        </FormLabel>
                        <TextField
                            label=""
                            name="ppeTitle"
                            onChange={formik.handleChange}
                            value={formik.values.ppeTitle}
                            placeholder={t('ADD_WI.PPE_NAME') as string}
                            id="component-outlined"
                            style={{ borderRadius: '8px !important' }}
                            inputProps={{ maxLength: 55 }}
                            required
                        />
                        <FormHelperText
                            id="component-helper-text"
                            className="helperText"
                        >
                            {55 - formik.values.ppeTitle.length} {t('COMMON.CHARACTERS_LEFT') as string}
                        </FormHelperText>
                    </FormControl>
                    <FormControl
                        sx={{ fontSize: '20px', padding: '16px', textAlign: 'left' }}
                        className="tour-instruction-desc"
                    >
                        <FormLabel
                            component="legend"
                            className="formStyle"
                            sx={{
                                color: 'rgba(28, 29, 31, .8)',
                                fontSize: '12px',
                                fontWeight: '500',
                                paddingBottom: '8px',
                            }}
                        >
                            {t('ADD_WI.PPE_DESCRIPTION') as string}
                        </FormLabel>
                        <TextField
                            value={formik.values.ppeDescription}
                            name="ppeDescription"
                            id="outlined-multiline-static"
                            onChange={formik.handleChange}
                            placeholder={t('ADD_WI.PPE_DESCRIPTION') as string}
                            multiline
                            rows={4}
                            inputProps={{ maxLength: 170 }}
                            required
                        />
                        <FormHelperText
                            id="component-helper-text"
                            className="helperText"
                        >
                            {170 - formik.values.ppeDescription.length} {t('COMMON.CHARACTERS_LEFT') as string}
                        </FormHelperText>
                    </FormControl>
                    <PPEListComp
                        standardPpeCount={isTourOpen ? 10 : standardPpeCount}
                        customPpeCount={isTourOpen ? 0 : customPpeCount}
                        checked={checked}
                        ppeList={isTourOpen ? tourPpeList : sharedPpeList}
                        getPpeList={getPpeList}
                        handlePpeValues={handlePpeValues}
                        contentReady={contentReady}
                    />

                    <CardActions sx={{ justifyContent: 'flex-end', margin: '12px' }}>
                        {props.showBackButton && (
                            <IconButton
                                size="small"
                                sx={{ float: 'right', borderRadius: 1 }}
                                title="Back"
                                onClick={handleBackButtonClick}
                            >
                                <LeftArrow />
                            </IconButton>
                        )}
                        {props.procedureDetails === null && (
                            <AddNewPpe
                                customPpeCount={customPpeCount}
                                title={t('COMMON.ADD_PPE') as string}
                                getPpeList={getPpeList}
                            >
                                <Button
                                    size="small"
                                    variant="outlined"
                                    sx={{ float: 'right', margin: '0px 6px' }}
                                    type="submit"
                                    disabled={!contentReady}
                                >
                                    {t('COMMON.ADD_PPE') as string}
                                </Button>
                            </AddNewPpe>
                        )}
                        {props.procedureDetails === null && (
                            <Button
                                size="small"
                                className="tour-ppe-finish"
                                variant="contained"
                                sx={{ float: 'right' }}
                                type="submit"
                                disabled={!contentReady}
                                onClick={handleFinish}
                            >
                                {checked.length > 0 ? `${t('BUTTON.FINISH')} (${checked.length})` : t('BUTTON.FINISH')}
                            </Button>
                        )}

                        {props.procedureDetails !== null && (
                            <Button
                                size="small"
                                variant="contained"
                                sx={{ float: 'right' }}
                                type="submit"
                                onClick={handleSave}
                            >
                                {checked.length > 0 ? `${t('BUTTON.SAVE')} (${checked.length})` : t('BUTTON.SAVE')}
                            </Button>
                        )}
                    </CardActions>
                </Card>
            </form>
        </>
    );
};

export default AddPPE;
