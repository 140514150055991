import { Backdrop, Button } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicDialog } from '../BasicDialog/BasicDialog';
import TrainerService from '../../../../services/trainer/TrainerService';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import Loading from '../../../Loading/Loading';
import { closeSnackbar } from 'notistack';
import { Toast } from '../../../Notifications/Notification';
import { Download, PictureAsPdf } from '@mui/icons-material';

export const ExportPDFDialog = (props) => {
    const { procedureId, procedureName } = props;
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();
    const exportAsStandard = () => {
        setLoading(true);
        TrainerService.exportProcedureConcisePdf(procedureId)
            .then(successHandler)
            .catch(errorHandler);
        props.closeDialog();
    };

    const exportAsDetailed = () => {
        setLoading(true);
        TrainerService.exportProcedurePdf(procedureId)
            .then(successHandler)
            .catch(errorHandler);
        props.closeDialog();
    };

    const successHandler = (type) => {
        setLoading(false);
        Toast.success(t('PROCEDURE.REQUEST_SUBMITTED'), t("PROCEDURE.REQUEST_SUBMITTED_MESSAGE"))
    }
    const errorHandler = ({ response }) => {
        setLoading(false);
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response?.data?.messageCode, { ns: 'error' }) || response?.data?.errorMessage);
    }
    return (
        <>
            {loading && (
                <Backdrop
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 100 }}
                    open={loading}
                >
                    <Loading />
                </Backdrop>
            )}
            <BasicDialog
                title={t('PROCEDURE.EXPORT_PDF')}
                onClose={() => {
                    props.closeDialog();
                }}
                onOpen={props.openDialog}
                actions={
                    <div style={{ display: 'flex', width: '100%', gap: '10px' }}>
                        <Button
                            variant="outlined"
                            onClick={exportAsStandard}
                            startIcon={<PictureAsPdf />}
                            endIcon={<Download />}
                        >
                            {t('BUTTON.STANDARD_WORK_SHEET')}
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={exportAsDetailed}
                            startIcon={<PictureAsPdf />}
                            endIcon={<Download />}
                        >
                            {t('BUTTON.DETAILED_PROCEDURE')}
                        </Button>
                    </div>
                }
            ></BasicDialog>
        </>
    );
};
