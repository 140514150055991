import { Box, Card, Grid, Skeleton } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getVisitHistoryOverview } from '../../services/VisitHistory/VisitHistoryService';
import RadialBarGraph from '../Charts/RadialBarGraph';
import { IGetVisitOverviewResponse } from './constants';

const OverviewComponentLoadingState = () => {
    return (
        <Grid
            paddingLeft="24px"
            container
            columnGap={'20px'}
            sx={{ width: '100%' }}
        >
            <Grid
                item
                xs={5.8}
            >
                <Skeleton
                    width={'100%'}
                    height={'250px'}
                />
            </Grid>
            <Grid
                item
                xs={5.8}
            >
                <Skeleton
                    width={'100%'}
                    height={'250px'}
                />
            </Grid>
        </Grid>
    );
};

const VisitHistoryOverview = (props) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const previousController = useRef<any>();
    const [visitHistoryOverviewData, setVisitHistoryOverviewData] = useState<IGetVisitOverviewResponse>({
        totalProcedures: 0,
        visitedProcedures: 0,
        totalUsers: 0,
        activeUsers: 0,
    });

    useEffect(() => {
        if ((!props.dateRange.startDate && !props.dateRange.endDate) || (props.dateRange.startDate && props.dateRange.endDate)) {
            if (previousController.current) {
                previousController.current.abort();
            }
            const controller = new AbortController();
            const signal = controller.signal;
            previousController.current = controller;
            setLoading(true);
            getVisitHistoryOverview(props.dateRange, signal)
                .then((data) => {
                    if (data) setVisitHistoryOverviewData(data.data);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [props.dateRange]);

    return (
        <>
            {loading ? (
                <OverviewComponentLoadingState />
            ) : (
                <Box sx={{ paddingTop: '16px', width: '100%', display: 'flex', gap: '24px' }}>
                    <Card
                        sx={{ position: 'relative', borderRadius: '12px', boxShadow: 'rgba(149, 157, 165, 0.5) 0px 8px 24px', padding: '16px 24px', width: '100%' }}
                        elevation={0}
                    >
                        <RadialBarGraph
                            value={visitHistoryOverviewData.visitedProcedures}
                            total={visitHistoryOverviewData.totalProcedures}
                            title={t('COMMON.PUBLISHED_PROCEDURES')}
                            legends={[
                                {
                                    label: t('COMMON.VISITED'),
                                    value: visitHistoryOverviewData.visitedProcedures,
                                    // percentage: `${Math.round(((visitHistoryOverviewData.visitedProcedures / visitHistoryOverviewData.totalProcedures) * 100 + Number.EPSILON) * 100) / 100}%`,
                                    percentage: (() => {
                                        const percentage = (visitHistoryOverviewData.visitedProcedures / visitHistoryOverviewData.totalProcedures) * 100;
                                        const roundedPercentage = Math.round((percentage + Number.EPSILON) * 100) / 100;
                                        return isNaN(roundedPercentage) ? '0%' : `${roundedPercentage}%`;
                                    })(),
                                    circleColor: '#219653',
                                },
                            ]}
                        />
                    </Card>

                    <Card
                        sx={{ position: 'relative', borderRadius: '12px', boxShadow: 'rgba(149, 157, 165, 0.5) 0px 8px 24px', padding: '16px 24px', width: '100%' }}
                        elevation={0}
                    >
                        <RadialBarGraph
                            value={visitHistoryOverviewData.activeUsers}
                            total={visitHistoryOverviewData.totalUsers}
                            title={t('COMMON.USERS')}
                            legends={[
                                {
                                    label: t('COMMON.LOGGED_IN_USERS'),
                                    value: visitHistoryOverviewData.activeUsers,
                                    // percentage: `${Math.round(((visitHistoryOverviewData.activeUsers / visitHistoryOverviewData.totalUsers) * 100 + Number.EPSILON) * 100) / 100}%`,
                                    percentage: (() => {
                                        const percentage = (visitHistoryOverviewData.activeUsers / visitHistoryOverviewData.totalUsers) * 100;
                                        const roundedPercentage = Math.round((percentage + Number.EPSILON) * 100) / 100;
                                        return isNaN(roundedPercentage) ? '0%' : `${roundedPercentage}%`;
                                    })(),
                                    circleColor: '#9747FF',
                                },
                            ]}
                        />
                    </Card>
                </Box>
            )}
        </>
    );
};

export default VisitHistoryOverview;
