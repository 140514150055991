import { useContext, useEffect, useRef, useState } from 'react';
import MediaUploadDialog from '../../../Trainer/Dialog/MediaUploadDialog/MediaUploadDialog';
import { DialogTitle, Drawer, FormControl, FormControlLabel, IconButton, InputAdornment, List, OutlinedInput, Typography, FormHelperText, Box, Button, DialogActions, CircularProgress, useTheme, Grid, Divider } from '@mui/material';
import { ArrowDownIcon, ArrowUpIcon, CloseIcon, MediaUploadIcon, ReplyCommentIcon, RightArrowIcon } from '../../../Icons/CdsIcons';
import { useTranslation } from 'react-i18next';
import MediaRef from '../../../Trainer/Dialog/MediaUploadDialog/MediaRef';
import Loading from '../../../Loading/Loading';
import { useFormik } from 'formik';
import { environment } from '../../../../environment/environment';
import { Toast } from '../../../Notifications/Notification';
import FileManagerService from '../../../../services/FileManager/FileManagerService';
import TutorialService from '../../../../services/trainee/TraineeService';
import UserContext from '../../../../context/UserContext';
import { getFormattedTimeFromUnixTime } from '../../../../shared/utils/formateDate';
import { convertFileBlobToMediaType } from '../../../../services/Mapper/Mapper';
import Avatar from './Avatar';
import CommentCrud from './CommentCrud';
import { CommentFilePreview } from './CommonFilePreview';
import { useLocation, useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import TrainerService from '../../../../services/trainer/TrainerService';
import { ThreadComment } from './ThreadComment';

export const CommentSideBar = (props) => {
    const [showMediaUploadDialog, setShowMediaUploadDialog] = useState<boolean>(false);
    const [selectedFiles, setSelectedFiles] = useState<Array<File>>([]);
    const [loading, setLoading] = useState(false);
    const [isThreadExist, setIsThreadExist] = useState(false);
    const [mediaFiles, setMediaFiles] = useState<Array<File>>([]);
    const studioUrl = environment.AppConfigs.studioURL;
    const [mentorIds, setMentorIds] = useState<Array<any>>([]);
    const [stepComments, setstepComments] = useState<any>([]);
    const [submitDisableButton, setSubmitDisableButton] = useState<boolean>(false);
    const [is2d, setIs2d] = useState<boolean>(false);
    const [disableThreadComment, setDisableThreadComment] = useState<boolean>(false);
    const [editComment, setEditComment] = useState<string>('');
    const [editCommentPayload, setEditCommentPayload] = useState<any>(null);

    const [showMoreCommentOnThread, setShowMoreCommentOnThread] = useState<boolean>(false);
    const [replyCommentHandler, setReplyCommentHandler] = useState<boolean>(false);
    const [isButtonFirstBelow, setIsButtonFirstBelow] = useState(false);
    const [isHoverCommentCrud, setIsHoverCommentCrud] = useState(false);
    const [editThreadId, setEditThreadId] = useState<any>('');

    const listStepCommentThread = (threadId: string) => {
        setLoading(true);
        TutorialService.listStepComments(threadId)
            .then((e) => {
                setstepComments(e?.data?.data?.comments);
                setIs2d(e?.data?.data?.is2d);
                setIsThreadExist(true);
                setLoading(false);
            })
            .catch(({ response }) => {
                Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response?.data?.messageCode, { ns: 'error' }) || response?.data?.errorMessage);
                setLoading(false);
            });
    };

    const threadCommentClickHandler = () => {
        setDisableThreadComment(true);
    };

    useEffect(() => {
        if (props.activeThreadId && props.showRightPaneComment) {
            listStepCommentThread(props.activeThreadId);
        }
    }, [props.activeThreadId, props.showRightPaneComment]);

    const handleMediaUploadDialog = () => {
        setShowMediaUploadDialog(true);
    };
    const closeMediaDialogHandler = () => {
        setShowMediaUploadDialog(false);
    };

    const handleSelectedFiles = (files) => {
        setSelectedFiles(files);
    };
    const { t } = useTranslation();
    const toggleDrawerComment = (status) => {
        props.setShowRightPaneComment(status);

        if (status === false && props.setActiveRow) {
            props.setActiveRow(-1);
        }
    };

    const { currentUser } = useContext(UserContext);
    const submitComment = () => {
        if (formik.values.commentText) {
            setSubmitDisableButton(true);
            setMentorIds([]);
            saveCurrentMediaRef(selectedFiles[0], true);
            formik.setFieldValue('commentMedia', []);
            setSelectedFiles([]);
        }
        if (formikEdit.values.commentText) {
            setSubmitDisableButton(true);
            setMentorIds([]);
            saveCurrentMediaRef(selectedFiles[0], true);
            formik.setFieldValue('commentMedia', []);
            setSelectedFiles([]);
        }
    };
    useEffect(() => {
        if (mediaFiles.length > 0) {
            saveCurrentMediaRef(mediaFiles[0], false);
        } else {
            if (formik.values.commentText) {
                let data = {
                    comment: formik.values.commentText,
                    files: mentorIds,
                };
                formik.setFieldValue('commentText', '');
                if (props?.activeThreadId) {
                    TutorialService.addCommentToThread(props.activeThreadId, data)
                        .then(() => {
                            setIsButtonFirstBelow(true);
                            setReplyCommentHandler(!replyCommentHandler);
                            setDisableThreadComment(false);
                            Toast.success(t('BUTTON.OPERATION_SUCCESS'), t('COMMON.COMMENT_ADDED_SUCCESSFULLY'));
                            listStepCommentThread(props.activeThreadId);
                            setSubmitDisableButton(false);
                        })
                        .catch(({ response }) => {
                            setSubmitDisableButton(false);
                            Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
                        });
                } else {
                    TutorialService.addCommentToStep(props.procedure.procedureId, props.stepIndex, data)
                        .then(() => {
                            if (props.activeThreadId) {
                                listStepCommentThread(props.activeThreadId);
                            } else {
                                props.setThreadUpdate(true);
                            }

                            setIsThreadExist(true);
                            setSubmitDisableButton(false);
                            setIsButtonFirstBelow(true);
                            setReplyCommentHandler(!replyCommentHandler);
                            setDisableThreadComment(false);
                            Toast.success(t('BUTTON.OPERATION_SUCCESS'), t('COMMON.THREAD_ADDED_SUCCESSFULLY'));
                        })
                        .catch(({ response }) => {
                            setSubmitDisableButton(false);
                            Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
                        });
                }
            }

            if (formikEdit.values.commentText) {
                let data = {
                    comment: formikEdit.values.commentText,
                    files: mentorIds,
                    createdOn: editCommentPayload?.createdOn,
                    commentId: editCommentPayload?.commentId,
                };

                formikEdit.setFieldValue('commentText', '');
                if (props?.activeThreadId || editThreadId) {
                    const threadId = props?.activeThreadId ? props?.activeThreadId : editThreadId;
                    TrainerService.editStepComment(threadId, data)
                        .then(() => {
                            setIsButtonFirstBelow(true);

                            setDisableThreadComment(false);
                            disableThreadCommentEditHandler();
                            Toast.success(t('BUTTON.OPERATION_SUCCESS'), t('COMMON.THREAD_MODIFIED_SUCCESSFULLY'));
                            if (props.listAllThreads) {
                                props.listAllThreads();
                            }
                            listStepCommentThread(threadId);
                            setSubmitDisableButton(false);
                        })
                        .catch(({ response }) => {
                            setSubmitDisableButton(false);
                            Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
                        });
                }
            }
        }
    }, [mediaFiles]);

    async function saveCurrentMediaRef(file, initial) {
        if (file) {
            if (!file?.fileId) {
                const metaData = { organizationId: currentUser?.organizationId, workInstructionId: props?.activeThreadData?.workInstructionId };
                await FileManagerService.fileUploadBlob(file, metaData).then((response: any) => {
                    const mentorID = response.data.id; // file id

                    setMentorIds((mentorids) => [...mentorids, { fileId: mentorID, fileName: file.name, mimeType: file?.type, isBlobUpload: true }]);
                });
                setSelectedFiles([]);
                if (file.url) {
                    let shortUrl = file.url.substring(0, 15);
                    shortUrl = shortUrl.concat('  .....');
                    Toast.success(t('COMMON.SAVING_LINK'), `${file?.name}`);
                } else {
                    Toast.success(t('COMMON.UPLOADING_FILE'), `${file?.name}`);
                }
            }

            if (file?.fileId) {
                setMentorIds((mentorids) => [...mentorids, { fileId: file.fileId, fileName: file.name, mimeType: file?.mimeType, isBlobUpload: true }]);
            }
        } else {
            setSelectedFiles([]);
        }
        if (initial) {
            setMediaFiles(selectedFiles.slice(1));
        } else {
            setMediaFiles(mediaFiles.slice(1));
        }
    }

    const navigate = useNavigate();

    const redirectToComment = () => {
        const companyId = localStorage.getItem('company');
        if (props.isTrainer) {
            var query = new URLSearchParams();
            query.append('procedureId', props?.activeThreadData?.procedureId);
            query.append('model', props?.activeThreadData?.workInstructionId);
            query.append('org', currentUser?.organizationId);
            if (is2d) {
                query.append('workInstructionType', '2d');
            }
            query.append('isQueryExist', 'true');
            query.append('stepNo', props?.activeThreadData?.stepNo);
            // query.append('authToken', authToken);
            var url = `${studioUrl}${query.toString()}`;
            window.open(url, '_blank', 'noopener,noreferrer');
        } else {
            let procedureId = props?.activeThreadData?.procedureId;
            let workInstructionId = props?.activeThreadData?.workInstructionId;
            let is2d = props?.activeThreadData?.is2d;

            if (props?.activeThreadData?.is2d) {
                navigate(`/assembly/${companyId}/${workInstructionId}/${procedureId}?workInstructionType=${is2d ? '2d' : '3d'}&isViewComment=true&stepNo=${props?.activeThreadData?.stepNo}`);
            } else {
                window.location.href = `/assembly/${companyId}/${workInstructionId}/${procedureId}?workInstructionType=${is2d ? '2d' : '3d'}&isViewComment=true&stepNo=${props?.activeThreadData?.stepNo}`;
            }
        }
    };

    const formik = useFormik({
        initialValues: {
            commentText: '',
            commentMedia: [],
        },

        onSubmit: () => {},
    });

    // useEffect(() => {
    //   formik.setValues({
    //     ...formik.values,
    //     commentText: '',
    //     commentMedia: [],
    //   });
    // }, []);
    // useEffect(() => {
    //   formikEdit.setValues({
    //     ...formikEdit.values,
    //     commentText: '',
    //     commentMedia: [],
    //   });
    // }, []);
    const theme = useTheme();

    const formikEdit = useFormik({
        initialValues: {
            commentText: '',
            commentMedia: [],
        },

        onSubmit: () => {},
    });

    const handleEditComment = (comment, isEditing, threadId?: any) => {
        if (threadId) {
            setEditThreadId(threadId);
        }
        setEditComment(comment.commentId);
        setEditCommentPayload(comment);
        formikEdit.setValues({
            ...formik.values,
            commentText: comment.comment,
        });
        setSelectedFiles(comment.files);
    };
    const disableThreadCommentEditHandler = () => {
        setEditComment('');
        formikEdit.setFieldValue('commentMedia', []);
        setSelectedFiles([]);
        formikEdit.setFieldValue('commentText', '');
    };

    function shadeColor(color, percent) {
        var R = parseInt(color.substring(1, 3), 16);
        var G = parseInt(color.substring(3, 5), 16);
        var B = parseInt(color.substring(5, 7), 16);

        R = (R * (100 - percent) + percent * 255) / 100;
        G = (G * (100 - percent) + percent * 255) / 100;
        B = (B * (100 - percent) + percent * 255) / 100;

        R = R < 255 ? R : 255;
        G = G < 255 ? G : 255;
        B = B < 255 ? B : 255;
        R = Math.round(R);
        G = Math.round(G);
        B = Math.round(B);

        var RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
        var GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
        var BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

        return '#' + RR + GG + BB;
    }
    const themePrimaryExtraLight = shadeColor(theme.palette.primary.main, 87);

    const showCommentOnThreadHandler = () => {
        setShowMoreCommentOnThread(!showMoreCommentOnThread);
    };

    const showCommentonThreadReplyHandler = () => {
        setIsButtonFirstBelow(true);
        setReplyCommentHandler(!replyCommentHandler);
        setDisableThreadComment(false);
        formik.setFieldValue('commentMedia', []);
        setSelectedFiles([]);
        formik.setFieldValue('commentText', '');
    };
    const notificationPath = new RegExp('/notification');
    const location = useLocation();
    const handleMouseEnter = (e) => {
        setIsHoverCommentCrud(true);
    };

    const handleMouseLeave = (e) => {
        setIsHoverCommentCrud(false);
    };

    const wrapperRef: any = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                formik.handleSubmit();
            }
        };

        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                if (formik.values.commentText) {
                    formik.handleSubmit();
                }
                if (formikEdit.values.commentText) {
                    formikEdit.handleSubmit();
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [formik.values.commentText, formikEdit.values.commentText]);

    return (
        <>
            {showMediaUploadDialog && (
                <MediaUploadDialog
                    closeDialog={closeMediaDialogHandler}
                    openDialog={showMediaUploadDialog}
                    editMode={false}
                    style={{ padding: '24px' }}
                    loading={loading}
                    selectedFiles={selectedFiles}
                    setSelectedFiles={handleSelectedFiles}
                />
            )}

            <Drawer
                anchor={'right'}
                open={props.showRightPaneComment}
                onClose={() => toggleDrawerComment(false)}
                PaperProps={{
                    sx: { width: '432px', padding: window.location.pathname === '/notifications' ? '24px' : null },
                }}
            >
                {props.threadLoading && (
                    <Box sx={{ position: 'relative', height: '100%' }}>
                        <Loading />
                    </Box>
                )}

                {!props.threadLoading &&
                    (loading ? (
                        <Loading />
                    ) : (
                        <>
                            <>
                                <DialogTitle sx={{ fontSize: '16px', padding: '2px 12px 2px 12px', marginBottom: '16px' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', top: '14px', left: '12px' }}>
                                        <Typography sx={{ width: '150px', fontWeight: '600', fontSize: '18px', lineHeight: '21.6px' }}>{t('NAVBAR.COMMENTS')}</Typography>
                                        <Typography sx={{ display: 'flex', flexDirection: 'row' }}>
                                            {props.isTrainer && (
                                                <Button
                                                    sx={{ display: 'flex', flexDirection: 'row' }}
                                                    onClick={redirectToComment}
                                                >
                                                    <Typography
                                                        variant="h6"
                                                        sx={{ fontSize: '16px', color: theme.palette.primary.main, marginRight: '8px' }}
                                                    >
                                                        {t('COMMON.OPEN_IN_STUDIO')}
                                                    </Typography>
                                                    <RightArrowIcon />
                                                </Button>
                                            )}
                                            {!props.isTrainer && notificationPath.test(location.pathname) && (
                                                <Button
                                                    sx={{ display: 'flex', flexDirection: 'row' }}
                                                    onClick={redirectToComment}
                                                >
                                                    <Typography
                                                        variant="h6"
                                                        sx={{ fontSize: '16px', color: theme.palette.primary.main, marginRight: '8px' }}
                                                    >
                                                        {t('BUTTON.PREVIEW')}
                                                    </Typography>
                                                    <RightArrowIcon />
                                                </Button>
                                            )}
                                            {!notificationPath.test(location.pathname) && (
                                                <Button
                                                    sx={{ display: 'flex', flexDirection: 'row' }}
                                                    onClick={showCommentonThreadReplyHandler}
                                                >
                                                    <Typography
                                                        variant="h6"
                                                        sx={{ fontSize: '16px', color: theme.palette.primary.main, marginRight: '8px' }}
                                                    >
                                                        {t('COMMON.NEW_COMMENT')}
                                                    </Typography>
                                                    <AddIcon />
                                                </Button>
                                            )}
                                            <IconButton
                                                aria-label="close"
                                                onClick={() => toggleDrawerComment(false)}
                                                sx={{ marginLeft: '16px', color: (theme) => theme.palette.grey[500], padding: 0 }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </Typography>
                                    </Box>
                                </DialogTitle>

                                {notificationPath.test(location.pathname) && (
                                    <>
                                        <List key="listTitle">
                                            <Grid sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                                <Grid>
                                                    <Avatar
                                                        firstName={stepComments[stepComments.length - 1]?.userFirstName}
                                                        lastName={stepComments[stepComments.length - 1]?.userLastName}
                                                        isRead={stepComments[stepComments.length - 1]?.isRead}
                                                        color={theme.palette.primary.main}
                                                        role="trainer"
                                                        sx={{ width: '24px', height: '24px', borderRadius: '18px', marginRight: '4px', marginLeft: '6px' }}
                                                    />
                                                </Grid>
                                                <Grid sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    <Grid sx={{ justifyContent: 'left' }}>
                                                        <Typography sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left', marginLeft: '0.5em', fontSize: '14px', color: '#12181F' }}>{stepComments ? `${stepComments[stepComments.length - 1]?.userFirstName} ${stepComments[stepComments.length - 1]?.userLastName}` : null} </Typography>

                                                        <Typography sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left', marginLeft: '0.5em', fontWeight: '400', fontSize: '14px', color: '#12181F66' }}>{stepComments ? `${getFormattedTimeFromUnixTime(stepComments[stepComments.length - 1]?.createdOn)}` : null}</Typography>
                                                    </Grid>
                                                    <Grid>
                                                        <Button
                                                            sx={{
                                                                color: `${theme.palette.primary.main}`,
                                                                padding: '4px 8px',
                                                                minWidth: 'auto',
                                                            }}
                                                            onClick={showCommentonThreadReplyHandler}
                                                        >
                                                            <Typography sx={{ fontSize: '14px', position: 'relative' }}>{!replyCommentHandler ? <ReplyCommentIcon /> : null}</Typography>
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                {/* <CommentCrud
                        comment={stepComments[stepComments.length - 1]}
                        threadId={props.activeThreadId}
                        listStepCommentThread={listStepCommentThread}
                        editComment={editComment}
                        setEditComment={() => handleEditComment(stepComments[stepComments.length - 1], true)}
                      /> */}
                                            </Grid>
                                            <Typography sx={{ ml: '4px', marginTop: '16px', width: '376px', fontWeight: '400', fontSize: '14px', color: '#12181F99' }}>{stepComments ? `${stepComments[stepComments.length - 1]?.comment}` : null}</Typography>
                                        </List>
                                        <Box
                                            component={'div'}
                                            sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}
                                        >
                                            {stepComments ? stepComments[stepComments.length - 1]?.files?.map((file, index) => <CommentFilePreview file={file} />) : null}
                                        </Box>

                                        {stepComments.length - 1 > 0 && (
                                            <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Button
                                                    sx={{
                                                        color: `${theme.palette.primary.main}`,
                                                        fontSize: '14px',
                                                        padding: '4px 8px',
                                                        minWidth: 'auto',
                                                    }}
                                                    onClick={showCommentOnThreadHandler}
                                                >
                                                    <Typography sx={{ marginRight: '5px' }}>{showMoreCommentOnThread ? t('COMMON.SHOW_LESS') : `${stepComments.length - 1 > 0 ? stepComments.length - 1 : ''} ${stepComments.length - 1 > 1 ? t('COMMON.REPLIES') : t('COMMON.REPLY')}`}</Typography>
                                                    {showMoreCommentOnThread ? <ArrowUpIcon color={theme.palette.primary.main} /> : <ArrowDownIcon color={theme.palette.primary.main} />}
                                                </Button>
                                            </DialogActions>
                                        )}
                                    </>
                                )}
                                {replyCommentHandler && (
                                    
                                    <form onSubmit={formik.handleSubmit}>
                                        <FormControl sx={{ textAlign: 'center', paddingLeft: window.location.pathname !== '/notifications' ? '24px' : null }}>
                                            <FormControlLabel
                                                control={<></>}
                                                label={
                                                    <Typography
                                                        paddingX={2}
                                                        paddingY={1}
                                                        sx={{ float: 'left', mt: '16px', fontSize: '12px', fontWeight: disableThreadComment ? '600' : '400', opacity: '0.8' }}
                                                    >
                                                        {t('COMMON.ADD_COMMENTS')}
                                                    </Typography>
                                                }
                                            />
                                            <OutlinedInput
                                                id="outlined-basic"
                                                multiline
                                                required
                                                onClick={() => {
                                                    threadCommentClickHandler();
                                                }}
                                                minRows={4}
                                                name="commentText"
                                                placeholder={`${t('COMMON.WRITE_COMMENT')}...`}
                                                onChange={formik.handleChange}
                                                value={formik.values.commentText}
                                                sx={{ width: '368px', borderRadius: '8px', borderWidth: disableThreadComment ? '1px' : '0.5px', borderColor: disableThreadComment ? theme.palette.primary.main : 'black' }}
                                                endAdornment={
                                                    <InputAdornment
                                                        position="end"
                                                        sx={{ marginTop: 'auto', marginBottom: '12px' }}
                                                        onClick={handleMediaUploadDialog}
                                                    >
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            edge="end"
                                                        >
                                                            <MediaUploadIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                inputProps={{
                                                    maxLength: 500,
                                                }}
                                            />

                                            <FormHelperText
                                                className="helperText"
                                                sx={{ fontWeight: '400', marginTop: '8px', fontSize: '12px' }}
                                            >
                                                {500 - formik.values.commentText.length} {t('COMMON.CHARACTERS_LEFT')}
                                            </FormHelperText>
                                        </FormControl>
                                        <Box
                                            component={'div'}
                                            sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', paddingLeft: window.location.pathname !== '/notifications' ? '24px' : null }}
                                        >
                                            {selectedFiles?.map((file, index) => {
                                                let fileToShow = convertFileBlobToMediaType(file);
                                                return (
                                                    <MediaRef
                                                        key={index}
                                                        aReference={fileToShow}
                                                        index={index}
                                                        commentMediaRef={true}
                                                    />
                                                );
                                            })}
                                        </Box>
                                        {disableThreadComment && (
                                            <DialogActions style={{ display: 'flex', justifyContent: 'end', marginRight: '27px' }}>
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => {
                                                        showCommentonThreadReplyHandler();
                                                    }}
                                                >
                                                    {t('BUTTON.CANCEL')}
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    sx={{ m: '8px 0px 8px 0px' }}
                                                    disabled={submitDisableButton || formik.values.commentText.length === 0}
                                                    onClick={() => {
                                                        submitComment();
                                                    }}
                                                >
                                                    {submitDisableButton && (
                                                        <CircularProgress
                                                            sx={{ marginRight: '8px' }}
                                                            size="12px"
                                                        />
                                                    )}
                                                    {t('BUTTON.SUBMIT')}
                                                </Button>
                                            </DialogActions>
                                        )}
                                        {window.location.pathname !== '/notifications' && <Divider sx={{ margin: '16px' }} />}
                                    </form>
                                )}
                                {showMoreCommentOnThread &&
                                    stepComments?.slice(0, -1)?.map((comment, index) => {
                                        const isEditing = editComment === comment.commentId;
                                        return (
                                            <div
                                                key={index}
                                                onMouseEnter={(e) => {
                                                    handleMouseEnter(e);
                                                }}
                                                onMouseLeave={(e) => {
                                                    handleMouseLeave(e);
                                                }}
                                            >
                                                {isEditing ? (
                                                    <form onSubmit={formikEdit.handleSubmit}>
                                                        <FormControl sx={{ textAlign: 'center', paddingLeft: window.location.pathname !== '/notifications' ? '24px' : null }}>
                                                            <FormControlLabel
                                                                control={<></>}
                                                                label={
                                                                    <Typography
                                                                        paddingX={2}
                                                                        paddingY={1}
                                                                        sx={{ float: 'left', mt: '16px', fontSize: '12px', fontWeight: disableThreadComment ? '600' : '400', opacity: '0.8' }}
                                                                    >
                                                                        {t('COMMON.ADD_COMMENTS')}
                                                                    </Typography>
                                                                }
                                                            />
                                                            <OutlinedInput
                                                                id="outlined-basic"
                                                                multiline
                                                                required
                                                                onClick={() => {
                                                                    threadCommentClickHandler();
                                                                }}
                                                                minRows={4}
                                                                name="commentText"
                                                                placeholder={`${t('COMMON.WRITE_COMMENT')}...`}
                                                                onChange={formikEdit.handleChange}
                                                                value={formikEdit.values.commentText}
                                                                sx={{ width: '368px', borderRadius: '8px', borderWidth: disableThreadComment ? '1px' : '0.5px', borderColor: disableThreadComment ? theme.palette.primary.main : 'black' }}
                                                                endAdornment={
                                                                    <InputAdornment
                                                                        position="end"
                                                                        sx={{ marginTop: 'auto', marginBottom: '12px' }}
                                                                        onClick={handleMediaUploadDialog}
                                                                    >
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            edge="end"
                                                                        >
                                                                            <MediaUploadIcon />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                                inputProps={{
                                                                    maxLength: 500,
                                                                }}
                                                            />

                                                            <FormHelperText
                                                                className="helperText"
                                                                sx={{ fontWeight: '400', marginTop: '8px', fontSize: '12px' }}
                                                            >
                                                                {500 - formikEdit.values.commentText.length} {t('COMMON.CHARACTERS_LEFT')}
                                                            </FormHelperText>
                                                        </FormControl>
                                                        <Box
                                                            component={'div'}
                                                            sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', paddingLeft: window.location.pathname !== '/notifications' ? '24px' : null }}
                                                        >
                                                            {selectedFiles?.map((file, index) => {
                                                                return (
                                                                    <CommentFilePreview
                                                                        file={file}
                                                                        selectedFiles={selectedFiles}
                                                                        setSelectedFiles={setSelectedFiles}
                                                                    />
                                                                );
                                                            })}
                                                        </Box>

                                                        <DialogActions style={{ display: 'flex', justifyContent: 'end' }}>
                                                            <Button
                                                                variant="outlined"
                                                                onClick={() => {
                                                                    disableThreadCommentEditHandler();
                                                                }}
                                                            >
                                                                {t('BUTTON.CANCEL')}
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                sx={{ m: '8px 0px 8px 0px' }}
                                                                disabled={submitDisableButton || formikEdit.values.commentText.length === 0}
                                                                onClick={() => {
                                                                    submitComment();
                                                                }}
                                                            >
                                                                {submitDisableButton && (
                                                                    <CircularProgress
                                                                        sx={{ marginRight: '8px' }}
                                                                        size="12px"
                                                                    />
                                                                )}
                                                                {t('BUTTON.SUBMIT')}
                                                            </Button>
                                                        </DialogActions>
                                                    </form>
                                                ) : (
                                                    <>
                                                        <List
                                                            key={comment.commentId}
                                                            sx={{ marginLeft: '16px' }}
                                                        >
                                                            <Grid sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                                                <Grid>
                                                                    {' '}
                                                                    <Avatar
                                                                        firstName={comment?.userFirstName}
                                                                        lastName={comment?.userLastName}
                                                                        isRead={comment?.isRead}
                                                                        color={theme.palette.primary.main}
                                                                        role="trainer"
                                                                        sx={{ width: '24px', height: '24px', borderRadius: '18px' }}
                                                                    />
                                                                </Grid>
                                                                <Grid sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                    <Grid sx={{ justifyContent: 'left' }}>
                                                                        <Typography sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left', marginLeft: '0.5em', fontSize: '14px', color: '#12181F' }}>{`${comment?.userFirstName} ${comment?.userLastName}`} </Typography>
                                                                        <Typography sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left', marginLeft: '0.5em', fontWeight: '400', fontSize: '12px', color: '#12181F66' }}>{`${getFormattedTimeFromUnixTime(comment?.createdOn)}`}</Typography>
                                                                    </Grid>
                                                                    <Grid>
                                                                        {isHoverCommentCrud && (
                                                                            <CommentCrud
                                                                                comment={comment}
                                                                                threadId={props.activeThreadId}
                                                                                editComment={editComment}
                                                                                listStepCommentThread={listStepCommentThread}
                                                                                setEditComment={() => handleEditComment(comment, isEditing)}
                                                                            />
                                                                        )}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Typography sx={{ ml: '4px', marginTop: '16px', width: '376px', fontWeight: comment?.isRead ? '400' : '600', fontSize: '14px', color: '#12181F99' }}>{comment.comment}</Typography>
                                                        </List>
                                                        <Box
                                                            component={'div'}
                                                            sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}
                                                        >
                                                            {comment?.files?.map((file, index) => (
                                                                <CommentFilePreview file={file} />
                                                            ))}
                                                        </Box>
                                                    </>
                                                )}
                                            </div>
                                        );
                                    })}

                                {props?.stepCommentThreads?.map((thread, index) => {
                                    const isEditing = editComment === thread.firstComment?.commentId;
                                    return (
                                        <div>
                                            {isEditing ? (
                                                <form
                                                    key={thread._id}
                                                    onSubmit={formikEdit.handleSubmit}
                                                >
                                                    <FormControl sx={{ textAlign: 'center', paddingLeft: window.location.pathname !== '/notifications' ? '24px' : null }}>
                                                        <FormControlLabel
                                                            control={<></>}
                                                            label={
                                                                <Typography
                                                                    paddingX={2}
                                                                    paddingY={1}
                                                                    sx={{ float: 'left', mt: '16px', fontSize: '12px', fontWeight: disableThreadComment ? '600' : '400', opacity: '0.8' }}
                                                                >
                                                                    {t('COMMON.ADD_COMMENTS')}
                                                                </Typography>
                                                            }
                                                        />
                                                        <OutlinedInput
                                                            id="outlined-basic"
                                                            multiline
                                                            required
                                                            onClick={threadCommentClickHandler}
                                                            minRows={4}
                                                            name="commentText"
                                                            placeholder={`${t('COMMON.WRITE_COMMENT')}...`}
                                                            onChange={formikEdit.handleChange}
                                                            value={formikEdit.values.commentText}
                                                            sx={{
                                                                width: '368px',
                                                                borderRadius: '8px',
                                                                borderWidth: disableThreadComment ? '1px' : '0.5px',
                                                                borderColor: disableThreadComment ? theme.palette.primary.main : 'black',
                                                            }}
                                                            endAdornment={
                                                                <InputAdornment
                                                                    position="end"
                                                                    sx={{ marginTop: 'auto', marginBottom: '12px' }}
                                                                    onClick={handleMediaUploadDialog}
                                                                >
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        edge="end"
                                                                    >
                                                                        <MediaUploadIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                            inputProps={{ maxLength: 500 }}
                                                        />
                                                        <FormHelperText
                                                            className="helperText"
                                                            sx={{ fontWeight: '400', marginTop: '8px', fontSize: '12px' }}
                                                        >
                                                            {500 - formikEdit.values.commentText.length} {t('COMMON.CHARACTERS_LEFT')}
                                                        </FormHelperText>
                                                    </FormControl>

                                                    <Box
                                                        component={'div'}
                                                        sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', paddingLeft: window.location.pathname !== '/notifications' ? '24px' : null }}
                                                    >
                                                        {selectedFiles?.map((file, index) => (
                                                            <CommentFilePreview
                                                                key={index}
                                                                file={file}
                                                                selectedFiles={selectedFiles}
                                                                setSelectedFiles={setSelectedFiles}
                                                            />
                                                        ))}
                                                    </Box>

                                                    <DialogActions style={{ display: 'flex', justifyContent: 'end', paddingRight: window.location.pathname !== '/notifications' ? '32px' : '0px' }}>
                                                        <Button
                                                            variant="outlined"
                                                            onClick={disableThreadCommentEditHandler}
                                                        >
                                                            {t('BUTTON.CANCEL')}
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            sx={{ m: '8px 0px 8px 0px' }}
                                                            disabled={submitDisableButton || formikEdit.values.commentText.length === 0}
                                                            onClick={submitComment}
                                                        >
                                                            {submitDisableButton && (
                                                                <CircularProgress
                                                                    sx={{ marginRight: '8px' }}
                                                                    size="12px"
                                                                />
                                                            )}
                                                            {t('BUTTON.SUBMIT')}
                                                        </Button>
                                                    </DialogActions>
                                                </form>
                                            ) : (
                                                <ThreadComment
                                                    // key={thread._id}
                                                    listAllThreads={props.listAllThreads}
                                                    thread={thread}
                                                    index={index}
                                                    setEditComment={() => handleEditComment(thread.firstComment, isEditing, thread._id)}
                                                />
                                            )}
                                        </div>
                                    );
                                })}
                            </>
                        </>
                    ))}
            </Drawer>
        </>
    );
};
