import { Delete, Edit, InsertLink, LinkOutlined, MoreHoriz, Refresh, RemoveRedEye, Share } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import GradingIcon from '@mui/icons-material/Grading';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TimelineIcon from '@mui/icons-material/Timeline';
import TranslateIcon from '@mui/icons-material/Translate';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { IconButton, Link, Menu, MenuItem, SvgIcon, useTheme } from '@mui/material';
import { trackEvent } from '../../../../App';
import { AmplitudeEvents, PROCEDURE_VISIBILITY_SCOPE, ProcedureDuplicationStatus } from '../../../../shared/enums';
import './TrainerProcedureCard.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { t } from 'i18next';
import { PROCEDURE_LIBRARY_DIALOG } from '../../../../pages/Trainer/ProcedureLibrary/constants';
import { setDialogueToOpen, setProcedureToView } from '../../../../redux/features/ModularProcedures/ModularProcedures.slice';
import { openStudioEditorInATab } from './TrainerProcedureCard';
import useCurrentTranslation from '../../../../hooks/useCurrentTranslation';
import TutorialService from '../../../../services/trainee/TraineeService';
import TrainerService from '../../../../services/trainer/TrainerService';
import { Toast } from '../../../Notifications/Notification';

const ProcedurePreview = ({ onClick }) => {
  return (
    <MenuItem onClick={onClick}>
      <RemoveRedEye color="primary" sx={{ mr: 1 }} />
      {t('PROCEDURE.CARD_PREVIEW') as string}
    </MenuItem>
  );
};

const RefreshButton = ({ onClick }) => {
  return (
    <MenuItem onClick={onClick}>
      <Refresh color="primary" sx={{ mr: 1 }} />
      {t('PROCEDURE.REFRESH') as string}
    </MenuItem>
  );
};

// Share QR Code Component
const ShareQRCode = ({ onClick }) => (
  <MenuItem onClick={onClick}>
    <Share color="primary" sx={{ mr: 1 }} />
    {t('PROCEDURE.CARD_SHARE') as string}
  </MenuItem>
);

// Export PDF Component
const ExportPDF = ({ onClick }) => (
  <MenuItem onClick={onClick}>
    <OpenInNewIcon color="primary" sx={{ mr: 1 }} />
    {t('PROCEDURE.EXPORT_PDF') as string}
  </MenuItem>
);

// Edit Steps Component
const EditSteps = ({ onClick }) => (
  <MenuItem onClick={onClick}>
    <Edit color="primary" sx={{ mr: 1 }} />
    {t('PROCEDURE.CARD_EDIT_STEPS') as string}
  </MenuItem>
);

// Periodic Review Component
const PeriodicReview = ({ onClick }) => (
  <MenuItem onClick={onClick}>
    <GradingIcon color="primary" sx={{ mr: 1 }} />
    {t('NAVBAR.PERIODIC_REVIEW') as string}
  </MenuItem>
);

// Recertification Review Component
const RecertificationReview = ({ onClick }) => (
  <MenuItem onClick={onClick}>
    <WorkspacePremiumIcon color="primary" sx={{ mr: 1 }} />
    {t('NAVBAR.RECERTIFICATIONS_REVIEW') as string}
  </MenuItem>
);

// Approve Component
const Approve = ({ onClick }) => (
  <MenuItem onClick={onClick}>
    <CheckCircleOutlineIcon color="primary" sx={{ mr: 1 }} />
    {t('PROCEDURE.APPROVE') as string}
  </MenuItem>
);

// Reject Component
const Reject = ({ onClick }) => (
  <MenuItem onClick={onClick}>
    <HighlightOffIcon color="primary" sx={{ mr: 1 }} />
    {t('ADD_USER.REJECT') as string}
  </MenuItem>
);

// Edit Procedure Component
const EditButton = ({ onClick, procedureDetails }) => {
  const text = useGetTextForButton(procedureDetails);
  return (
    <MenuItem onClick={onClick}>
      <Edit color="primary" sx={{ mr: 1 }} />
      {t('SYSTEM_SETTINGS.EDIT')} {text}
    </MenuItem>
  );
};

const EditStepsButton = ({ onClick }) => {
  return (
    <MenuItem onClick={onClick}>
      <Edit color="primary" sx={{ mr: 1 }} />
      {t('SYSTEM_SETTINGS.ADD_OR_EDIT_STEPS') as string}
    </MenuItem>
  );
}
// Duplicate Procedure Component
const DuplicateButton = ({ onClick, procedureDetails }) => {
  const text = useGetTextForButton(procedureDetails);
  const theme = useTheme();
  return (
    <MenuItem onClick={onClick}>
      <SvgIcon sx={{ mr: 1 }}>
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill={theme.palette.primary.main}>
          <path d="M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360Zm0-80h360v-480H360v480ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Zm160-240v-480 480Z" />
        </svg>
      </SvgIcon>
      {t('SYSTEM_SETTINGS.DUPLICATE')} {text}
    </MenuItem>
  );
};

// AddLanguageSupport Component
const AddLanguageSupport = ({ onClick }) => (
  <MenuItem onClick={onClick}>
    <TranslateIcon color="primary" sx={{ mr: 1 }} />
    {t('PROCEDURE.ADD_LANGUAGE_SUPPORT') as string}
  </MenuItem>
);

// Revision History Component
const RevisionHistory = ({ onClick }) => {
  const theme = useTheme();

  return (
    <MenuItem onClick={onClick}>
      <SvgIcon sx={{ mr: 1 }}>
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill={theme.palette.primary.main}>
          <path d="M480-120q-138 0-240.5-91.5T122-440h82q14 104 92.5 172T480-200q117 0 198.5-81.5T760-480q0-117-81.5-198.5T480-760q-69 0-129 32t-101 88h110v80H120v-240h80v94q51-64 124.5-99T480-840q75 0 140.5 28.5t114 77q48.5 48.5 77 114T840-480q0 75-28.5 140.5t-77 114q-48.5 48.5-114 77T480-120Zm112-192L440-464v-216h80v184l128 128-56 56Z" />
        </svg>
      </SvgIcon>
      {t('PROCEDURE.CARD_REVISION_HISTORY') as string}
    </MenuItem>
  );
};

// View Analytics Component
const ViewAnalytics = ({ onClick }) => (
  <MenuItem onClick={onClick}>
    <TimelineIcon color="primary" sx={{ mr: 1 }} />
    {t('PROCEDURE.VIEW_ANALYTICS') as string}
  </MenuItem>
);

// View Analytics Component
const ShowLinkedWIButton = ({ onClick }) => (
  <MenuItem onClick={onClick}>
    <InsertLink color="primary" sx={{ mr: 1 }} />
    {t('PROCEDURE.SHOW_LINKED_WI') as string}
  </MenuItem>
);

// Revoke Access Component
const RevokeAccess = ({ onClick }) => {
  const {t} = useTranslation();
  const theme = useTheme();
  return (
    <MenuItem onClick={onClick}>
      <SvgIcon sx={{ mr: 1 }}>
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill={theme.palette.primary.main}>
          <path d="M790-57 488-359q-32 54-87 86.5T280-240q-100 0-170-70T40-480q0-66 32.5-121t86.5-87L57-790l57-56 732 733-56 56Zm50-543 120 120-183 183-127-126 50-37 72 54 75-74-40-40H553l-80-80h367ZM280-320q51 0 90.5-27.5T428-419l-56-56-48.5-48.5L275-572l-56-56q-44 18-71.5 57.5T120-480q0 66 47 113t113 47Zm0-80q-33 0-56.5-23.5T200-480q0-33 23.5-56.5T280-560q33 0 56.5 23.5T360-480q0 33-23.5 56.5T280-400Z" />
        </svg>
      </SvgIcon>
      {t("PROCEDURE.REMOVE_ACCESS")}
    </MenuItem>
  );
};

const PublishButton = ({onClick, isDisabled = false}) => {
  const {t} = useTranslation();
  const theme = useTheme();
  return (
    <MenuItem onClick={onClick} disabled={isDisabled}>
      <SvgIcon sx={{ mr: 1 }}>
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill={theme.palette.primary.main}>
          <path d="M440-160v-326L336-382l-56-58 200-200 200 200-56 58-104-104v326h-80ZM160-600v-120q0-33 23.5-56.5T240-800h480q33 0 56.5 23.5T800-720v120h-80v-120H240v120h-80Z" />
        </svg>
      </SvgIcon>
      {t('PROCEDURE.CARD_PUBLISH')}
    </MenuItem>
  );
}

const useGetTextForButton = (procedureDetails) => {
  const { i18n } = useTranslation();
  const { currentTranslation } = useCurrentTranslation();
  const currentLanguage = i18n.language.toUpperCase();
  const systemPrerequisite = currentTranslation?.PREREQUISITES ? currentTranslation.PREREQUISITES[currentLanguage] : `${t('PROCEDURE.PRE_REQUISITES')}`;
  const systemCoreProcedure = currentTranslation?.CORE_PROCEDURES ? currentTranslation.CORE_PROCEDURES[currentLanguage] : `${t('PROCEDURE.CORE_PROCEDURES')}`;

  return procedureDetails.isPrerequisite ? systemPrerequisite : systemCoreProcedure;
};
// Delete Procedure Component
const DeleteButton = ({ onClick, procedureDetails }) => {
  const deleteText = useGetTextForButton(procedureDetails);
  return (
    <MenuItem onClick={onClick}>
      <Delete color="primary" sx={{ mr: 1 }} />
      {t('BUTTON.DELETE')} {deleteText}
    </MenuItem>
  );
};

const MenuList = (props) => {
  const { procedureDetails, workInstructionDetails } = props;
  const { showRefreshButton, setShowRefreshButton, onRefreshClick} = props;
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const { companyId } = useParams<string>();

  const procedureReady = !procedureDetails.duplicateContentCreated || procedureDetails.duplicateContentCreated === ProcedureDuplicationStatus.READY;

  const openDialog = (dialogeToOpen: PROCEDURE_LIBRARY_DIALOG) => {
    dispatch(setDialogueToOpen(dialogeToOpen));
  };
  const handleEditClick = () => {
    navigate(`/trainer/addProcedure/${procedureDetails.workInstructionId}`, { state: { procedureDetails: procedureDetails, visibilityScope: procedureDetails.visibilityScope } });
  };

  const handleRevisionHistoryClick = () => {
    navigate(`/trainer/procedureHistory/${companyId}/${procedureDetails.workInstructionId}/${procedureDetails.procedureId}?workInstructionType=${workInstructionDetails.is2d ? '2d' : '3d'}`, {state: {visibilityScope : procedureDetails.visibilityScope}});
  };

  const navigateToPreview = () => {
    if (workInstructionDetails.is2d) {
      navigate(`/assembly/${companyId}/${procedureDetails.workInstructionId}/${procedureDetails.procedureId}?workInstructionType=${workInstructionDetails.is2d ? '2d' : '3d'}${props.isDelete ? '&isDelete=true' : ''}${props.isRevisitRequest ? '&isRevisitRequest=true' : ''}`);
    } else {
      window.location.href = `/assembly/${companyId}/${procedureDetails.workInstructionId}/${procedureDetails.procedureId}?workInstructionType=${workInstructionDetails.is2d ? '2d' : '3d'}${props.isDelete ? '&isDelete=true' : ''}${props.isRevisitRequest ? '&isRevisitRequest=true' : ''}`;
    }
  };

  const handleProcedurePreviewClick = () => {
    trackEvent(AmplitudeEvents.PREVIEW_PROCEDURE, {
      procedure_id: procedureDetails.procedureId,
      active_version_id: procedureDetails.versionId,
      previewed_by: localStorage.getItem('email'),
    });
    navigateToPreview();
  };

  const handleViewAnalyticsClick = () => {
    navigate(`/trainer/analytics/${procedureDetails.workInstructionId}/${procedureDetails.procedureId}`, {state: {visibilityScope : procedureDetails.visibilityScope}});
  };

  const handleExportClick = () => {
    TrainerService.exportProcedurePdf(procedureDetails.procedureId)
      .then((e) => {
        var file = new Blob([e.data], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', `${procedureDetails.procedureName}.pdf`);
        document.body.appendChild(link);
        link.click();
        if (link && link.parentNode) link.parentNode.removeChild(link);
      })
      .catch(({ response }) => {
        let error:any = (new TextDecoder('utf-8')).decode(response.data);
        error = JSON.parse(error);
        const {messageCode, errorMessage} = error;
        Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(messageCode, { ns: 'error' }) || errorMessage);
      });
  };

  const handleEditStepsClick = (procedureDetails) => {
    openStudioEditorInATab(procedureDetails.organizationId, procedureDetails.workInstructionId, procedureDetails.procedureId, true);
    setShowRefreshButton && setShowRefreshButton(true);
  }

  switch (procedureDetails.visibilityScope) {
    case PROCEDURE_VISIBILITY_SCOPE.NORMAL:
      return (
        <>
          {procedureDetails.tasks_count > 0 && procedureReady && <ProcedurePreview onClick={handleProcedurePreviewClick} />}
          {procedureDetails.versionId && procedureReady && <ShareQRCode onClick={() => openDialog(PROCEDURE_LIBRARY_DIALOG.SHARE_QR)} />}
          {procedureDetails.versionId && procedureReady && <ExportPDF onClick={() => openDialog(PROCEDURE_LIBRARY_DIALOG.EXPORT_PDF)} />}
          {procedureDetails.isPublished && procedureReady && (
            <EditSteps
              onClick={() => {
                openStudioEditorInATab(procedureDetails.organizationId, procedureDetails.workInstructionId, procedureDetails.procedureId, workInstructionDetails.is2d);
              }}
            />
          )}
          {procedureDetails.isPublished && <PeriodicReview onClick={() => openDialog(PROCEDURE_LIBRARY_DIALOG.PERIODIC_REVIEW)} />}
          {procedureDetails.isPublished && <RecertificationReview onClick={() => {}} />}
          {procedureDetails.approvalRequestedStatus && procedureDetails.approvalRequestedStatus === 'pending' && procedureReady && <Approve onClick={() => {}} />}

          {/* {procedureDetails.approvalRequestedStatus && procedureDetails.approvalRequestedStatus === 'pending' && procedureReady && (
                <MenuItem
                  onClick={() => {
                    setManagerApproval(true);
                    setManagerAction(1);
                  }}
                >
                  <HighlightOffIcon color="primary" sx={{ mr: 1 }} />
                  &nbsp;{t('ADD_USER.REJECT')}
                </MenuItem>
              )} */}
          {/* <EditSteps onClick={() => {}} /> */}
          {props.procedureDetails.versionId && procedureReady && <RevisionHistory onClick={handleRevisionHistoryClick} />}

          <EditButton onClick={handleEditClick} procedureDetails={procedureDetails} />
          <DuplicateButton onClick={() => openDialog(PROCEDURE_LIBRARY_DIALOG.DUPLICATE)} procedureDetails={procedureDetails} />
          <AddLanguageSupport onClick={() => openDialog(PROCEDURE_LIBRARY_DIALOG.LANGUAGE_SUPPORT)} />
          {props.procedureDetails.versionId && procedureReady && <ViewAnalytics onClick={handleViewAnalyticsClick} />}
          {props.procedureDetails.versionId && procedureReady && <RevokeAccess onClick={() => openDialog(PROCEDURE_LIBRARY_DIALOG.REVOKE_ACCESS)} />}
          <DeleteButton onClick={() => openDialog(PROCEDURE_LIBRARY_DIALOG.DELETE)} procedureDetails={procedureDetails} />
        </>
      );
    case PROCEDURE_VISIBILITY_SCOPE.REFERENCE:
      return [];
    case PROCEDURE_VISIBILITY_SCOPE.GLOBAL:
      return (
        <>
          {showRefreshButton && <RefreshButton onClick={onRefreshClick}/>}
          {procedureDetails.tasks_count > 0 && procedureReady && <ProcedurePreview onClick={handleProcedurePreviewClick} />}
          {!props.procedureDetails.isPublished && props.procedureDetails.tasks_count > 0 && !props.procedureDetails.approvalRequestedStatus && !props.procedureDetails.approvalSentStatus && 
            <PublishButton 
              onClick={() => openDialog(PROCEDURE_LIBRARY_DIALOG.VERSION_DIALOG)}
            />}
          {procedureDetails.versionId && procedureReady && <ShareQRCode onClick={() => openDialog(PROCEDURE_LIBRARY_DIALOG.SHARE_QR)} />}
          {procedureDetails.versionId && procedureReady && <ExportPDF onClick={() => openDialog(PROCEDURE_LIBRARY_DIALOG.EXPORT_PDF)} />}
          {/* {procedureDetails.isPublished && procedureReady && (
            <EditSteps
              onClick={() => {
                openStudioEditorInATab(procedureDetails.organizationId, procedureDetails.workInstructionId, procedureDetails.procedureId, workInstructionDetails.is2d);
              }}
            />
          )} */}
          {procedureDetails.isPublished && <PeriodicReview onClick={() => openDialog(PROCEDURE_LIBRARY_DIALOG.PERIODIC_REVIEW)} />}
          {/* {procedureDetails.isPublished && <RecertificationReview onClick={() => {}} />}*/}
          {procedureDetails.approvalRequestedStatus && procedureDetails.approvalRequestedStatus === 'pending' && procedureReady && <Approve onClick={() => openDialog(PROCEDURE_LIBRARY_DIALOG.MANAGER_APPROVE)} />} 

          {procedureDetails.approvalRequestedStatus && procedureDetails.approvalRequestedStatus === 'pending' && procedureReady && (
            <Reject onClick={() => openDialog(PROCEDURE_LIBRARY_DIALOG.MANAGER_REJECT)} />
          )}
          {/* <EditSteps onClick={() => {}} /> */}
          <EditStepsButton onClick={() => handleEditStepsClick(procedureDetails)} />
          <EditButton onClick={handleEditClick} procedureDetails={procedureDetails} />
          <DuplicateButton onClick={() => openDialog(PROCEDURE_LIBRARY_DIALOG.DUPLICATE)} procedureDetails={procedureDetails} />
          <AddLanguageSupport onClick={() => openDialog(PROCEDURE_LIBRARY_DIALOG.LANGUAGE_SUPPORT)} />
          {props.procedureDetails.versionId && procedureReady && <RevisionHistory onClick={handleRevisionHistoryClick} />}

          {/* {props.procedureDetails.versionId && procedureReady && <ViewAnalytics onClick={handleViewAnalyticsClick} />}
          {props.procedureDetails.versionId && procedureReady && <RevokeAccess onClick={() => openDialog(PROCEDURE_LIBRARY_DIALOG.REVOKE_ACCESS)} />} */}
          <ShowLinkedWIButton onClick={() => openDialog(PROCEDURE_LIBRARY_DIALOG.LINKED_WI)} />
          <DeleteButton onClick={() => openDialog(PROCEDURE_LIBRARY_DIALOG.DELETE)} procedureDetails={procedureDetails} />
        </>
      );
  }
};

const ProcedureCardOptions = (props) => {
  const dispatch = useAppDispatch();

  const { procedureDetails, workInstructionDetails } = props;
  const iconBtn = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const handleIconClick = (e) => {
    e.stopPropagation();
    setIsMenuOpen(true);
    dispatch(setProcedureToView(procedureDetails.procedureId));
  };
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        setIsMenuOpen(false);
      }}
    >
      <IconButton
        size="small"
        onClick={handleIconClick}
        aria-controls={isMenuOpen ? 'table-view-action-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : undefined}
        className="modular-procedure-list-more-icon show-on-hover"
        sx={{
          p: 0,
          marginRight: '10px',
          display: isMenuOpen ? 'inline-block!important' : 'auto',
        }}
        ref={iconBtn}
      >
        <MoreHoriz color='primary'/>
      </IconButton>

      <Menu
        anchorEl={iconBtn.current}
        id="table-view-action-menu"
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList
          procedureDetails={procedureDetails}
          workInstructionDetails={workInstructionDetails}
          onRefreshClick={props.onRefreshClick}
          showRefreshButton={props.showRefreshButton}
          setShowRefreshButton={props.setShowRefreshButton}
        />
      </Menu>
    </div>
  );
};

export default ProcedureCardOptions;
