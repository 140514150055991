import { Box, Typography, Grid, Skeleton, Avatar, useTheme, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import usePpeList from '../../hooks/ppeList';
import TraineeService from '../../services/trainee/TraineeService';
import TrainerService from '../../services/trainer/TrainerService';
import { useCanvasState } from '../../state/canvasState';
import { Toast } from '../Notifications/Notification';

interface Props {
    ppeList: any[];
    loading: boolean;
    setLoading: any;
    infiniteLoading: boolean;
    hasMore: boolean;
    loadNext: () => void;
}

const StepsPpeList: React.FC<Props> = (props) => {
    const { introStep, showIntro } = usePpeList();
    const { workInstructionId } = useParams();
    const [workInstructionName, setWorkInstructionName] = useState('');
    const canvasState = useCanvasState();
    const [procedureName, setProcedureName] = useState('');

    const { search: searchQuery } = useLocation();
    const query = new URLSearchParams(searchQuery);
    const partnerAccessToken = query.get('access');
    const observerRef = useRef<any>(null);
    const theme = useTheme();
    const { t } = useTranslation();
    const setProcedureDetails = () => {
        TraineeService.getWorkInstructionsById(workInstructionId, partnerAccessToken)
            .then((response) => {
                setWorkInstructionName(response.data.data.workInstructionName);
            })
            .catch(({ response }) => {
                Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
            });
        TrainerService.getProcedureById(canvasState.procedureId, partnerAccessToken)
            .then((response) => {
                setProcedureName(response.data.data.procedureName);
            })
            .catch(({ response }) => {
                Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response.data.messageCode, { ns: 'error' }) || response.data.errorMessage);
            });
    };
    useEffect(() => {
        if (introStep === 1) {
            props.setLoading(true);
            setProcedureDetails();
        }
    }, [canvasState.procedureId, introStep]);

    const lastElementRef = useCallback(
        (node) => {
            if (props.infiniteLoading) return;
            if (observerRef.current) observerRef.current.disconnect();
            observerRef.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && props.hasMore) {
                    props.loadNext();
                }
            });
            if (node) observerRef.current.observe(node);
        },
        [props.hasMore],
    );
    return (
        <>
            {props.loading && (
                <Box
                    component="div"
                    sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', overflow: 'auto', width: '100%' }}
                >
                    <Grid
                        container
                        spacing={2}
                        sx={{ height: '100%', width: '100%', padding: '32px !important', margin: 0 }}
                    >
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((e, i) => {
                            return (
                                <Grid
                                    item
                                    xs={12}
                                    md={2}
                                    lg={2}
                                    key={i}
                                >
                                    <Skeleton
                                        height={'180px'}
                                        width={'100%'}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
            )}
            {!props.loading && props.ppeList?.length > 0 && (
                <Box
                    component="div"
                    sx={{
                        overflow: 'auto',
                        width: '100%',
                        alignItems: 'center',
                        overflowY: 'auto',
                        padding: '0 8px',
                        '&::-webkit-scrollbar': {
                            width: '8px',
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: '#f1f1f1',
                            borderRadius: '4px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: '4px',
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            backgroundColor: theme.palette.primary.dark,
                        },
                        scrollbarWidth: 'thin',
                        scrollbarColor: theme.palette.primary.main,
                    }}
                >
                    <Box
                        sx={{
                            display:'flex',
                            overflowY: 'auto',
                            padding: '0 8px',
                            justifyContent:'center'
                        }}
                    >
                        {showIntro && introStep === 2 && (
                            <Grid
                                container
                                spacing={{ xs: 2, md: 2, lg: 2 }}
                                sx={{
                                    textAlign: 'left',
                                    margin: 'auto',
                                    padding: '32px !important',
                                    widows:'100%',
                                    height: '100%',
                                    pointerEvents: 'none',
                                    justifyContent:'center'
                                    
                                }}
                            >
                                {props.ppeList.map((e, i) => {
                                    const isLastElement = i === props.ppeList.length - 1;
                                    return (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            md={6}
                                            lg={2.3}
                                            key={i}
                                            ref={isLastElement ? lastElementRef : null}
                                        >
                                            <Tooltip title={e.name || 'Default Tooltip'}>
                                                <Box
                                                    component="div"
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        border: `2px solid #CDCDCD`,
                                                        borderRadius: '8px',
                                                        margin: '0px 10px 0px 0px',
                                                        padding: '12px',
                                                        minWidth: '120px',
                                                        maxWidth: '250px',
                                                        pointerEvents: 'all',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    <Avatar
                                                        sx={{
                                                            borderRadius: '12px',
                                                            height: '90px',
                                                            width: 'fit-content',
                                                            minWidth: '60%',
                                                            maxWidth: '100%',
                                                            cursor: 'pointer',
                                                        }}
                                                        imgProps={{
                                                            style: {
                                                                objectFit: 'contain',
                                                            },
                                                        }}
                                                        variant="square"
                                                        alt="PPE"
                                                        src={'data:image/jpeg;base64,' + e.previewImgUrl}
                                                    />

                                                    <Typography
                                                        component="div"
                                                        sx={{
                                                            fontSize: '20px !important',
                                                            maxWidth: '100%',
                                                            overflow: 'hidden',
                                                            whiteSpace: 'nowrap',
                                                            textOverflow: 'ellipsis',
                                                            paddingTop: '12px',
                                                        }}
                                                    >
                                                        {e.name}
                                                    </Typography>
                                                </Box>
                                            </Tooltip>
                                        </Grid>
                                    );
                                })}

                                {props.infiniteLoading && <Typography sx={{ position: 'absolute', bottom: 0 }}>{t('COMMON.LOADING')}</Typography>}
                            </Grid>
                        )}
                    </Box>
                </Box>
            )}
        </>
    );
};

export default StepsPpeList;
