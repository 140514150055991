import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import './AddCustomPPEDialog.scss';
import TrainerService from '../../../../services/trainer/TrainerService';
import Loading from '../../../Loading/Loading';
import { Autocomplete, TextField, FormControl, FormLabel, Stack } from '@mui/material';
import Dropzone from '../../../Dropzone/Dropzone';
import { environment } from '../../../../environment/environment';
import { useTranslation } from 'react-i18next';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box
                    component="div"
                    sx={{ p: 3 }}
                >
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-container': {
        '& .MuiPaper-root': {
            minWidth: '400px', // Set your width here
        },
    },
    '& .MuiDialogContent-root': {
        paddingBottom: '0px',
    },
    '& .MuiDialogActions-root': {
        padding: '0px 24px 24px 24px',
    },
    '& .MuiDialogTitle-root': {
        margin: ' 12px 12px 0px 12px !important',
    },
}));

interface Props {
    openDialog: boolean;
    closeDialog: () => void;
    procedure: any;
}

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle
            className="remove-margin"
            sx={{ m: 4, p: 4 }}
            {...other}
        >
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export const isString = (item: any): item is string => {
    return typeof item === 'string';
};

type AutoCompleteFieldProps<T> = {
    selectValue: keyof T;
    options: T[];
    onChange: any;
};

const AutoCompleteField = <T extends {}>({ selectValue, options, onChange }: AutoCompleteFieldProps<T>): React.ReactElement => {
    return (
        <Autocomplete
            id="manager-auto-complete"
            options={options}
            getOptionLabel={(option: any) => {
                const val = option[selectValue];
                return isString(val) ? val : '';
            }}
            onChange={onChange}
            renderInput={(params) => (
                <Box component="div">
                    <TextField
                        {...params}
                        placeholder="Enter Here"
                    />
                </Box>
            )}
        />
    );
};

export default function AddCustomPPEDialog(props: any) {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [PpeName, setPpeName] = React.useState('');
    const [PpeImage, setPpeImage] = React.useState();
    const [fileValues, setFileValues] = React.useState<any>();
    const [isFileError, setIsFileError] = React.useState<boolean>(false);
    
    useEffect(() => {
        setOpen(props.openDialog);
        if (props.editMode && props.editId) {
            setLoading(true);
            TrainerService.getPPEEquipmentById(props.editId)
                .then((response) => {
                    setLoading(false);
                    setPpeName(response.data.data.name);
                    setFileValues(response.data.data.previewImgUrl);
                })
                .catch((e) => {
                    setLoading(false);
                });
        } else {
            clearForm();
        }
    }, [props.openDialog]);

    const handleClose = () => {
        props.closeDialog();
        setOpen(false);
    };

    const clearForm = () => {
        setPpeName('');
        setFileValues(null);
    };

    const uploadForm = async () => {
        const formData = new FormData();
        formData.append('name', PpeName);

        if (Array.isArray(fileValues) && fileValues.length > 0) {
            formData.append('previewImg', fileValues[0]);
        } else if (fileValues) {
            const file = await (await fetch('data:image/jpeg;base64,' + fileValues)).blob();
            formData.append('previewImg', new File([file], `${PpeName}.jpg`, { type: 'image/jpg' }));
        } else {
            const staticImagePath = '/assets/ppe_no_image.png';
            const response = await fetch(staticImagePath);
            const blob = await response.blob();

            formData.append('previewImg', new File([blob], 'ppe_no_image.png', { type: blob.type }));
        }

        if (props.editMode) {
            await props.editCustomPpe(formData);
        } else {
            await props.uploadCustomPpe(formData);
        }
    };
    const { t } = useTranslation();
    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                {(props.loading || loading) && <Loading />}
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    {props.editMode ? (t('COMMON.EDIT_PPE_EQUIPMENT') as string) : (t('COMMON.ADD_PPE_EQUIPMENT') as string)}
                </BootstrapDialogTitle>

                <DialogContent>
                    <Box component="div">
                        <FormControl sx={{ width: '100%', paddingTop: '24px' }}>
                            <FormLabel
                                component="legend"
                                className="formStyle"
                                required
                                sx={{
                                    color: 'rgba(28, 29, 31, .8)',
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    paddingBottom: '8px',
                                }}
                            >
                                {' '}
                                {t('COMMON.NAME') as string}{' '}
                            </FormLabel>
                            <TextField
                                // value={formik.values.brand}
                                name="brand"
                                value={PpeName}
                                placeholder={t('3D_VIEWER.EXAMPLE_SAFETY_GLOVES')}
                                onChange={(e) => {
                                    setPpeName(e.target.value);
                                }}
                                id="component-outlined"
                                inputProps={{ maxLength: 55, minLength: 2 }}
                                required
                            />
                        </FormControl>
                        <FormControl sx={{ width: '100%', paddingTop: '24px', paddingBottom: '24px' }}>
                            <FormLabel
                                component="legend"
                                className="formStyle"
                                sx={{
                                    color: 'rgba(28, 29, 31, .8)',
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    paddingBottom: '8px',
                                }}
                            >
                                {t('COMMON.UPLOAD_IMAGE') as string}
                            </FormLabel>
                            {/* <Box component={"div"} sx={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}> */}

                            <Dropzone
                                className="file-upload-active"
                                acceptedFiles={['.png', '.jpg', '.jpeg']}
                                maxFileSize={environment.AppConfigs.fileUploadLimit.customPpe}
                                selectedFiles={fileValues}
                                PpeName={PpeName}
                                PPE={true}
                                editMode={props.editMode}
                                fileUploadValues={(data) => setFileValues(data)}
                                setIsFileError={(isError) => setIsFileError(isError)}
                            />
                            {/* </Box> */}
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-start"
                                sx={{ py: 1 }}
                                spacing={2}
                            >
                                <Box
                                    component="span"
                                    className="helperText"
                                    sx={{ color: 'rgba(28, 29, 31, .8)' }}
                                >
                                    {t('COMMON.MAX_FILE_SIZE', { MB: '5MB' }) as string}
                                </Box>
                                <Box
                                    component="span"
                                    className="helperText"
                                    sx={{ color: 'rgba(28, 29, 31, .8)' }}
                                >
                                    {t('COMMON.SUPPORTS') as string} ".png", ".jpg", ".jpeg"
                                </Box>
                            </Stack>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={!PpeName || isFileError}
                        variant="contained"
                        type="submit"
                        onClick={uploadForm}
                    >
                        {t('BUTTON.SAVE') as string}
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
